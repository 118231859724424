import { motion } from "framer-motion";
import { AiOutlineClose, AiOutlinePlus, AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useEffect, useRef, useState } from "react";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";



export function ModifyStockInOption(props: {permission: any}){

    const navigate = useNavigate();
    const {account} = useAccountStore();
    const {colors} = useStyleStore();

    const collectionInputField = useRef<HTMLInputElement | null>(null);

    const [options, setOptions] = useState<string[]>([]);

    const {name} = useParams();

    useEffect(() =>{
        if (!name)
            return;

        const f = async() =>{
            collectionInputField.current!.value = name;

            let result = await post('getStockOptions', {
                group_id: account.current_group,
                title: name
            });

            let temp: typeof options = [];
            Object.values(result.data).forEach((item) =>{
                let typed_item = item as any;
                temp.push(typed_item);
            })
            setOptions([...temp]);
        }
        f();
    }, [name])

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center">
            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                <div className="w-full h-fit flex flex-row justify-end items-center text-white gap-5">
                    <motion.div className=" w-fit h-fit bg-[#649d7f] flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async() =>{
                            if (!props.permission['a-stockin-opt'])
                                return;

                            if (!collectionInputField.current!.value){
                                alert('Collection name cannot be null.');
                                return;
                            }
                            if (options.length === 0){
                                alert('No option added.');
                                return;
                            }
                            for(let i = 0; i < options.length; i++){
                                if (options[i] === ''){
                                    alert('Option value cannot be empty.');
                                    return;
                                }
                            }

                            await post('addStockOptions', {
                                group_id: account.current_group,
                                collection: collectionInputField.current!.value,
                                options: options
                            }, true);

                            navigate('/dashboard/stockin-opt');
                        }}
                    >
                        Save
                    </motion.div>
                </div>

                <div className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Modify Stock-in Option</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/stockin-opt');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center"
                    style={{color: colors['tb-row-text']}}
                >
                    <tbody>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Collection</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={collectionInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className=" w-full mt-10">
                {
                    options.map((option, index) =>{
                        return (
                            <div key={`opt-${index}`} className=" w-full p-1 flex justify-between items-center gap-1"
                                style={{
                                    backgroundColor: index % 2 === 0 ? colors['tb-row-1'] : colors['tb-row-2']
                                }}
                            >
                                <input value={option} type="text" className=" w-full pl-1 pr-1 border rounded-md" style={{borderColor: colors['tb-row-1-h']}} 
                                    onChange={(e) =>{
                                        let temp = options;
                                        temp[index] = e.target.value;
                                        setOptions([...temp]);
                                    }}
                                />
                                <div className=" select-none cursor-pointer hover:opacity-70"
                                    style={{color: colors['tb-row-text']}}
                                    onClick={() =>{
                                        let temp = options;
                                        temp.splice(index, 1);
                                        setOptions([...temp]);
                                    }}
                                >
                                    <AiOutlineClose />
                                </div>
                            </div>
                        )
                    })
                }
                </div>

                <motion.div className=" w-14 h-14 rounded-full mt-5 flex justify-center items-center hover:cursor-pointer"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                    initial={{scale: 1}}
                    whileHover={{scale: 1.2}}
                    onClick={() =>{
                        setOptions([...options, '']);
                    }}>
                        <AiOutlinePlus />
                </motion.div>

            </div>
        </div>
    )
}