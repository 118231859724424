import { useEffect, useRef, useState } from "react"
import { post } from "../utils/post"
import { useNavigate, useParams } from "react-router-dom"
import { AiOutlineSwapLeft } from "react-icons/ai";
import { motion } from "framer-motion";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { getPermission } from "../utils/getPermission";

export const allPermissions: Record<string, string> = {
    'v-stockin': 'View Stock-In',
    'o-stockin': 'Open Stock-In',
    'a-stockin': 'Add Stock-In',
    'd-stockin': 'Delete Stock-In',
    'm-stockin': 'Modify Stock-In',

    'a-opt-stockin': 'Add New Options from Stock-In',
    'd-opt-stockin': 'Delete Options from Stock-In',

    'v-stockin-opt': 'View Stock-In Option',
    'o-stockin-opt': 'Open Stock-In Option',
    'a-stockin-opt': 'Add Stock-In Option',
    'd-stockin-opt': 'Delete Stock-In Option',
    'm-stockin-opt': 'Modify Stock-In Option',

    'v-invin': 'View Inv-In',
    'o-invin': 'Open Inv-In',
    'a-invin': 'Add Inv-In',
    'd-invin': 'Delete Inv-In',
    'm-invin': 'Modify Inv-In',

    'v-dis': 'View Distribution',
    'o-dis': 'Open Distribution',
    'm-dis': 'Modify Distribution',

    'v-inv': 'View Inventory',
    'o-inv': 'Open Inventory',
    'm-inv': 'Modify Inventory',

    'v-cate': 'View Category',

    'v-admin': '▲ View All Users',
    'o-admin': '▲ Open User Info',
    'a-admin': '▲ Add New User',
    'd-admin': '▲ Delete Users',
    'm-admin': '▲ Modify User Info',

    'v-cust': 'View Customer Profile',
    'o-cust': 'Open Customer Profile',

    'v-ord': 'View Order',
    'o-ord': 'Open Order',
    'm-ord': 'Modify Order',

    'v-quote': 'View Quotation',

    'v-sale': 'View Sale',
    'a-sale': 'Add Sale',
    'm-sale': 'Modify Sale',
    'o-sale': 'Open Sale',
    'd-sale': 'Delete Sale',
    
    'v-bal': 'View Balance',
    'o-bal': 'Open Balance',

    'v-cash': 'View Cash Payment',

    'v-warehouse': 'View Warehouse',
    'a-warehouse': 'Add Warehouse',
    'd-warehouse': 'Delete Warehouse',

    'v-contact': 'View Contact',
    'a-contact': 'Add Contact',
    'd-contact': 'Delete Contact',
    'm-contact': 'Modify Contact',

    'v-invadjust': 'View Inventory Minor Adjustment',

    'v-external': 'View External Links',

    'v-dev': 'View Developer Links'
};

export const superAdminPermission: Record<string, string> = {
    'a-cate': 'Add Category',
    'd-cate': 'Delete Category',
    'm-cate': 'Modify Category',

    'v-slide': 'View Slide',
    'o-slide': 'Open Slide',
    'm-slide': 'Modify Slide',

    'v-pend-ac': 'View Pending Account',
    'a-pend-ac': 'Approve Pending Account'
}

export function UserPermission(props: {permission: any}){

    const navigate = useNavigate();

    const {name} = useParams();
    const [user, setUser] = useState<any>({});

    const isAdmin = useRef<boolean>(false);

    const [checkedPermission, setCheckedPermission] = useState<Record<string, boolean>>({});

    const {account, setAccount, groups} = useAccountStore();

    useEffect(() =>{
        if (!name)
            return;

        let index = groups.findIndex(e => e.id === account.current_group);
        if (index > -1){
            if (groups[index].created_by === name){
                isAdmin.current = true;
            }
        }

        const f = async() =>{

            let dict: typeof checkedPermission = {};
            Object.keys(allPermissions).forEach((key) =>{
                dict[key] = false;
            })

            let result = await post('getUserById', {
                group_id: account.current_group,
                user_id: name
            })
            if (result.data){
                setUser({...result.data, id: name});
                if (result.data.permission === 'super'){
                    isAdmin.current = true;
                } else {
                    setCheckedPermission(getPermission(result.data.permission, true));
                }
            }
        }
        f();

    }, [name])

    if (!props.permission['o-admin'])
        return (<></>)

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center mb-10">

            <div className="mt-14 w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">

            <div className="w-full h-fit inline-flex justify-end items-center text-white gap-5">
                {
                    props.permission['m-admin'] ? 
                    <motion.div className=" w-fit h-fit bg-[#649d7f] flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async() =>{
                            if (isAdmin.current){
                                alert('This account cannot be edited.');
                                return;
                            }
                            if (!props.permission['m-admin'])
                                return;

                            let checked: Record<string, number> = {};
                            let str = '';
                            Object.entries(checkedPermission).forEach(([key, val]) =>{
                                if (val){
                                    checked[key] = 1;
                                    str += `${key},`;
                                }
                            })
                            str = str.slice(0, -1);

                            if (parseInt(user.id) === account.user_id){
                                setAccount({
                                    ...account,
                                    permission: checked
                                })
                            }
                            await post('setPermission', {
                                group_id: account.current_group,
                                user_id: user.id,
                                permission: str
                            }, true)

                            navigate('/dashboard/admin');
                        }}
                    >
                        Save
                    </motion.div> : <></>
                }
            </div>

                <div className=" mt-5 w-full h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                    <p>User Info</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/admin');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md">
                    <tbody>
                        <tr className=" bg-[#F5F3F9]">
                            <td className=" pl-2">Verified</td>
                            <td>{user.isPending ? 'false' : 'true'}</td>
                        </tr>
                        <tr className=" bg-[#ECE9F1]">
                            <td className=" pl-2">Deleted</td>
                            <td>{user.isDeleted ? 'true' : 'false'}</td>
                        </tr>
                        <tr className=" bg-[#F5F3F9]">
                            <td className=" pl-2">Account</td>
                            <td>{user.account_type}</td>
                        </tr>
                        <tr className=" bg-[#ECE9F1]">
                            <td className=" pl-2">Username</td>
                            <td>{user.username}</td>
                        </tr>
                    </tbody>
                </table>

                <div className=" mt-10 w-full h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                    <p>Permission</p>
                </div>

                <table className="w-full overflow-hidden whitespace-nowrap rounded-tl-md rounded-tr-md">
                    <tbody>
                        {
                            Object.entries(checkedPermission).map(([key, checked], index) =>{
                                return (
                                    <tr key={`perm-${index}`}
                                        style={{
                                            backgroundColor: index % 2 === 0 ? 'rgb(245 243 249)' : 'rgb(236 233 241)'
                                        }}
                                    >
                                        <td className=" pl-2 w-1/3 pt-1 pb-1">{allPermissions[key]}</td>
                                        <td className=" w-2/3 inline-flex justify-center">
                                            <input checked={checkedPermission[key]} type="checkbox" className="cursor-pointer" 
                                                onChange={(e) =>{
                                                    let temp = checkedPermission;
                                                    temp[key] = e.target.checked;
                                                    setCheckedPermission({...temp});
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>

        </div>
    )
}