import { useEffect, useState } from "react"
import { FaRedo } from "react-icons/fa";
import { validate } from "../utils/validate"
import { useNavigate, useParams } from "react-router-dom";
import { NavigationBar } from "./NavigationBar";
import { Categories } from "../categories/Categories";
import { AddCategory } from "../categories/AddCategory";
import { ModifyCategory } from "../categories/ModifyCategory";
import { Admin } from "../admin/Admin";
import { StockIn } from "../stock-in/StockIn";
import { AddStockIn } from "../stock-in/AddStockIn";
import { ModifyStockIn } from "../stock-in/ModifyStockIn";
import { Distribution } from "../distribution/Distribution";
import { ModifyDistribution } from "../distribution/ModifyDistribution";
import { Inventory } from "../inventory/Inventory";
import { InventoryView } from "../inventory/InventoryView";
import { AddInvIn } from "../inv-in/AddInvIn";
import { InvIn } from "../inv-in/InvIn";
import { ModifyInvIn } from "../inv-in/ModifyInvIn";
import { Order } from "../order/Order";
import { OrderView } from "../order/OrderView";
import { MainSlide } from "../main-slide/MainSlide";
import { AddSlide } from "../main-slide/AddSlide";
import { UserPermission } from "../admin/UserPermission";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { CustomerProfile } from "../customer-profile/CustomerProfile";
import { CustomerProfileView } from "../customer-profile/CustomerProfileView";
import { AddGroup } from "../group/AddGroup";
import { post } from "../utils/post";
import { AddUser } from "../admin/AddUser";
import { Placeholder } from "./Placeholder";
import { Settings } from "../settings/Settings";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { Sale } from "../sale/Sale";
import { AddSale } from "../sale/AddSale";
import { Quotation } from "../quotation/Quotation";
import { QuotationView } from "../quotation/QuotationView";
import { ModifySale } from "../sale/ModifySale";
import { TabBar } from "./TabBar";
import { CashPayment } from "../cash-payment/CashPayment";
import { Warehouse } from "../warehouse/Warehouse";
import { AddWarehouse } from "../warehouse/AddWarehouse";
import { useWarehouseStore } from "../utils/zustand/useWarehouseStore";
import { Balance } from "../balance/Balance";
import { ViewBalance } from "../balance/ViewBalance";
import { Contact } from "../contact/Contact";
import { AddContact } from "../contact/AddContact";
import { ModifyContact } from "../contact/ModifyContact";
import { InventoryAdjust } from "../inventory-adjust/InventoryAdjust";
import { External } from "../external/External";
import { Developer } from "../external/Developer";
import { Notification } from "../notification/Notification";
import { useTabStore } from "../utils/zustand/useTabStore";
import { StockInOption } from "../stock-in-option/StockInOption";
import { AddStockInOption } from "../stock-in-option/AddStockOption";
import { ModifyStockInOption } from "../stock-in-option/ModifyStockInOption";
import { PendingAccount } from "../pending-account/PendingAccount";

export function Dashboard(){

    const [canContinue, setCanContinue] = useState<number>(0);

    const navigate = useNavigate();

    const { category } = useParams();

    const [currentView, setCurrentView] = useState<JSX.Element>(<></>);

    const {account, setAccount, setGroups} = useAccountStore();
    const {colors, setStyle} = useStyleStore();
    const {total_warehouses, setTotalWarehouse} = useWarehouseStore();

    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    const {deleteAllTabs} = useTabStore();

    useEffect(() =>{
        if (account.current_group === -1)
            return;
        const f = async() =>{
            let result = await post('getTotalWarehouses', {
                group_id: account.current_group
            });
            let temp: typeof total_warehouses = [];
            Object.values(result.data).forEach((item: any) =>{
                temp.push(item.name);
            })
            setTotalWarehouse([...temp]);
        };
        f();
    }, [account.current_group])

    useEffect(() =>{
        const f = async() =>{
            // one time only
            if (Object.keys(account.permission).length === 0){
                deleteAllTabs();
                let style = localStorage.getItem('style');
                if (!style) 
                    style = 'default';
                else
                    setStyle(style);

                let username = account.username;
                if (username === '')
                    username = sessionStorage.getItem('username') as string;

                let password = account.password;
                if (password === '')
                    password = sessionStorage.getItem('password') as string;

                let bypass_auth = window.sessionStorage.getItem('bypass_auth');
                let result = await validate(username, password, bypass_auth);

                window.sessionStorage.removeItem('bypass_auth');
                
                setCanContinue(result.status);
                if (result.status === 1){
                    sessionStorage.setItem('username', username);
                    sessionStorage.setItem('password', password);
                    
                    setAccount(
                        {
                            ...account,
                            username: username,
                            password: password,
                            user_id: result.user_id,
                            image: result.img
                        }
                    )

                    let result2 = await post('getAllGroups', {
                        user_id: result.user_id,
                        username: username
                    });

                    setGroups(result2.groups);
                } else {
                    if (result.status === 0){
                        alert('Invalid username or password.');
                        setAccount({
                            username: '',
                            password: '',
                            user_id: -1,
                            permission: {},
                            current_group: -1,
                            image: ''
                        })
                        navigate('/');
                    } else if (result.status === 2){
                        if (result.auth_status === 0){
                            alert('Unexpected error');
                            setAccount({
                                username: '',
                                password: '',
                                user_id: -1,
                                permission: {},
                                current_group: -1,
                                image: ''
                            })
                            navigate('/');
                            return;
                        }
                        if (result.auth_status === 2){
                            alert('Unverified email');
                            setAccount({
                                username: '',
                                password: '',
                                user_id: -1,
                                permission: {},
                                current_group: -1,
                                image: ''
                            })
                            navigate('/');
                            return;
                        }
                        setAccount({
                            ...account,
                            username: username,
                            password: password,
                            user_id: result.user_id
                        })
                        navigate('/auth');
                    }
                }
            }
        }
        f();

    }, [category])

    useEffect(() =>{

        if (account.current_group === -1){
            let _placeholder = true;
            switch(category){
                case 'settings': {
                    _placeholder = false;
                    break;
                }
                case 'add-group': {
                    _placeholder = false;
                    break;
                }
                case 'notification': {
                    _placeholder = false;
                    break;
                }
            }
            if (_placeholder){
                setCurrentView(<Placeholder />);
                return;
            }
        }

        switch (category) {
            case 'pending-ac': {
                setCurrentView(<PendingAccount permission={account.permission} />)
                break;
            }
            case 'notification': {
                setCurrentView(<Notification/>)
                break;
            }
            case 'dev': {
                setCurrentView(<Developer permission={account.permission} />)
                break;
            }
            case 'external': {
                setCurrentView(<External permission={account.permission} />)
                break;
            }
            case 'invadjust': {
                setCurrentView(<InventoryAdjust permission={account.permission} />)
                break;
            }
            case 'modify-contact': {
                setCurrentView(<ModifyContact />)
                break;
            }
            case 'add-contact': {
                setCurrentView(<AddContact />)
                break;
            }
            case 'contact': {
                setCurrentView(<Contact permission={account.permission} />)
                break;
            }
            case 'view-balance': {
                setCurrentView(<ViewBalance />)
                break;
            }
            case 'balance': {
                setCurrentView(<Balance permission={account.permission} />);
                break;
            }
            case 'add-warehouse': {
                setCurrentView(<AddWarehouse permission={account.permission} />)
                break;
            }
            case 'warehouse': {
                setCurrentView(<Warehouse permission={account.permission} />)
                break;
            }
            case 'cash': {
                setCurrentView(<CashPayment permission={account.permission} />);
                break;
            }
            case 'quote-view': {
                setCurrentView(<QuotationView permission={account.permission} />)
                break;
            }
            case 'quote': {
                setCurrentView(<Quotation permission={account.permission} />)
                break;
            }
            case 'modify-sale': {
                setCurrentView(<ModifySale />);
                break;
            }
            case 'add-sale': {
                setCurrentView(<AddSale />);
                break;
            }
            case 'sale': {
                setCurrentView(<Sale permission={account.permission} />)
                break;
            }
            case 'settings': {
                setCurrentView(<Settings />)
                break;
            }
            case 'add-user': {
                setCurrentView(<AddUser permission={account.permission} />)
                break;
            }
            case 'add-group': {
                setCurrentView(<AddGroup />);
                break;
            }
            case 'customer-view': {
                setCurrentView(<CustomerProfileView permission={account.permission} />)
                break;
            }
            case 'customer-profile': {
                setCurrentView(<CustomerProfile permission={account.permission} />)
                break;
            }
            case 'add-slide': {
                setCurrentView(<AddSlide permission={account.permission} />);
                break;
            }
            case 'slide': {
                if (account.permission['v-slide'])
                setCurrentView(<MainSlide permission={account.permission} />);
                break;
            }
            case 'order-view': {
                setCurrentView(<OrderView permission={account.permission} />);
                break;
            }
            case 'order': {
                if (account.permission['v-ord'])
                setCurrentView(<Order permission={account.permission} />)
                break;
            }
            case 'inventory-view': {
                setCurrentView(<InventoryView permission={account.permission} />)
                break;
            }
            case 'inventory': {
                if (account.permission['v-inv'])
                setCurrentView(<Inventory permission={account.permission} />)
                break;
            }
            case 'inv-in': {
                if (account.permission['v-invin'])
                setCurrentView(<InvIn permission={account.permission} />)
                break;
            }
            case 'add-inv-in': {
                setCurrentView(<AddInvIn permission={account.permission} />)
                break;
            }
            case 'modify-inv-in': {
                setCurrentView(<ModifyInvIn permission={account.permission} />)
                break;
            }
            case 'modify-distribution': {
                setCurrentView(<ModifyDistribution permission={account.permission} />)
                break;
            }
            case 'distribution': {
                if (account.permission['v-dis'])
                setCurrentView(<Distribution permission={account.permission}/>)
                break;
            }
            case 'modify-stockin': {
                setCurrentView(<ModifyStockIn permission={account.permission} />)
                break;
            }
            case 'add-stockin': {
                setCurrentView(<AddStockIn permission={account.permission} />)
                break;
            }
            case 'user-permission': {
                setCurrentView(<UserPermission permission={account.permission} />)
                break;
            }
            case 'admin': {
                if (account.permission['v-admin'])
                setCurrentView(<Admin permission={account.permission} />)
                break;
            }
            case 'modify-stockin-opt': {
                setCurrentView(<ModifyStockInOption permission={account.permission} />)
                break;
            }
            case 'add-stockin-opt': {
                setCurrentView(<AddStockInOption permission={account.permission} />)
                break;
            }
            case 'stockin-opt': {
                setCurrentView(<StockInOption permission={account.permission} />)
                break;
            }
            case 'modify-category': {
                setCurrentView(<ModifyCategory permission={account.permission} />)
                break;
            }
            case 'add-category': {
                setCurrentView(<AddCategory permission={account.permission} />)
                break;
            }
            case 'stockin': {
                if (account.permission['v-stockin'])
                setCurrentView(<StockIn permission={account.permission} />)
                break;
            }
            case 'category': {
                if (account.permission['v-cate'])
                setCurrentView(<Categories permission={account.permission}/>)
                break;
            }
            case 'overview': {
                setCurrentView(<></>);
                break;
            }
            default: {
                setCurrentView(<Placeholder />);
                break;
            }
        }

    }, [category])

    if (canContinue !== 1)
        return <></>

    return (
        <div className=" w-full h-screen flex flex-row justify-start items-start max-w-[100vw] overflow-hidden"
            style={{
                backgroundColor: colors['bg'],
                color: colors['text']
            }}
        >
            <NavigationBar permission={account.permission} />

            <div key={`view-${forceUpdate}`} className=" w-full h-full flex flex-col">

                <div className=" absolute right-0 top-0 p-2 w-fit h-fit">
                    <div className="hover:opacity-50 cursor-pointer"
                        onClick={() =>{
                            deleteAllTabs();
                            setForceUpdate(!forceUpdate);
                        }}
                    >
                        <FaRedo />
                    </div>
                </div>

                <div className=" w-full h-full overflow-y-scroll pb-10 flex shrink"
                    style={{
                        backgroundColor: colors['bg']
                    }}
                >
                    {currentView}
                </div>

                <TabBar />
            </div>
        </div>
    )
}