import * as Excel from "exceljs";
import { saveAs } from "./saveAs";

export async function saveInvInExcel(
  username: string,
  invin_id: string,
  redistributionRecords: Record<
    string,
    {
      modelId: string;
      name: string;
      style: string;
      style_code: number;
      quantity: number;
    }[]
  >,
  redistributionInfo: Record<string, { id: string; status: string; from: string; to: string }>
) {
  const workbook = new Excel.Workbook();
  workbook.creator = username;
  workbook.lastModifiedBy = username;
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const sheet = workbook.addWorksheet(invin_id);
  sheet.columns = [
    { header: "", key: "A", width: 25 },
    { header: "", key: "B" },
    { header: "", key: "C" },
    { header: "", key: "D" },
    { header: "", key: "E" },
    { header: "", key: "F" },
  ];

  let currentRow = 0;

  sheet.insertRow(++currentRow, { A: "Inv-In Record" });
  sheet.insertRow(++currentRow, { A: "Date", B: new Date().toISOString() });

  currentRow++;

  sheet.insertRow(++currentRow, { A: "Delivery Id", B: invin_id });

  currentRow++;

  Object.entries(redistributionInfo).forEach(([warehouse, info]) => {
    sheet.insertRow(++currentRow, { A: "Redelivery Id", B: "From", C: 'To', D: 'Status' });
    sheet.insertRow(++currentRow, { A: info.id, B: info.from, C: info.to, D: info.status });
    sheet.insertRow(++currentRow, {
      A: "Model Id",
      B: "Name",
      C: "Style",
      D: "Quantity",
    });

    redistributionRecords[warehouse].forEach((record) => {
      sheet.insertRow(++currentRow, {
        A: record.modelId,
        B: record.name,
        C: record.style,
        D: record.quantity
      });
    });
    currentRow++;
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, `${invin_id}.xlsx`);
}
