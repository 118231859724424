import { useEffect, useRef, useState } from "react";
import { AiFillCaretRight, AiOutlineClose, AiOutlinePlus, AiOutlineSwapLeft } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { post } from "../utils/post";
import * as CustomEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { motion } from "framer-motion";
import { AvailableLanguages } from "../categories/AddCategory";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { saveInventoryExcel } from "../utils/report/saveInventoryExcel";

export function InventoryView(props: {permission: any}){

    const { name } = useParams();
    const navigate = useNavigate();

    const {account} = useAccountStore();
    const {colors} = useStyleStore();

    const [redisOpen, setRedisOpen] = useState<Record<string, boolean>>({});
    const [redisDetails, setRedisDetails] = useState<Record<string, {redis_id: string, created_at: string, name: string, model_id: string, style_code: number, style: string, quantity: number}[]>>({});


    const modelIdInputField = useRef<HTMLInputElement | null>(null);
    const nameInputField = useRef<HTMLInputElement | null>(null);
    const priceInputField = useRef<HTMLInputElement | null>(null);
    const categoryInputField = useRef<HTMLInputElement | null>(null);
    const SEOInputField = useRef<HTMLInputElement | null>(null);
    const frameInputField = useRef<HTMLInputElement | null>(null);

    const [images, setImages] = useState<string[]>([]);

    const descriptionEditor = useRef<CKEditor<ClassicEditor> | null>(null);

    const [inventory, setInventory] = useState<Record<string, {style: string, style_code: number, warehouse: string, quantity: number}[]>>({});

    const [languages, setLanguages] = useState<{code: string, name: string, desc: string}[]>([]);

    const location = useLocation();


    useEffect(() =>{
        if (!name)
            return;
        if (!location.pathname.startsWith("/dashboard/inventory-view")) return;

        setImages([]);
        setInventory({});
        setLanguages([]);

        const f = async() =>{
            let result = await post('getInventory', {
                group_id: account.current_group,
                modelId: name
            })

            if (result.status === 0){
                alert('Inventory not found.')
                navigate('/dashboard/inventory');
                return;
            }
            let typedItems = result.data as any;
            if (typedItems['images']){
                setImages([...typedItems['images'].split(',')]);
            }

            modelIdInputField.current!.value = name;
            nameInputField.current!.value = typedItems['name'];
            priceInputField.current!.value = typedItems['price'];
            categoryInputField.current!.value = typedItems['category'];
            SEOInputField.current!.value = typedItems['seo'];
            frameInputField.current!.value = typedItems['frame'];

            if (typedItems['description'])
            descriptionEditor.current!.editor!.data.set(typedItems['description']);

            if (typedItems['inv']){
                let temp: typeof inventory = {};
                Object.values(typedItems['inv']).forEach((item: any) =>{
                    if (!temp[item.warehouse])
                        temp[item.warehouse] = [];

                    let index = temp[item.warehouse].findIndex(e => e.style_code === item.style_code);
                    if (index > -1){
                        temp[item.warehouse][index].quantity += parseInt(item.quantity);
                    } else {
                        temp[item.warehouse].push({
                            style: typedItems['style_code_ref'][item.style_code],
                            style_code: item.style_code,
                            warehouse: item.warehouse,
                            quantity: parseInt(item.quantity)
                        })
                    }
                })
                setInventory({...temp});
            }

            if (typedItems['lang']){
                let temp: typeof languages = [];
                Object.values(typedItems['lang']).forEach((item: any) =>{
                    temp.push({
                        code: item.country_code,
                        name: item.text,
                        desc: item.text2
                    })
                })
                setLanguages([...temp]);
            }
        };
        f();
    }, [name, location])

    const [inputFile, setInputFile] = useState<File | null>(null);
    useEffect(() => {
        if (!name){
            alert('Model Id is null.');
            return;
        }
        if (inputFile === null)
            return;
        if (inputFile.type.split('/')[0] !== 'image'){
            alert('Only accept image format file.')
            setInputFile(null)
            return;
        }
        const f = async() => {
            let buffer = await inputFile.arrayBuffer();
            let data = new FormData();
            data.append('file', new Blob([buffer], {type: inputFile.type}), inputFile.name);
            data.append('id', name);
            data.append('filename', inputFile.name);
            data.append('table', 'model_details');
            data.append('path', '');

            let result = fetch('https://inanin.com/shopping-platform/commerce-backend/upload.php', {
                method: 'POST',
                body: data
            }).then(async(response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const getData = await response.json();

                if (getData.status === 'upload success'){
                    let temp = images;
                    temp.push(inputFile.name);
                    setImages([...temp]);
                } else {
                    alert(getData.status);
                }


            }).catch(e => {
                alert('There was a problem with the file upload.');
                console.log(e);
            }).finally(() =>{
                setInputFile(null);
            });
            
        };
        f();
    }, [inputFile])

    return(
        <div className=" w-full h-fit flex flex-col justify-center items-center">
            
            <div className=" mt-14 w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">

                <div className="w-full h-fit flex flex-row justify-end items-center gap-5">
                    <motion.div className=" w-fit h-fit flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
                        style={{
                            backgroundColor: colors['tb-head'],
                            color: colors['tb-head-text']
                        }}
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async() =>{
                            if (!name)
                                return;

                            await saveInventoryExcel(account.username, name, nameInputField.current!.value, inventory, priceInputField.current!.value, categoryInputField.current!.value, SEOInputField.current!.value, images, descriptionEditor.current!.editor!.data.get());
                        }}
                    >
                        Export Details As Excel
                    </motion.div>
                    
                    <motion.div className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async() =>{
                            if (!props.permission['m-inv'])
                                return;

                            let _model_id = modelIdInputField.current!.value;
                            let _name = nameInputField.current!.value;
                            let _price = priceInputField.current!.value;
                            let _category = categoryInputField.current!.value;
                            let _SEO = SEOInputField.current!.value;
                            let _frame = frameInputField.current!.value;
                            let _description = descriptionEditor.current!.editor!.data.get();
                            let _languages = JSON.stringify(languages);

                            let result = await post('setInventory', {
                                group_id: account.current_group,
                                oriModelId: name,
                                newModelId: _model_id,
                                name: _name,
                                price: _price,
                                description: _description,
                                category: _category,
                                seo: _SEO,
                                frame: _frame,
                                languages: _languages
                            })
                            if (result.status === 0)
                                alert('Model Id already exist.');
                            navigate('/dashboard/inventory');
                        }}
                    >
                        Save
                    </motion.div>
                </div>

                <div className=" w-full inline-flex justify-end h-fit hover:text-[#a9abc6] hover:cursor-pointer mt-10 mb-2"
                    style={{
                        color: colors['text']
                    }}
                    onClick={() =>{
                        navigate('/dashboard/inventory');
                    }}>
                    <AiOutlineSwapLeft className=" text-lg " />
                </div>

                <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md">
                    <thead style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}>
                        <tr>
                            <td className="p-2">
                                Inventory Info
                            </td>
                            <td>
                            </td>
                        </tr>
                    </thead>
                    <tbody style={{color: colors['tb-row-text']}}>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Model Id</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={modelIdInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5" required />
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className=" pl-2">Name</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={nameInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5" required />
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Quantity</td>
                            <td>
                                <div className=" w-full flex flex-col justify-start items-start pl-5 pr-5 gap-5">
                                {
                                    Object.entries(inventory).map(([warehouse, items], index) =>{
                                        return (
                                            <div key={`inv-${index}`} className=" w-full flex flex-col justify-start items-start">
                                                <p className=" font-semibold">{warehouse}</p>
                                                {
                                                    items.map((item, iIndex) =>{
                                                        return (
                                                        <div  key={`inv-${index}-${iIndex}`} className=" w-full flex flex-col justify-center items-start gap-2">
                                                            <div className=" w-full flex justify-between items-center pr-2"
                                                            style={{
                                                                backgroundColor: iIndex % 2 === 0 ? colors['tb-row-2-h'] : colors['tb-row-1-h']
                                                            }}>
                                                                <div className=" w-fit flex justify-start items-center">
                                                                    <AiFillCaretRight />
                                                                    <p>{item.style}</p>
                                                                </div>
                                                                <p>{item.quantity}</p>
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                }

                                                <div className=" w-full flex justify-end">
                                                    <div className=" p-1 mt-5 cursor-pointer select-none hover:opacity-80 text-xs"
                                                        style={{
                                                            backgroundColor: colors['tb-head'],
                                                            color: colors['tb-head-text']
                                                        }}
                                                        onClick={async() =>{
                                                            if (redisOpen[warehouse]){
                                                                setRedisOpen({
                                                                    ...redisOpen,
                                                                    [warehouse]: false
                                                                })
                                                                return;
                                                            }
                                                            if (redisDetails[warehouse]){
                                                                setRedisOpen({
                                                                    ...redisOpen,
                                                                    [warehouse]: true
                                                                })
                                                                return;
                                                            }

                                                            let result = await post('searchInventoryRedistributions', {
                                                                group_id: account.current_group,
                                                                warehouse: warehouse,
                                                                model_id: name,
                                                                page: 1
                                                            })
                                                            let temp: any[] = [];
                                                            Object.values(result.data).forEach((redisItem: any) =>{
                                                                temp.push({
                                                                    redis_id: redisItem.redis_id,
                                                                    created_at: redisItem.created_at,
                                                                    name: redisItem.name,
                                                                    model_id: redisItem.model_id,
                                                                    style_code: parseInt(redisItem.style_code),
                                                                    style: result.style_code_ref[parseInt(redisItem.style_code)],
                                                                    quantity: parseInt(redisItem.quantity),
                                                                })
                                                            })
                                                            setRedisDetails({
                                                                ...redisDetails,
                                                                [warehouse]: temp
                                                            })
                                                            setRedisOpen({
                                                                ...redisOpen,
                                                                [warehouse]: true
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            redisOpen[warehouse] ?
                                                            'Close' : 'View Details'
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    redisOpen[warehouse] ?
                                                    <table className="w-full mt-5 table-auto text-sm">
                                                        <thead
                                                            style={{
                                                                backgroundColor: colors['tb-head'],
                                                                color: colors['tb-head-text']
                                                            }}
                                                        >
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Distribution Id</th>
                                                                <th>Style</th>
                                                                <th>Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            Object.values(redisDetails[warehouse]).map((redisItem, rIndex) =>{
                                                                return (
                                                                    <tr key={`redis-${index}-${rIndex}`}>
                                                                        <td className=" text-xs opacity-60">{redisItem.created_at}</td>
                                                                        <td>
                                                                            <p className=" select-none font-semibold hover:underline cursor-pointer"
                                                                                onClick={() =>{
                                                                                    navigate(`/dashboard/modify-distribution/${redisItem.redis_id}`);
                                                                                }}
                                                                            >
                                                                                {redisItem.redis_id}
                                                                            </p>
                                                                        </td>
                                                                        <td>{redisItem.style}</td>
                                                                        <td className=" font-mono">{redisItem.quantity}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <></>
                                                }
                                            </div>                                            
                                        )
                                    })
                                }
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className=" pl-2">Price</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={priceInputField} type="number" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Category</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={categoryInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className=" pl-2">SEO</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={SEOInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Frame</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={frameInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className=" pl-2 align-top pt-2">Images</td>
                            <td className=" w-full pl-2"> 
                                <input type="file" 
                                    className=" mb-2 flex left-0"
                                    disabled={inputFile ? true : false}
                                    onChange={(e) =>{
                                        if (inputFile)
                                            return;
                                        if (e.target.files)
                                        if (e.target.files[0])
                                            setInputFile(e.target.files[0])
                                }}/>
                                <div className=" w-full max-h-52 align-top flex flex-col justify-start items-start mr-2 overflow-y-scroll">
                                        {
                                            images.map((item, index) =>{
                                                let bgColor = colors['tb-row-1-h'];
                                                if (index % 2 === 0)
                                                    bgColor = colors['tb-row-2-h'];
                                                return (
                                                    <div key={`img-${index}`}
                                                    className=" w-full p-1 pr-3 text-start flex justify-between items-start"
                                                    style={{
                                                        backgroundColor: bgColor
                                                    }}>
                                                        <div className=" w-full max-w-[10vw] h-fit flex flex-col justify-start items-start">
                                                            <p
                                                            className="hover:cursor-pointer hover:underline w-fit"
                                                            onClick={() =>{
                                                                window.open(`https://inanin.com/shopping-platform/commerce-backend/uploads/${name}%23${item}`)
                                                            }}>
                                                                {item}
                                                            </p>
                                                            <img src={`https://inanin.com/shopping-platform/commerce-backend/uploads/${name}%23${item}`} className=" contain h-full max-h-[10vw]" />
                                                        </div>

                                                        <AiOutlineClose className=" hover:cursor-pointer"
                                                        onClick={async() =>{
                                                            await post('deleteImage', {
                                                                group_id: account.current_group,
                                                                table: 'model_details',
                                                                path: '',
                                                                modelId: name,
                                                                image: item
                                                            })

                                                            let temp: string[] = [];
                                                            for(let i = 0; i < images.length; i++){
                                                                if (i !== index)
                                                                    temp.push(images[i]);
                                                            }
                                                            setImages([...temp]);
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2 align-top pt-2">Description</td>
                            <td className=" pl-2 text-black">
                                <CKEditor
                                config={
                                    {  removePlugins: ['MediaEmbedToolbar']  }
                                }
                                ref={descriptionEditor}
                                editor={ CustomEditor.default }
                                // data="<p>Hello from CKEditor&nbsp;5!</p>"
                                onChange={ ( event, editor ) => {
                                    // const data = editor.getData();
                                    // console.log( { event, editor, data } );
                                } }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Languages</p>
                </div>

                {
                    languages.map((item, index) =>{
                        let bg = colors['tb-row-1'];
                        if (index % 2 === 0)
                            bg = colors['tb-row-2'];
                        return (
                            <div key={`language-item-${index}`} className=" w-full h-fit flex flex-col justify-start items-center p-2 gap-2"
                                style={{
                                    backgroundColor: bg,
                                    color: colors['tb-row-text']
                                }}
                            > 

                                <div className=" w-full flex flex-row justify-start items-center gap-5">
                                    <select className=" w-1/3 p-2.5 border border-zinc-400 text-black rounded-md"
                                        value={item.code}
                                        onChange={(e) =>{
                                            for(let i = 0; i < languages.length; i++){
                                                if (languages[i].code === e.target.value){
                                                    e.target.value = 'none';
                                                    alert('Input field with same country code found.');
                                                    return;
                                                }
                                            }

                                            let temp = languages;
                                            temp[index].code = e.target.value;

                                            setLanguages([...temp]);
                                        }}>
                                            {
                                                Object.entries(AvailableLanguages).map(([key, value], index) =>{
                                                    return (
                                                        <option key={`lang-${index}`} value={key}>{value}</option>
                                                    )
                                                })
                                            }
                                    </select>

                                    <div className="w-2/3">
                                        <input type="text" placeholder="Name" className="p-2.5 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full"
                                            value={item.name}
                                            onChange={(e) =>{
                                                let temp = languages;
                                                temp[index].name = e.target.value;

                                                setLanguages([...temp]);
                                            }}/>
                                    </div>

                                    <AiOutlineClose className=" hover:cursor-pointer" 
                                        onClick={() =>{
                                            let temp = languages;
                                            temp.splice(index, 1);

                                            setLanguages([...temp]);
                                        }}/>
                                </div>

                                <div className=" w-full text-black">
                                    <CKEditor
                                        config={
                                            {  removePlugins: ['MediaEmbedToolbar']  }
                                        }
                                        editor={ CustomEditor.default }
                                        data={languages[index].desc}
                                        onChange={ ( event, editor ) => {
                                            let temp = languages;
                                            temp[index].desc = editor.getData();

                                            setLanguages([...temp]);
                                        } }
                                    />
                                </div>

                            </div>
                        )
                    })
                }

                <motion.div className=" w-14 h-14 rounded-full mt-5 flex justify-center items-center hover:cursor-pointer"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                    initial={{scale: 1}}
                    whileHover={{scale: 1.2}}
                    onClick={(e) =>{
                        let temp = languages;
                        temp.push({
                            code: '',
                            name: '',
                            desc: ''
                        })

                        setLanguages([...temp]);
                    }}>
                        <AiOutlinePlus />
                </motion.div>

            </div>
        </div>
    )
}