import { post } from "./post";

export async function validate(username: string, password: string, bypass_auth: string | null){

    if (username === '' || password === '')
        return {status: 0, user_id: -1, img: ''};

    let result = await post('login', {
                    username: username,
                    password: password,
                    bypass_auth: bypass_auth
                })

    if (result.status === 1){
        return {status: result.status, user_id: result.id, img: result.img};
    }else if (result.status === 2){
        // onetime passcode
        return {status: result.status, user_id: result.id, img: '', auth_status: result.auth_status};
    }

    return {status: result.status, user_id: -1, img: ''};
}