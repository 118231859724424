import { useEffect, useRef, useState } from "react";
import {
  AiFillDelete,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlinePlus,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/post";

type SlideProps = {
  id: number;
  checked: boolean;
  type: string;
  images: string;
};

export function MainSlide(props: {permission: any}) {
  const navigate = useNavigate();

  const selectAllheckbox = useRef<HTMLInputElement | null>(null);

  const [items, setItems] = useState<SlideProps[]>([]);
  const totalLength = useRef<number>(0);
  const totalPage = useRef<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    const f = async () => {
      let length = await post("getLength", {
        name: "slides",
      });
      totalLength.current = length.data;
      totalPage.current = Math.ceil(totalLength.current / 10);

      setCurrentPage(1);
    };
    f();
  }, []);

  useEffect(() => {
    const f = async () => {
      let result = await post("getAllSlides", {
        page: currentPage,
      });

      if (result.status != 1) return;

      let temp = items;
      temp = [];
      Object.values(result.data).forEach((item) => {
        let typedItem = item as any;
        temp.push({
          id: typedItem.id,
          checked: false,
          type: typedItem.model_id,
          images: typedItem.images,
        });
      });
      setItems([...temp]);
    };
    f();
  }, [currentPage]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">

      <div className=" mt-5 w-full lg:w-2/3 h-fit">
        <table className="table-auto  whitespace-nowrap">
          <thead className=" text-white">
            <tr>
              <th>
                <div className="bg-[#56596c] rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]">
                  <input
                    ref={selectAllheckbox}
                    type="checkbox"
                    className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    onChange={(e) => {
                      let temp = items;
                      for (let i = 0; i < temp.length; i++) {
                        temp[i].checked = selectAllheckbox.current!.checked;
                      }
                      setItems([...temp]);
                    }}
                  />
                </div>
              </th>
              <th className="text-start">
                <div className=" flex justify-center items-center gap-2 bg-[#56596c] pl-2 pr-2">
                  <p>Type</p>
                </div>
              </th>
              <th className="text-start w-full">
                <div className=" flex justify-center items-center gap-2 bg-[#56596c] pl-2 pr-2">
                  <p>Image</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              let bgColor = "#F5F3F9";
              if (index % 2 === 0) bgColor = "#ECE9F1";

              return (
                <tr
                  key={`stockin-${index}`}
                  style={{
                    backgroundColor: bgColor,
                  }}
                >
                  <td>
                    <div className="flex justify-center items-center h-[24px] mb-[1px]">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        onChange={(e) => {
                          let temp = items;
                          temp[index].checked = e.target.checked;
                          setItems([...temp]);

                          let allChecked: boolean = true;
                          for (let i = 0; i < temp.length; i++) {
                            if (!temp[i].checked) {
                              allChecked = false;
                            }
                          }
                          selectAllheckbox.current!.checked = allChecked;
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <p className="pl-1 pr-1 text-center cursor-pointer hover:underline"
                      onClick={() => {
                        if (props.permission['o-slide'])
                        navigate(`/dashboard/add-slide/${item.type}`);
                      }}
                    >
                      {item.type}
                    </p>
                  </td>
                  <td className=" w-full">
                    <div
                      className="w-full flex justify-center items-center gap-2 pl-1 pr-1 overflow-x-scroll">
                      <p className="font-semibold">{item.images}</p>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="w-full h-fit flex flex-row justify-center items-center gap-5 mt-5">
          {currentPage - 1 > 0 ? (
            <AiOutlineDoubleLeft
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
          ) : (
            <></>
          )}
          <p>{currentPage}</p>
          {currentPage + 1 > totalPage.current ? (
            <></>
          ) : (
            <AiOutlineDoubleRight
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
