import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import { FaQuestionCircle } from "react-icons/fa";

export function Login(){

    const {account, setAccount} = useAccountStore();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

    const navigate = useNavigate();

    const [dismissMessage, setDismissMessage] = useState<boolean>(true);

    useEffect(() =>{
        let dismiss = localStorage.getItem('inanin-dismiss');
        if (dismiss === null)
            setDismissMessage(false);
    }, [])

    const onEnterFocus = (name: string) =>{
        const element = document.getElementById(name);
        if (element){
          const inputField = element as HTMLInputElement;
          inputField.focus();
        }
      }
    
      const enter = async (username: string, password: string) =>{
        if (isLoggingIn)
            return;
        if (username === ''){
            alert('Username cannot be empty.');
            return;
        }
        if (password === ''){
            alert('Password cannot be empty.');
            return;
        }
        setIsLoggingIn(true);

        setAccount({
            ...account,
            username: username,
            password: password
        })
        navigate('/dashboard/');

        setIsLoggingIn(false);
      }

    return (
        <>

        <div className=' w-[100vw] h-[100vh] flex justify-start items-center'>

            <div className="h-fit w-fit"
                style={{
                    display: dismissMessage ? 'none' : 'block'
                }}
            >
                <div className="h-screen shrink-0 w-0 md:w-[20vw] hidden md:flex bg-zinc-900 flex-col justify-start items-start text-white p-4 break-words gap-2 overflow-y-scroll">
                    <div className="w-full font-semibold inline-flex justify-start items-center gap-2">
                        <VscDebugBreakpointLog />
                        <p>How do I activate my account?</p>
                    </div>
                    <hr className=" w-full border-zinc-600" />
                    <p className="w-full font-light text-sm">1. Sign up business account at <span className="text-[#F4E9A5] hover:underline cursor-pointer" onClick={(e) => {window.open('https://shopping-platform-front.vercel.app/member/signup/business'); }}>this link</span>.</p>
                    <p className="w-full font-light text-sm">2. Sign in account from Google.</p>
                    <p className="w-full font-light text-sm">3. Go to Account Profile.</p>
                    <p className="w-full font-light text-sm">4. Set your password.</p>
                    <p className="w-full font-light text-sm">5. Done.</p>
                    <hr className=" w-full border-zinc-600" />

                    <div className="w-full font-semibold inline-flex justify-start items-center gap-2">
                        <VscDebugBreakpointLog />
                        <p>Info</p>
                    </div>
                    <hr className=" w-full border-zinc-600" />
                    <p className="w-full font-light text-sm">If you encountered any difficulties in using our services, you can email us at <span className=" text-[#F4E9A5] hover:underline cursor-pointer" onClick={(e) => {window.open(`mailto:${e.currentTarget.innerText}`); }}>user1.inanin@gmail.com</span>. </p>
                    <p className="w-full font-light text-sm">We recommend to use our services with Google Chrome browser in desktop environment.</p>
                    <p className="w-full font-light text-sm">This website is meant to be accessed with business accounts only. Please do not share this link publicly for security reasons.</p>
                    <hr className=" w-full border-zinc-600" />

                    <div className="w-full font-semibold inline-flex justify-start items-center gap-2">
                        <VscDebugBreakpointLog />
                        <p>Browser Compatibility</p>
                    </div>
                    <hr className=" w-full border-zinc-600" />
                    <p className="w-full font-light text-sm">For Mac OS 10.11 El Capitan or older versions, please use Firefox instead.</p>
                    <p className="w-full font-light text-sm">You can evaluate whether your OS is compatible with <span className=" text-[#F4E9A5] hover:underline cursor-pointer" onClick={(e) => {window.open('https://www.ssllabs.com/ssltest/analyze.html?d=apinai.xyz'); }}>this test</span>. </p>
                    <hr className=" w-full border-zinc-600" />

                    <div className=" w-full flex flex-col justify-start items-start font-light text-sm break-words mt-5">
                        <p className="w-full">Copyright © <span className=" font-mono italic text-zinc-300">{new Date().getFullYear()}</span></p>
                        <p className="w-full text-[#F4E9A5] hover:underline cursor-pointer" onClick={() => {window.open('https://inanin.com/page/official/Home')}}>iN and iN Management Ltd.</p>
                        <p className="w-full">All rights reserved.</p>
                    </div>

                    <p className=" font-light text-sm mt-5 text-zinc-500 cursor-pointer hover:text-[#F4E9A5] select-none"
                        onClick={() =>{
                            localStorage.setItem('inanin-dismiss', '1');
                            setDismissMessage(true);
                        }}
                    >
                        Never Display This Again
                    </p>
                </div>
            </div>

            <div className=" relative w-full h-full flex justify-center items-center">
                <div className="w-full h-full absolute -z-10 select-none pointer-events-none">
                    <img alt="bg" src="./images/color-gradient1.png" className=" cover" />
                    <p className=" absolute right-0 bottom-0 m-2 text-white text-xs font-mono">
                        v916bce8
                    </p>
                </div>

                <div className=' relative z-10 w-full sm:w-1/2 h-fit pr-2 pt-2 m-5 shadow-[0_0px_60px_10px_rgba(255,255,255,0.3)] rounded-lg flex flex-col items-center justify-center gap-3'>

                    <p className=" font-bold text-white">Login</p>

                    <div className=' w-full h-fit flex items-start justify-center gap-2'>
                        <p className=' text-white pl-1'>username</p>
                        <input type="text" id="username" defaultValue={username} className="border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required
                        onKeyUp={(e) =>{
                        if (e.key === 'Enter'){
                            onEnterFocus('password');
                        }
                        e.preventDefault();
                        }}
                        onChange={(e) =>{
                            setUsername(e.target.value);
                        }}/>
                    </div>

                    <div className=' w-full h-fit flex items-start justify-center gap-2'>
                        <p className=' text-white pl-1'>password</p>
                        <input type="password" id="password" defaultValue={password} className=" bg-[#7183ad] border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required
                        onKeyUp={(e) =>{
                        if (e.key === 'Enter'){
                            enter(username, password);
                        }
                        e.preventDefault();
                        }}
                        onChange={(e) =>{
                            setPassword(e.target.value);
                        }}/>
                    </div>

                    <div className=" text-white text-xs w-full h-fit flex justify-end select-none">
                        <div className=" inline-flex gap-1 justify-center items-center cursor-pointer hover:underline"
                            onClick={() =>{
                                window.open('https://shopping-platform-front.vercel.app/member/recovery');
                            }}
                        >
                            <FaQuestionCircle />
                            <p>Forget Password</p>
                        </div>
                    </div>

                    <div className=' w-full relative'>
                        <p className=' absolute right-0 bg-white p-1 pl-2 pr-2 rounded-md border-black border-[1px] hover:cursor-pointer hover:bg-gray-100'
                        onClick={() =>{
                            enter(username, password);
                        }}>Enter</p>
                    </div>

                </div>

            </div>

        </div>

        </>
    )
}