import * as Excel from "exceljs";
import { saveAs } from "./saveAs";

export async function saveOrderExcel(
  username: string,
  order_id: string,
  products: Record<
    string,
    {
      model_id: string;
      name: string;
      price: string;
      quantity: string;
      style: string;
      style_code: number;
    }
  >,
  distributions: {
    warehouse: string;
    model_id: string;
    style_code: number;
    style: string;
    quantity: number;
    transferred: number;
  }[],
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  country: string,
  address1: string,
  address2: string,
  city: string,
  region: string,
  postal_code: string,
  shipping_method: string,
  shipping_option: string,
  payment_method: string,
  created_at: string
) {
  const workbook = new Excel.Workbook();
  workbook.creator = username;
  workbook.lastModifiedBy = username;
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const sheet = workbook.addWorksheet(order_id);
  sheet.columns = [
    { header: "", key: "A", width: 25 },
    { header: "", key: "B" },
    { header: "", key: "C" },
    { header: "", key: "D" },
    { header: "", key: "E" },
    { header: "", key: "F" },
  ];

  let currentRow = 0;

  sheet.insertRow(++currentRow, { A: "Order Record" });
  sheet.insertRow(++currentRow, { A: "Date", B: new Date().toISOString() });

  currentRow++;

  sheet.insertRow(++currentRow, { A: "Order Id", B: order_id });
  sheet.insertRow(++currentRow, { A: "Order Date", B: created_at });

  currentRow++;

  sheet.insertRow(++currentRow, { A: "Customer Order" });
  sheet.insertRow(++currentRow, { A: 'Model Id', B: 'Name', C: 'Style', D: 'Price', E: 'Quantity'});


  Object.values(products).forEach((item) =>{
    sheet.insertRow(++currentRow, { A: item.model_id, B: item.name, C: item.style, D: parseFloat(item.price), E: parseInt(item.quantity)});
  })

  currentRow++;

  sheet.insertRow(++currentRow, { A: "Customer Info" });

  sheet.insertRow(++currentRow, {A: 'Name', B: first_name, C: last_name});
  sheet.insertRow(++currentRow, {A: 'Email', B: email});
  sheet.insertRow(++currentRow, {A: 'Phone', B: phone});
  sheet.insertRow(++currentRow, {A: 'Country', B: country});
  sheet.insertRow(++currentRow, {A: 'Address1', B: address1});
  sheet.insertRow(++currentRow, {A: 'Address2', B: address2});
  sheet.insertRow(++currentRow, {A: 'City', B: city});
  sheet.insertRow(++currentRow, {A: 'Region', B: region});
  sheet.insertRow(++currentRow, {A: 'Postal Code', B: postal_code});
  sheet.insertRow(++currentRow, {A: 'Shipping Method', B: shipping_method});
  sheet.insertRow(++currentRow, {A: 'Shipping Option', B: shipping_option});
  sheet.insertRow(++currentRow, {A: 'Payment Method', B: payment_method});

  currentRow++;

  sheet.insertRow(++currentRow, { A: "Distribution" });
  sheet.insertRow(++currentRow, { A: 'Warehouse', B: 'Model Id', C: 'Style', D: 'Quantity', E: 'Transferred'});

  Object.values(distributions).forEach((item) =>{
    sheet.insertRow(++currentRow, { A: item.warehouse, B: item.model_id, C: item.style, D: item.quantity, E: parseInt(item.transferred as any) });
  })

  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, `${order_id}.xlsx`);
}
