import { motion, useAnimate } from 'framer-motion';
import { useEffect, useState } from 'react';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaGear, FaDoorClosed, FaBell, FaUserPlus } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { useAccountStore } from '../utils/zustand/useAccountStore';
import {
    Menu,
    MenuButton,
    MenuItem  
    } 
from "@szhsin/react-menu";
import { getPermission } from '../utils/getPermission';
import { useStyleStore } from '../utils/zustand/useStyleStore';
import { useTabStore } from '../utils/zustand/useTabStore';
// import "@szhsin/react-menu/dist/index.css";

import { useLocation } from 'react-router-dom';

// title, route
export const navigationButtons: Record<string, {route: string, permissionName: string}> = {
    'Stock-In': {route: 'stockin', permissionName: 'v-stockin'},
    'Stock-In Option': {route: 'stockin-opt', permissionName: 'v-stockin-opt'},
    'Inv-In': {route: 'inv-in', permissionName: 'v-stockin'},
    'Distribution': {route: 'distribution', permissionName: 'v-dis'},
    'Inventory': {route: 'inventory', permissionName: 'v-invin'},
    'Inventory Adjustment': {route: 'invadjust', permissionName: 'v-invadjust'},
    'Admin': {route: 'admin', permissionName: 'v-admin'},
    'Customer Profile': {route: 'customer-profile', permissionName: 'v-cust'},
    'Quotation': {route: 'quote', permissionName: 'v-quote'},
    'Sale Order': {route: 'sale', permissionName: 'v-sale'},
    'Customer Order': {route: 'order', permissionName: 'v-ord'},
    'Balance': {route: 'balance', permissionName: 'v-bal'},
    'Cash Payment': {route: 'cash', permissionName: 'v-cash'},
    'Warehouse': {route: 'warehouse', permissionName: 'v-warehouse'},
    'Contact': {route: 'contact', permissionName: 'v-contact'},
    'External': {route: 'external', permissionName: 'v-external'},
    'Developer': {route: 'dev', permissionName: 'v-dev'},
    '(!) Categories': {route: 'category', permissionName: 'v-cate'},
    '(!) Slide': {route: 'slide', permissionName: 'v-slide'},
    '(!) Approve Pending Account': {route:'pending-ac', permissionName: 'v-pend-ac'}
};

export function NavigationBar(props: {permission: any}){

    const navigate = useNavigate();
    const {account, setAccount, groups} = useAccountStore();
    const {colors} = useStyleStore();
    const {goTab, all_tabs} = useTabStore();

    const [hasClose, setHasClose] = useState<boolean>(true);
    const [closeWidth, setCloseWidth] = useState(0);

    const [navigationScope, navigationAnimate] = useAnimate();

    const location = useLocation();

    async function navigationOpenAnimation(){
        navigationAnimate(navigationScope.current, {x: 0}, {ease: "linear"});
    }

    async function navigationCloseAnimation(){
        navigationAnimate(navigationScope.current, {x: -closeWidth}, {ease: "linear"});
    }

    useEffect(() =>{
        const onresize = () =>{
            if (window.innerWidth < 1024){
                setHasClose(true);
                setCloseWidth(100);
            }else{
                setHasClose(false);
                setCloseWidth(250);
            }
        }

        window.addEventListener('resize', onresize);
        onresize();

        return () =>{
            window.removeEventListener('resize', onresize);
        };

    }, [])

    useEffect(() =>{
        if (hasClose){
            navigationCloseAnimation();
        }else{
            navigationOpenAnimation();
        }
    }, [hasClose])

    const [currentButton, setCurrentButton] = useState<string>('');
    useEffect(() =>{
        let page = '';
        let pages = location.pathname.slice(11).split('/');
        if (pages.length > 0){
            page = pages[0];
        }

        if (page !== ''){
            let arr = Object.values(navigationButtons);
            for(let i = 0; i < arr.length; i++){
                if (arr[i].route === page){
                    page = `navbar-${page}`;
                    break;
                }
            }
        }
        setCurrentButton(page);
    }, [location])

    return (
        <motion.div ref={navigationScope} className="relative shrink-0 h-screen flex flex-row justify-center items-center"
            initial={{x: -closeWidth}}
            style={ {
                position: hasClose ? 'absolute' : 'relative'
            }}>
            <div className="h-full flex"
                style={{
                    width: closeWidth,
                }}>
                
                <div className=' h-full flex flex-col justify-start items-center pt-8 text-sm gap-5'
                    style={{
                         width: closeWidth * 0.2,
                         backgroundColor: colors['bar-1'],
                         color: colors['bar-1-text']
                    }}
                >
                    <Menu menuButton={<MenuButton ><FaUser /></MenuButton>}>
                        {
                            groups.map((group, index) =>{
                                return (
                                    <MenuItem key={`grp-${index}`} value={group.id} className="dropdown-button"
                                        style={{
                                            backgroundColor: group.id === account.current_group ? '#3e5e9a' : 'rgb(10, 10, 12)'
                                        }}
                                        onClick={(e) =>{
                                            if (group.id === account.current_group)
                                                return;
                                            
                                            setAccount({
                                                ...account,
                                                current_group: e.value,
                                                permission: getPermission(group.permission)
                                            })
                                            navigate('/dashboard/');
                                        }}
                                    >
                                        <div className=" inline-flex items-center gap-2 whitespace-nowrap">
                                            <FaHome />
                                            <p>{group.name}</p>
                                        </div>
                                    </MenuItem>
                                )
                            })
                        }
                        <MenuItem value='+ add group' className="dropdown-button"
                            onClick={(e) =>{
                                navigate('/dashboard/add-group');
                            }}
                        >
                            <div className=" inline-flex items-center gap-2 whitespace-nowrap">
                                <FaUserPlus />
                                <p>Add Group</p>
                            </div>
                        </MenuItem>
                    </Menu>

                    <FaBell className=' cursor-pointer'
                        style={{
                            color: account.current_group === -1 ? '#52525B' : '#FFFFFF'
                        }}
                        onClick={() =>{
                            if (account.current_group === -1)
                                return;
                            
                            goTab('Notifications', '/dashboard/notification', all_tabs);
                            navigate('/dashboard/notification');
                        }}
                    />
                    <FaGear className=' cursor-pointer'
                        onClick={() =>{
                            goTab('Settings', '/dashboard/settings', all_tabs);
                            navigate('/dashboard/settings');
                        }}
                    />
                    <FaDoorClosed className=' cursor-pointer hover:text-rose-800'
                        onClick={() =>{
                            sessionStorage.removeItem('username');
                            sessionStorage.removeItem('password');
                            setAccount({
                                ...account,
                                username: '',
                                password: '',
                                permission: {},
                                user_id: -1,
                                current_group: -1
                            })
                            navigate('/');
                        }}
                    />
                </div>

                <div className=' h-full font-light text-white text-sm lg:text-base flex flex-col justify-start items-center pt-8 select-none overflow-y-scroll'
                    style={{
                        width: closeWidth * 0.8,
                        backgroundColor: colors['bar-2'],
                        color: colors['bar-2-text']
                    }}
                >
                {
                    Object.entries(navigationButtons).map(([title, item], index) =>{
                        if (props.permission[item.permissionName])
                        return (
                            <div key={`navbar-${item.route}`} id={`navbar-item-${index}`} className=' w-full hover:bg-[#3e5e9a] p-2 lg:pl-10 hover:cursor-pointer'
                            style={{
                                color: currentButton === `navbar-${item.route}` ? '#d8b765' : '#ffffff'
                            }}
                                onClick={(e) =>{
                                    if (item.route !== ''){
                                        goTab(title, `/dashboard/${item.route}`, all_tabs);
                                        navigate(`/dashboard/${item.route}`);
                                    }
                                }}>
                                <p className=' text-left break-words min-w-0 text-sm'>{title}</p>
                            </div>
                        );
                    })
                }
                </div>

            </div>

            <div className="z-20 absolute -right-4 lg:-right-6 top-20 w-8 h-8 lg:w-12 lg:h-12 border-zinc-800 rounded-full flex justify-center items-center hover:cursor-pointer hover:bg-[#eabe73]" 
                style={{
                    backgroundColor: colors['bar-but']
                }}
                onClick={(e) =>{
                    setHasClose(!hasClose);
                }}>
                <div className=" text-white">
                    {hasClose ? <AiOutlineArrowRight /> : <AiOutlineArrowLeft />}
                </div>
            </div>
        </motion.div>
    )
}