import { AiFillDelete, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlinePlus } from "react-icons/ai";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/post";
import { useEffect, useRef, useState } from "react";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useWarehouseStore } from "../utils/zustand/useWarehouseStore";

export function Warehouse(props: { permission: any }) {
  const { colors } = useStyleStore();
  const { account } = useAccountStore();
  const {setTotalWarehouse} = useWarehouseStore();

  const navigate = useNavigate();
  const selectAllheckbox = useRef<HTMLInputElement | null>(null);

  const [items, setItems] = useState<{ checked: boolean; warehouse: string }[]>(
    []
  );

  const totalLength = useRef<number>(0);
  const totalPage = useRef<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const f = async () => {
      let length = await post("getLength", {
        name: "warehouses",
        where: `\`group_id\` = '${account.current_group}'`,
      });
      totalLength.current = length.data;
      totalPage.current = Math.ceil(totalLength.current / 10);

      setCurrentPage(1);
    };
    f();
  }, []);

  useEffect(() => {
    if (totalPage.current === 0) return;

    const f = async () => {
      let result = await post("getAllWarehouses", {
        group_id: account.current_group,
        page: currentPage,
      });

      if (result.status != 1) return;

      let temp: typeof items = [];
      Object.values(result.data).forEach((item: any) => {
        temp.push({
          checked: false,
          warehouse: item.name,
        });
      });
      setItems([...temp]);
    };
    f();
  }, [currentPage]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div
        className="w-full lg:w-2/3 h-fit flex flex-row justify-end items-center mt-5 gap-2"
        style={{
          color: colors["tb-head-text"],
        }}
      >
        {props.permission["a-warehouse"] ? (
          <div
            className=" w-10 h-10 rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer"
            style={{
              backgroundColor: colors["tb-head"],
            }}
            onClick={() => {
              if (props.permission["a-warehouse"]) {
                navigate("/dashboard/add-warehouse");
              }
            }}
          >
            <AiOutlinePlus />
          </div>
        ) : (
          <></>
        )}

        {props.permission["d-warehouse"] ? (
          <div
            className=" w-10 h-10 rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer"
            style={{
              backgroundColor: colors["tb-head"],
            }}
            onClick={async (e) => {
              if (!props.permission["d-warehouse"]) return;

              let logIds: string[] = [];
              let total: string[] = [];
              for (let i = 0; i < items.length; i++) {
                if (items[i].checked) {
                  logIds.push(items[i].warehouse);
                } else {
                  total.push(items[i].warehouse);
                }
              }
              if (logIds.length === 0) {
                alert("No reocrd selected.");
                return;
              }
              let _confirm = window.confirm('Are you sure to delete warehouse records?');
              if (!_confirm)
                return;

              await post(
                "deleteWarehouses",
                {
                  group_id: account.current_group,
                  name: logIds,
                },
                true
              );
              setTotalWarehouse(total);
              navigate("/dashboard");
            }}
          >
            <AiFillDelete />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className=" mt-5 w-full lg:w-2/3 h-fit">
        <table className=" table-auto  whitespace-nowrap">
          <thead style={{ color: colors["tb-head-text"] }}>
            <tr>
              <th>
                <div
                  className=" rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]"
                  style={{
                    backgroundColor: colors["tb-head"],
                  }}
                >
                  <input
                    ref={selectAllheckbox}
                    type="checkbox"
                    className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    onChange={(e) => {
                      let temp = items;
                      for (let i = 0; i < temp.length; i++) {
                        temp[i].checked = selectAllheckbox.current!.checked;
                      }
                      setItems([...temp]);
                    }}
                  />
                </div>
              </th>
              <th className="w-full text-start">
                <div
                  className=" flex justify-center items-center gap-2 pl-2 pr-2"
                  style={{
                    backgroundColor: colors["tb-head"],
                  }}
                >
                  <p>Warehouse</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody style={{ color: colors["tb-row-text"] }}>
            {items.map((item, index) => {
              let bgColor = colors["tb-row-1"];
              if (index % 2 === 0) bgColor = colors["tb-row-2"];
              return (
                <tr
                  key={`stockin-${index}`}
                  style={{
                    backgroundColor: bgColor,
                  }}
                >
                  <td>
                    <div className="flex justify-center items-center h-[24px] mb-[1px]">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        onChange={(e) => {
                          let temp = items;
                          temp[index].checked = e.target.checked;
                          setItems([...temp]);

                          let allChecked: boolean = true;
                          for (let i = 0; i < temp.length; i++) {
                            if (!temp[i].checked) {
                              allChecked = false;
                            }
                          }
                          selectAllheckbox.current!.checked = allChecked;
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      className="flex justify-center items-center gap-2 pl-1 pr-1 font-semibold"
                      style={{
                        color: colors["tb-row-text"],
                      }}
                    >
                      {item.warehouse}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        className="w-full h-fit flex flex-row justify-center items-center gap-5 mt-5"
        style={{
          color: colors["text"],
        }}
      >
        {currentPage - 1 > 0 ? (
          <AiOutlineDoubleLeft
            className=" text-lg hover:cursor-pointer"
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          />
        ) : (
          <></>
        )}
        <p>{currentPage}</p>
        {currentPage + 1 > totalPage.current ? (
          <></>
        ) : (
          <AiOutlineDoubleRight
            className=" text-lg hover:cursor-pointer"
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          />
        )}
      </div>
    </div>
  );
}
