import { AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaGlobe } from "react-icons/fa";
import { useStyleStore } from "../utils/zustand/useStyleStore";


export function External(props: {permission: any}){
    const navigate = useNavigate();

    const {colors} = useStyleStore();

    if (!props.permission['v-external'])
        return <></>;

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center">
            
            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                <div className="mt-5 w-full h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                    <div className=" inline-flex gap-2 justify-center items-center">
                        <FaGlobe />
                        <p>External Links</p>
                    </div>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-start w-full">
                    <tbody>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className="w-full p-2">iN and iN Website</td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className="w-full p-2 cursor-pointer hover:underline">https://inanin.com/</td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className="w-full p-2">Recruitment Website</td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className="w-full p-2 cursor-pointer hover:underline">https://inanin.com/</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    )
}