import { useEffect, useRef, useState } from "react";
import { post } from "../utils/post";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useWarehouseStore } from "../utils/zustand/useWarehouseStore";
import { AiFillCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";

export function CashPayment(props: { permission: any }) {
  const { account } = useAccountStore();
  const { colors } = useStyleStore();
  const { total_warehouses } = useWarehouseStore();

  const [warehouse, setWarehouse] = useState<string>("");
  const [modelName, setModelName] = useState<string>("");
  const [modelId, setModelId] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const totalLength = useRef<number>(0);
  const totalPage = useRef<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [items, setItems] = useState<any[]>([]);

  const [cart, setCart] = useState<any[]>([]);

  const onSearch = async (
    name: string,
    model_id: string,
    warehouse: string
  ) => {
    let result = await post("searchInventories", {
      group_id: account.current_group,
      page: currentPage,
      modelId: model_id,
      name: name,
      warehouse: warehouse,
    });
    if (result.status === 1) {
      let temp: typeof items = [];
      Object.values(result.data).forEach((item: any) => {
        temp.push({
          price: item.price,
          model_id: item.model_id,
          name: item.name,
          style: result.style_code_ref[item.style_code],
          code: item.style_code,
          quantity: parseInt(item.quantity),
          take: 0,
        });
      });
      setItems([...temp]);

      totalLength.current = temp.length;
      totalPage.current = Math.ceil(totalLength.current / 10);
      setCurrentPage(1);
    }
  };

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" mt-14 w-full lg:w-2/3 h-fit flex flex-col justify-start items-start">
        <p className=" font-semibold" style={{ color: colors["text"] }}>
          Current Warehouse
        </p>
        <select
          value={warehouse}
          className=" w-full p-1 rounded-md border border-zinc-800 text-black bg-white"
          onChange={(e) => {
            setWarehouse(e.target.value);
          }}
        >
          <option value=""></option>
          {total_warehouses.map((warehouse, index) => {
            return (
              <option key={`w-option-${index}`} value={warehouse}>
                {warehouse}
              </option>
            );
          })}
        </select>

        <p className=" font-semibold mt-5" style={{ color: colors["text"] }}>
          Model Name
        </p>
        <input
          value={modelName}
          className=" w-full p-1 rounded-md border border-zinc-800 text-black bg-white"
          onChange={(e) => {
            setModelName(e.target.value);
          }}
        />

        <p className=" font-semibold mt-5" style={{ color: colors["text"] }}>
          Model Id
        </p>
        <input
          value={modelId}
          className=" w-full p-1 rounded-md border border-zinc-800 text-black bg-white"
          onChange={(e) => {
            setModelId(e.target.value);
          }}
        />

        <div className=" mt-3 w-full inline-flex justify-end select-none">
          <div
            className=" w-fit p-3 cursor-pointer hover:opacity-80"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
            onClick={async () => {
              if (isSearching) return;
              if (warehouse === "") {
                alert("Please pick your current warehouse location first.");
                return;
              }

              setIsSearching(true);
              await onSearch(modelName, modelId, warehouse);
              setIsSearching(false);
            }}
          >
            <FaSearch />
          </div>
        </div>

        <div className=" mt-5 w-full h-full flex flex-col justify-start items-start">
          <table
            className=" w-full"
            style={{
              color: colors["tb-row-text"],
            }}
          >
            <thead
              className=" font-semibold"
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <td>
                  <p className=" p-1">Model Id</p>
                </td>
                <td>Name</td>
                <td>Style</td>
                <td>Quantity</td>
                <td>Take</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                return (
                  <tr
                    key={`item-${index}`}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? colors["tb-row-1"]
                          : colors["tb-row-2"],
                    }}
                  >
                    <td className=" p-1">
                      <p>{item.model_id}</p>
                    </td>
                    <td>
                      <p>{item.name}</p>
                    </td>
                    <td>
                      <p>{item.style}</p>
                    </td>
                    <td>
                      <p>{item.quantity}</p>
                    </td>
                    <td>
                      <input
                        value={item.take}
                        className=" bg-white text-black pl-1 pr-1 border w-20"
                        onChange={(e) => {
                          let val = parseInt(e.target.value);
                          if (isNaN(val)) val = 0;
                          if (val > item.quantity) val = item.quantity;
                          let temp = items;
                          temp[index].take = val;
                          setItems([...temp]);
                        }}
                      />
                    </td>
                    <td>
                      <div
                        className="w-fit select-none cursor-pointer hover:opacity-80"
                        onClick={async () => {
                          if (isNaN(item.take)) return;
                          if (item.take === 0) {
                            alert("Purchase quantity cannot be zero.");
                            return;
                          }

                          let temp = cart;
                          let hasAdded: boolean = false;
                          for (let i = 0; i < temp.length; i++) {
                            let c = temp[i];
                            if (
                              c.model_id === item.model_id &&
                              c.style === item.style
                            ) {
                              if (c.quantity + item.take > item.quantity) {
                                c.quantity = item.quantity;
                              } else {
                                c.quantity += item.take;
                              }
                              hasAdded = true;
                            }
                          }
                          if (!hasAdded)
                            temp.push({
                              model_id: item.model_id,
                              name: item.name,
                              style: item.style,
                              quantity: item.take,
                              total: item.quantity,
                              price: item.price,
                              code: item.code,
                              group_id: account.current_group,
                            });
                          setCart([...temp]);
                        }}
                      >
                        <FaShoppingCart />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div
          className="w-full h-fit flex flex-row justify-center items-center gap-5 mt-5"
          style={{
            color: colors["text"],
          }}
        >
          {currentPage - 1 > 0 ? (
            <AiOutlineDoubleLeft
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
          ) : (
            <></>
          )}
          <p>{currentPage}</p>
          {currentPage + 1 > totalPage.current ? (
            <></>
          ) : (
            <AiOutlineDoubleRight
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          )}
        </div>

        <div
          className=" mt-14 w-full p-1 font-semibold rounded-tl-md rounded-tr-md"
          style={{
            backgroundColor: colors["tb-head"],
            color: colors["tb-head-text"],
          }}
        >
          <p>Cart</p>
        </div>
        <div className=" w-full h-full flex flex-col justify-start items-start">
          <table
            className=" w-full"
            style={{
              color: colors["tb-row-text"],
            }}
          >
            <tbody>
              {cart.map((item, index) => {
                return (
                  <tr
                    key={`cart-${index}`}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? colors["tb-row-1"]
                          : colors["tb-row-2"],
                    }}
                  >
                    <td className=" p-1">
                      <p>{item.model_id}</p>
                    </td>
                    <td>
                      <p>{item.name}</p>
                    </td>
                    <td>
                      <p>{item.style}</p>
                    </td>
                    <td>
                      <input
                        value={item.quantity}
                        className=" bg-white text-black pl-1 pr-1 border w-20"
                        onChange={(e) => {
                          let val = parseInt(e.target.value);
                          if (isNaN(val)) val = 0;
                          if (val > item.total) val = item.total;
                          let temp = [...cart];
                          temp[index].quantity = val;
                          setCart([...temp]);
                        }}
                      />
                    </td>
                    <td>
                      <div
                        className=" w-fit select-none cursor-pointer hover:opacity-80"
                        onClick={() => {
                          let temp = cart;
                          temp.splice(index, 1);
                          setCart([...temp]);
                        }}
                      >
                        <AiFillCloseCircle />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className=" mt-5 w-full inline-flex justify-end">
          <div
            className=" p-1 pl-2 pr-2 select-none cursor-pointer hover:opacity-80"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
            onClick={async () => {
              if (cart.length === 0) return;

              await post("addOrder", {
                user_id: -1,
                phone: "",
                first_name: "",
                last_name: "",
                address1: "",
                address2: "",
                country: "",
                city: "",
                region: "",
                postal_code: "",
                shipping_method: "",
                shipping_option: "",
                payment_method: "cash",
                products: JSON.stringify(cart),
                remarks: "",
              });

              setCart([]);
            }}
          >
            <p>Add Customer Order</p>
          </div>
        </div>
      </div>
    </div>
  );
}
