import { AiOutlineClose } from "react-icons/ai";
import { useStyleStore } from "../utils/zustand/useStyleStore"
import { useTabStore } from "../utils/zustand/useTabStore";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { BsPin } from "react-icons/bs";

export function TabBar(){

    const {colors} = useStyleStore();
    const {current_tab, current_link, all_tabs, total_tabs, goTab, deleteTabs} = useTabStore();
    const navigate = useNavigate();

    const container = useRef<HTMLDivElement | null>(null);
    const [tabWidth, setTabWidth] = useState<number>(1);

    useEffect(() =>{
        if (!container.current)
            return;

        const onresize = () =>{
            setTabWidth(container.current!.clientWidth - 10);
        }
        window.addEventListener('resize', onresize);
        onresize();

        return () =>{
            window.removeEventListener('resize', onresize);
        }
    }, [container.current])

    return (
        <div ref={container} className=" w-full min-h-[20px] text-sm h-min inline-flex justify-start items-end select-none">

            {
                Object.entries(all_tabs).map((tab, index) =>{
                    return (
                        <motion.div key={`tab-${index}`} className=" h-full shrink-0 rounded-tl-md border-l-2 border-collapse rounded-tr-md inline-flex justify-between items-start gap-2 whitespace-nowrap"
                            initial={{height: 30}}
                            whileHover={{height: 60}}
                            style={{
                                backgroundColor: colors['bar-2'],
                                color: colors['bar-2-text'],
                                borderColor: colors['bar-1'],
                                opacity: current_tab === tab[0] ? ' 0.5' : '1.0',
                                width: (tabWidth) / total_tabs
                            }}
                        >
                            <div className="w-full h-full overflow-clip hover:overflow-visible pl-2 text-outline"
                                style={{
                                    fontSize: (tabWidth) / total_tabs < 100 ? '10px' : '14px'
                                }}
                                onClick={() =>{
                                    if (current_link !== tab[0]){
                                        goTab(tab[0], tab[1], all_tabs);
                                        navigate(tab[1]);
                                    }
                                }}
                            >
                                {tab[0]}
                            </div>
                            <div className=" w-fit h-full flex justify-center items-start shrink-0 rounded-tr-md z-10">
                                {/* <div className="h-full hover:bg-zinc-400 cursor-pointer p-[2px]">
                                    <BsPin />
                                </div> */}
                                <div className="h-full hover:bg-zinc-400 cursor-pointer p-[2px]"
                                    onClick={() =>{
                                        deleteTabs([tab[0]], all_tabs);
                                        if (current_tab === tab[0])
                                            navigate('/dashboard');
                                    }}
                                >
                                    <AiOutlineClose />
                                </div>
                            </div>
                        </motion.div>
                    )
                })
            }
        </div>
    )
}