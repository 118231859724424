import * as Excel from "exceljs";
import { saveAs } from "./saveAs";

// items: {created_at: string, order_id: string, price: number}[]
export async function saveBalanceExcel(
  username: string,
  filename: string,
  timespan: string,
  items: any[],
) {
  const workbook = new Excel.Workbook();
  workbook.creator = username;
  workbook.lastModifiedBy = username;
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const sheet = workbook.addWorksheet(filename);
  sheet.columns = [
    { header: "", key: "A", width: 25 },
    { header: "", key: "B" },
    { header: "", key: "C" },
    { header: "", key: "D" },
    { header: "", key: "E" },
    { header: "", key: "F" },
  ];

  let currentRow = 0;

  sheet.insertRow(++currentRow, { A: `${timespan} Balance Report` });
  sheet.insertRow(++currentRow, { A: "Date", B: new Date().toISOString() });

  currentRow++;

  sheet.insertRow(++currentRow, { A: 'Created At', B: 'Order Id', C: 'Price' });
  for(let i = 0; i < items.length; i++){
    let item = items[i];
    sheet.insertRow(++currentRow, { A: item.created_at, B: item.order_id, C: item.price });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, `${filename}.xlsx`);
}
