import * as Excel from "exceljs";
import {saveAs, toObjectBlob} from './saveAs';

export async function saveInventoryExcel(username: string, model_id: string, name: string, inventory: Record<string, {style: string, style_code: number, warehouse: string, quantity: number}[]>, price: string, category: string, seo: string, images: string[], description: string){
    const workbook = new Excel.Workbook();
    workbook.creator = username;
    workbook.lastModifiedBy = username;
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const sheet = workbook.addWorksheet(model_id);
    sheet.columns = [
        { header: '', key: 'A'},
        { header: '', key: 'B', width: 25},
        { header: '', key: 'C'},
        { header: '', key: 'D'},
        { header: '', key: 'E', width: 25},
        { header: '', key: 'F', width: 50}
      ];

      let currentRow = 0;

      sheet.insertRow(++currentRow, {A: 'Inventory Record'});
      sheet.insertRow(++currentRow, {A: 'Date', B: new Date().toISOString()});

      currentRow++;

      sheet.insertRow(++currentRow, {A: 'Model Id', B: 'Product Name', C: 'Price', D: 'Category'});
      sheet.insertRow(++currentRow, {A: model_id, B: name, C: parseFloat(price), D: category}).alignment = { wrapText: true};

      let model_id_cell = sheet.getCell(`A${currentRow}`);
      model_id_cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '96C8FB' },
        bgColor: { argb: '96C8FB' }
      }
      model_id_cell.font = {bold: true};

      ++currentRow;

      sheet.insertRow(++currentRow, {A: 'Inventory'});
      sheet.insertRow(++currentRow, {A: 'Warehouse', B: 'Style', C: 'Style Code', D: 'Quantity'});
      let quantity: any[] = [];
      Object.values(inventory).forEach((items) =>{
        let total = 0;
        items.forEach((item) =>{
            quantity.push({A: item.warehouse, B: item.style ? item.style : '', C: parseInt(item.style_code as any), D: parseInt(item.quantity as any)});
            total += item.quantity;
        })
        if (items.length > 1)
            quantity.push({C: 'Total', D: total});
        quantity.push({});
      })
      let quantityRows = sheet.insertRows(++currentRow, quantity);
      quantityRows.forEach((row, index) =>{
        row.eachCell((cell) =>{
            if (index % 2 === 0)
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '96C8FB' },
                    bgColor: { argb: '96C8FB' }
                };
            else
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'd0e2f5' },
                    bgColor: { argb: 'd0e2f5' }
                };
        })
      })

      currentRow += quantity.length;

      sheet.insertRow(++currentRow, {A: 'Images'});

      currentRow++;

      for(let i = 0; i < images.length; i++){
        let imageArr = images[i].split('.');
        if (imageArr.length !== 2)
            continue;

        let extension: "jpeg" | "png" | "gif" = imageArr[1] as any;
        let url = `https://inanin.com/shopping-platform/commerce-backend/uploads/${model_id}%23${images[i]}`;
        let blob = await toObjectBlob(url);
        let bmp = await createImageBitmap(blob);
        const { width, height } = bmp;
        bmp.close(); // free memory
        let arrayBuffer = await blob.arrayBuffer();

        let imageId = workbook.addImage({buffer: arrayBuffer, extension: extension });

        let scaleX = 200 / width;
        let scaleY = 200 / height;
        let finalScale = scaleX;
        if (scaleY < scaleX)
            finalScale = scaleY;
    
        sheet.addImage(imageId, `A${currentRow}:B${currentRow + 5}`);
        currentRow += 6;
    }

    sheet.insertRow(++currentRow, {E: 'SEO', F: seo}).alignment = {wrapText: true};
    sheet.insertRow(++currentRow, {E: 'Description', F: description}).alignment = {wrapText: true};

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([buffer], {type: fileType});
    saveAs(blob, `${model_id}.xlsx`);
}
