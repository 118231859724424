import { motion } from "framer-motion";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";

export function ModifyContact() {
  const { colors } = useStyleStore();
  const { account } = useAccountStore();
  const navigate = useNavigate();
  const { name } = useParams();

  const [contactId, setContactId] = useState<number>(-1);

  const nameInputField = useRef<HTMLInputElement | null>(null);
  const accountNameInputField = useRef<HTMLInputElement | null>(null);
  const phoneInputField = useRef<HTMLInputElement | null>(null);
  const emailInputField = useRef<HTMLInputElement | null>(null);
  const remarkInputField = useRef<HTMLTextAreaElement | null>(null);

  const [accountNameStatus, setAccountNameStatus] = useState<number>(0);
  const [accountNameMessage, setAccountNameMessage] = useState<{
    message: string;
    path: string;
    id: number;
  }>({ message: "", path: "", id: -1 });

  useEffect(() => {
    if (!name) return;

    const f = async () => {
      let result = await post("getContact", {
        group_id: account.current_group,
        name: name,
      });

      if (result.status === 0) return;
      nameInputField.current!.value = result.name;
      if (result.user_id !== "-1") {
        accountNameInputField.current!.value = result.username;
        setAccountNameStatus(1);
        setAccountNameMessage({
          message: result.username,
          path: result.path,
          id: result.user_id,
        });
      }
      phoneInputField.current!.value = result.phone;
      emailInputField.current!.value = result.email;
      remarkInputField.current!.value = result.remark;

      setContactId(result.id);
    };
    f();
  }, [name]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" w-full lg:w-2/3 h-fit flex flex-col justify-end items-center mt-5">
        <div
          className="w-full h-fit flex flex-row justify-end items-start gap-5"
          style={{
            color: colors["text"],
          }}
        >
          <motion.div
            className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
              if (!nameInputField.current!.value) {
                alert("Name not filled.");
                return;
              }

              await post(
                "updateContact",
                {
                  id: contactId,
                  group_id: account.current_group,
                  name: nameInputField.current!.value,
                  user_id: accountNameMessage.id,
                  phone: phoneInputField.current!.value,
                  email: emailInputField.current!.value,
                  remark: remarkInputField.current!.value,
                },
                true
              );

              navigate("/dashboard/contact");
            }}
          >
            Save
          </motion.div>
        </div>

        <div className="w-full h-fit flex flex-col justify-center items-center">
          <div
            className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <p className=" font-semibold">Modify Contact</p>
          </div>
        </div>

        <table
          className="table-auto whitespace-nowrap text-center"
          style={{
            color: colors["tb-row-text"],
          }}
        >
          <tbody>
            <tr style={{ backgroundColor: colors["tb-row-1"] }}>
              <td className=" pl-2">Name</td>
              <td className=" w-full pl-2">
                <input
                  ref={nameInputField}
                  type="text"
                  className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  onChange={(e) => {}}
                />
              </td>
            </tr>
            <tr style={{ backgroundColor: colors["tb-row-2"] }}>
              <td className=" pl-2">Account Name</td>
              <td>
                <div className=" w-full pl-2 inline-flex justify-start items-center gap-2">
                  <input
                    ref={accountNameInputField}
                    type="text"
                    className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    onChange={(e) => {}}
                  />
                  <div
                    className=" p-2 text-xl select-none cursor-pointer hover:opacity-80"
                    style={{
                      backgroundColor: colors["tb-head"],
                      color: colors["tb-head-text"],
                    }}
                    onClick={async () => {
                      if (!accountNameInputField.current!.value) {
                        alert("Account name cannot be empty.");
                        return;
                      }

                      let result = await post("getUserByName", {
                        name: accountNameInputField.current!.value,
                      });
                      setAccountNameStatus(result.status);
                      if (result.status === 1) {
                        setAccountNameMessage({
                          message: accountNameInputField.current!.value,
                          path: result.data,
                          id: result.id,
                        });
                      } else {
                        setAccountNameMessage({
                          message: "",
                          path: "",
                          id: -1,
                        });
                        alert("User not found.");
                      }
                    }}
                  >
                    <AiOutlineSearch />
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ backgroundColor: colors["tb-row-2"] }}>
              <td></td>
              <td>
                <div
                  className=" w-full justify-between items-start pl-2"
                  style={{
                    display: accountNameStatus === 0 ? "none" : "inline-flex",
                  }}
                >
                  <p>{accountNameMessage.message}</p>
                  <div
                    className=" w-[100px] h-[100px] rounded-full p-1"
                    style={{
                      backgroundColor: colors["bar-1"],
                    }}
                  >
                    {accountNameMessage.path ? (
                      <img
                        className=" contain rounded-full"
                        src={`https://inanin.com/shopping-platform/commerce-backend/uploads/${accountNameMessage.path}`}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ backgroundColor: colors["tb-row-1"] }}>
              <td className=" pl-2">Phone</td>
              <td className=" w-full pl-2">
                <input
                  ref={phoneInputField}
                  type="text"
                  className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  onChange={(e) => {}}
                />
              </td>
            </tr>
            <tr style={{ backgroundColor: colors["tb-row-2"] }}>
              <td className=" pl-2">Email</td>
              <td className=" w-full pl-2">
                <input
                  ref={emailInputField}
                  type="text"
                  className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  onChange={(e) => {}}
                />
              </td>
            </tr>
            <tr style={{ backgroundColor: colors["tb-row-1"] }}>
              <td className=" pl-2">Remark</td>
              <td className=" w-full pl-2">
                <textarea
                  ref={remarkInputField}
                  className="mb-2 mt-2 border-[2px] min-h-[100px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  onChange={(e) => {}}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
