
import CryptoJS from "crypto-js";

export async function post(name: string, data?: any, noResponse?: true){

    const auth = CryptoJS.AES.encrypt(
        'Jacky',
        name
        ).toString();
    
    let result = await fetch(`https://inanin.com/shopping-platform/commerce-backend/public/api/${name}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Auth": auth,
            "Name": name
        },
        body: JSON.stringify(data)
    });

    if (!noResponse){
        let getData = await result.json();

        return getData;
    }
}

export async function postEShop(name:string, data?: any, noResponse?: true){
    
    let keys: string[] = Object.keys(data);

    const _data = {
        ... data,
        keys: keys
    };

    const auth = CryptoJS.AES.encrypt(
        'Jacky',
        name
        ).toString();
    
    let result = await fetch(`https://inanin.com/shopping-platform/commerce-backend/public/api/postEShop`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Auth": auth,
            "Name": name
        },
        body: JSON.stringify(_data)
    });

    if (!noResponse){
        let getData = await result.json();

        return getData;
    }
}