import * as Excel from "exceljs";
import { saveAs } from "./saveAs";
import { RedistributionProps } from "../../distribution/ModifyDistribution";

export async function saveDistributionExcel(
  username: string,
  redelivery_Id: string,
  distributions: RedistributionProps[],
  checked_by: string,
  checked_at: string
) {
  const workbook = new Excel.Workbook();
  workbook.creator = username;
  workbook.lastModifiedBy = username;
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const sheet = workbook.addWorksheet(redelivery_Id);
  sheet.columns = [
    { header: "", key: "A", width: 25 },
    { header: "", key: "B" },
    { header: "", key: "C" },
    { header: "", key: "D" },
    { header: "", key: "E" },
    { header: "", key: "F" },
  ];

  let currentRow = 0;

  sheet.insertRow(++currentRow, { A: "Distribution Record" });
  sheet.insertRow(++currentRow, { A: "Date", B: new Date().toISOString() });

  currentRow++;

  sheet.insertRow(++currentRow, { A: "Redelivery Id", B: redelivery_Id });
  sheet.insertRow(++currentRow, { A: "Checked By", B: checked_by });
  sheet.insertRow(++currentRow, { A: "Checked Date", B: checked_at });

  currentRow++;

  sheet.insertRow(++currentRow, { A: 'Model Id', B: 'Name', C: 'Style', D: 'Delivered', E: 'Total', F: 'Broken' });
  for(let i = 0; i < distributions.length; i++){
    let dis = distributions[i];
    sheet.insertRow(++currentRow, { A: dis.model_id, B: dis.name, C: dis.style, D: dis.expected, E: dis.actual, F: dis.broken });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, `${redelivery_Id}.xlsx`);
}
