import { useEffect, useRef, useState } from "react";
import { AiFillDelete, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlinePlus } from "react-icons/ai"
import { useNavigate } from "react-router-dom";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useTabStore } from "../utils/zustand/useTabStore";


export function StockIn(props: {permission: any}){

    const navigate = useNavigate();

    const selectAllheckbox = useRef<HTMLInputElement | null>(null);

    const [items, setItems] = useState<{checked: boolean, logId: string, canSave: boolean, created_at: string, redis_items: any[]}[]>([]);

    const {account} = useAccountStore();
    const {colors} = useStyleStore();
    const {goTab, all_tabs} = useTabStore();

    const totalLength = useRef<number>(0);
    const totalPage = useRef<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const fetchCount = useRef<number>(0);

    useEffect(() =>{

        const f = async () =>{
            fetchCount.current++;
            let length = await post('getLength', {
                name: 'log_records',
                where: `\`group_id\` = '${account.current_group}' AND \`deleted_at\` IS NULL`
            });
            totalLength.current = length.data;
            totalPage.current = Math.ceil(totalLength.current / 20);

            setCurrentPage(1);
            fetchCount.current--;
        }
            f();
    }, [])

    useEffect(() =>{
        if (totalPage.current === 0)
        return;

        const f = async() =>{
            let result = await post('getAllLogs', {
                page: currentPage,
                group_id: account.current_group
            })

            if (result.status != 1)
                return;

            let temp = items;
            temp = [];
            Object.entries(result.data).forEach(([key, item]) =>{
                let typedItem = item as any;
                temp.push({
                    logId: typedItem.log_id,
                    canSave: typedItem.canSave,
                    created_at: typedItem.created_at,
                    checked: false,
                    redis_items: typedItem.redis_items
                })
            })

            setItems(temp)
        };
        f();
    }, [currentPage])

    return (
        <div className=" w-full h-fit flex flex-col justify-center items-center">

            <div className="w-full lg:w-2/3 h-fit flex flex-row justify-end items-center mt-5 gap-2"
                style={{
                    color: colors['tb-head-text']
                }}
            >

                {
                    props.permission['a-stockin'] ?
                    <div className=' w-10 h-10 rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer'
                        style={{
                            backgroundColor: colors['tb-head']
                        }}
                        onClick={() =>{
                            if (props.permission['a-stockin']){
                                goTab('Add Stock-In', '/dashboard/add-stockin', all_tabs);
                                navigate('/dashboard/add-stockin');
                            }
                        }}>
                        <AiOutlinePlus />
                    </div> : <></>
                }

                {
                    props.permission['d-stockin'] ?
                    <div className=' w-10 h-10 rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer'
                        style={{
                            backgroundColor: colors['tb-head']
                        }}    
                        onClick={async(e) =>{
                            if (!props.permission['d-stockin'])
                                return;

                            let logIds: string[] = [];
                            for(let i = 0; i < items.length; i++){
                                if (items[i].checked){
                                    logIds.push(items[i].logId);
                                }
                            }
                            if (logIds.length === 0){
                                alert('No reocrd selected.');
                                return;
                            }

                            let _confirm = window.confirm(`Are you sure to delete ${logIds.length} records?`);
                            if (!_confirm)
                                return;

                            await post('deleteLogs', {
                                group_id: account.current_group,
                                data: logIds
                            });

                            navigate('/dashboard');
                        }}>
                        <AiFillDelete />
                    </div> : <></>
                }

            </div>

            <div className=" mt-5 w-full lg:w-2/3 h-fit">
                <table className="table-auto  whitespace-nowrap">
                    <thead
                        style={{
                            color: colors['tb-head-text']
                        }}
                    >
                        <tr>
                            <th>
                                <div className=" rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]"
                                    style={{
                                        backgroundColor: colors['tb-head']
                                    }}
                                >
                                    <input ref={selectAllheckbox} type="checkbox" className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" 
                                        onChange={(e) =>{
                                            let temp = items;
                                            for(let i = 0; i < temp.length; i++){
                                                if (temp[i].canSave)
                                                    temp[i].checked = selectAllheckbox.current!.checked;
                                            }
                                            setItems([...temp]);
                                        }} />
                                </div>
                            </th>
                            <th >
                                <div className=" flex justify-center items-center pl-2 pr-2"
                                    style={{
                                        backgroundColor: colors['tb-head']
                                    }}
                                >
                                    <p>Created At</p>
                                </div>
                            </th>
                            <th className=" w-full">
                                <div className=" flex justify-center items-center pl-2 pr-2"
                                    style={{
                                        backgroundColor: colors['tb-head']
                                    }}
                                >
                                    <p>Delivery Id</p>
                                </div>
                            </th>
                            <th>
                                <div className=" flex justify-center items-center pl-2 pr-2"
                                    style={{
                                        backgroundColor: colors['tb-head']
                                    }}
                                >
                                    <p>Status</p>
                                </div>
                            </th>
                        </tr> 
                    </thead>
                    <tbody>
                        {
                            items.map((item, index) =>{
                                let bgColor = colors['tb-row-1'];
                                if (index % 2 === 0)
                                    bgColor = colors['tb-row-2'];
                                return (
                                    <tr key={`stockin-${index}`} style={{
                                        backgroundColor: bgColor
                                    }}>
                                        <td>
                                            <div className="flex justify-center items-center h-[24px] mb-[1px]">
                                                <input type="checkbox" disabled={!item.canSave} checked={item.checked} className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" 
                                                    onChange={(e) =>{
                                                        let temp = items;
                                                        temp[index].checked = e.target.checked;
                                                        setItems([...temp]);

                                                        let allChecked: boolean = true;
                                                        for(let i = 0; i < temp.length; i++){
                                                            if (!temp[i].checked){
                                                                allChecked = false;
                                                            }
                                                        }
                                                        selectAllheckbox.current!.checked = allChecked;
                                                }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className=" flex justify-center items-center"
                                                style={{
                                                    color: colors['tb-row-text']
                                                }}
                                            >
                                                <p>{item.created_at}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="  flex justify-center items-center"
                                                onClick={() =>{
                                                    if (props.permission['o-stockin']){
                                                        goTab(`Modify Stock-In ${item.logId}`, `/dashboard/modify-stockin/${item.logId}`, all_tabs);
                                                        navigate(`/dashboard/modify-stockin/${item.logId}`);
                                                    }
                                                }}>
                                                <p className="hover:cursor-pointer font-semibold"
                                                    style={{
                                                        color: item.canSave ? colors['tb-row-text-e'] : colors['tb-row-text-d']
                                                    }}>
                                                    {item.logId}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-full flex flex-col justify-start items-start">
                                                {
                                                    item.redis_items.map((redis_item, rIndex) =>{
                                                        let bgColor = colors['tb-row-1-h'];
                                                        if (rIndex % 2 === 0)
                                                            bgColor = colors['tb-row-2-h'];

                                                        let dotColor = "#ED768F";
                                                        if (redis_item.status === "incoming") dotColor = "#FDC470";
                                                        if (redis_item.status === "finished") dotColor = "#5AB770";
                                                        return (
                                                            <div key={`stockin-${index}-${rIndex}`} className="w-full flex justify-between items-center gap-2 pl-1 pr-1 select-none cursor-pointer hover:underline"
                                                                style={{
                                                                    backgroundColor: bgColor
                                                                }}
                                                                onClick={() =>{
                                                                    navigate(`/dashboard/modify-distribution/${redis_item.redis_id}`);
                                                                }}
                                                            >
                                                                <p className=" font-semibold">{redis_item.warehouse}</p>
                                                                <p className=" font-mono">{redis_item.status}</p>
                                                                <div
                                                                    className=" w-2 h-2 rounded-full animate-pulse"
                                                                    style={{
                                                                    backgroundColor: dotColor,
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>

                <div className='w-full h-fit flex flex-row justify-center items-center gap-5 mt-5'
                    style={{
                        color: colors['text']
                    }}
                >
                    {
                        currentPage - 1 > 0 ? <AiOutlineDoubleLeft className=' text-lg hover:cursor-pointer' 
                            onClick={() =>{
                                setCurrentPage(currentPage - 1);
                            }}/> : <></>
                    }
                    <p>{currentPage}</p>
                    {
                        currentPage + 1 > totalPage.current ? <></> : <AiOutlineDoubleRight className=' text-lg hover:cursor-pointer'
                            onClick={() =>{
                                setCurrentPage(currentPage + 1);
                            }} />
                    }
                </div>

            </div>

        </div>
    )
}