import { create } from 'zustand'

type Account = {
    permission: any;
    user_id: number;
    username: string;
    password: string;
    current_group: number;
    image: string;
}
type Group = {
    id: number;
    name: string;
    permission: string;
    created_by: string;
    latest_notification: string;
}

interface AccountState {
    account: Account;
    groups: Group[];
    setAccount: (account: Account) => void;
    setGroups: (groups: Group[]) => void;
}

export const useAccountStore = create<AccountState>()((set) => ({
    account: {permission: {}, user_id: -1, username: '', password: '', current_group: -1, image: ''},
    groups: [],
    setAccount: (_account) =>{
        set(() => ({
            account: _account
        }))
    },
    setGroups: (_groups) =>{
        set(() => ({
            groups: _groups
        }))
    }
}))