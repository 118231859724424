

import * as Excel from "exceljs";
import {saveAs} from './saveAs';
import { DistributionProps } from "../../stock-in/ModifyStockIn";

export async function saveStockInExcel(username: string, delivery_id: string, redistributionRecords: Record<string, DistributionProps[]>, redistributionInfo: Record<string, {id: string, status: string}>){
    const workbook = new Excel.Workbook();
    workbook.creator = username;
    workbook.lastModifiedBy = username;
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const sheet = workbook.addWorksheet(delivery_id);
    sheet.columns = [
        { header: '', key: 'A', width: 25},
        { header: '', key: 'B'},
        { header: '', key: 'C'},
        { header: '', key: 'D'},
        { header: '', key: 'E'},
        { header: '', key: 'F'}
    ];

    let currentRow = 0;

    sheet.insertRow(++currentRow, {A: 'Stock-In Record'});
    sheet.insertRow(++currentRow, {A: 'Date', B: new Date().toISOString()});

    currentRow++;

    sheet.insertRow(++currentRow, {A: 'Delivery Id', B: delivery_id});

    currentRow++;

    Object.entries(redistributionInfo).forEach(([warehouse, info]) =>{
        sheet.insertRow(++currentRow, {A: 'Redelivery Id', B: 'Status'});
        sheet.insertRow(++currentRow, {A: info.id, B: info.status});
        sheet.insertRow(++currentRow, {A: 'Model Id', B: 'Name', C: 'Style', D: 'Warehouse', E: 'Quantity'});
        
        redistributionRecords[warehouse].forEach((record) =>{
            sheet.insertRow(++currentRow, {A: record.mId, B: record.n, C: record.c, D: record.w, E: parseInt(record.q)});
        })
        currentRow++;
    })

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([buffer], {type: fileType});
    saveAs(blob, `${delivery_id}.xlsx`);
}
