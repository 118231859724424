import { useEffect, useRef, useState } from "react";
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight
} from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useTabStore } from "../utils/zustand/useTabStore";

export function Distribution(props: { permission: any }) {
  const navigate = useNavigate();

  const { account } = useAccountStore();
  const { colors } = useStyleStore();
  const { goTab, all_tabs} = useTabStore();

  const selectAllheckbox = useRef<HTMLInputElement | null>(null);

  const [items, setItems] = useState<
    {
      checked: boolean;
      redisId: string;
      status: string;
      warehouse: string;
      checkedBy: string;
      created_at: string;
      canConfirm: boolean;
    }[]
  >([]);

  const totalLength = useRef<number>(0);
  const totalPage = useRef<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const f = async () => {
      let length = await post("getLength", {
        name: "log_redistribution",
        where: `\`group_id\` = '${account.current_group}'`,
      });
      totalLength.current = length.data;
      totalPage.current = Math.ceil(totalLength.current / 20);

      setCurrentPage(1);
    };
    f();
  }, []);

  useEffect(() => {
    if (totalPage.current === 0) return;

    const f = async () => {
      let result = await post("getAllDistributions", {
        page: currentPage,
        group_id: account.current_group,
      });

      if (result.status != 1) return;

      let temp = items;
      temp = [];
      Object.values(result.data).forEach((item) => {
        let typedItem = item as any;
        temp.push({
          checked: false,
          redisId: typedItem.redis_id,
          status: typedItem.status,
          warehouse: typedItem.warehouse,
          checkedBy: typedItem.checked_by,
          created_at: typedItem.created_at,
          canConfirm: typedItem.canConfirm,
        });
      });

      setItems([...temp]);
    };
    f();
  }, [currentPage]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" mt-14 w-full lg:w-2/3 h-fit">
        <table className="table-auto  whitespace-nowrap">
          <thead
            style={{
              color: colors["tb-head-text"],
            }}
          >
            <tr>
              <th>
                <div
                  className=" rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]"
                  style={{
                    backgroundColor: colors["tb-head"],
                  }}
                >
                  <input
                    ref={selectAllheckbox}
                    type="checkbox"
                    className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    onChange={(e) => {
                      let temp = items;
                      for (let i = 0; i < temp.length; i++) {
                        if (temp[i].canConfirm)
                          temp[i].checked = selectAllheckbox.current!.checked;
                      }
                      setItems([...temp]);
                    }}
                  />
                </div>
              </th>
              <th className="text-start">
                <div
                  className=" flex justify-center items-center gap-2 pl-2 pr-2"
                  style={{
                    backgroundColor: colors["tb-head"]
                  }}
                >
                  <p>Created At</p>
                </div>
              </th>
              <th className="text-start">
                <div
                  className=" flex justify-center items-center gap-2 pl-2 pr-2"
                  style={{
                    backgroundColor: colors["tb-head"]
                  }}
                >
                  <p>Status</p>
                </div>
              </th>
              <th className="text-start w-full">
                <div
                  className=" flex justify-center items-center gap-2 pl-1 pr-1"
                  style={{
                    backgroundColor: colors["tb-head"],
                    color: colors['tb-head-text']
                  }}
                >
                  <p>Redelivery Id</p>
                </div>
              </th>
              <th className="text-start">
                <div
                  className=" flex justify-center items-center gap-2 pl-2 pr-2"
                  style={{
                    backgroundColor: colors["tb-head"]
                  }}
                >
                  <p>Warehouse</p>
                </div>
              </th>
              <th className="text-start">
                <div
                  className=" flex justify-center items-center gap-2 pl-2 pr-2 rounded-tr-md"
                  style={{
                    backgroundColor: colors["tb-head"]
                  }}
                >
                  <p>Checked</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              let bgColor = colors['tb-row-1'];
              if (index % 2 === 0) bgColor = colors['tb-row-2'];

              let dotColor = "#ED768F";
              if (item.status === "incoming") dotColor = "#FDC470";
              if (item.status === "finished") dotColor = "#5AB770";
              return (
                <tr
                  key={`stockin-${index}`}
                  style={{
                    backgroundColor: bgColor,
                    color: colors['tb-row-text']
                  }}
                >
                  <td>
                    <div className="flex justify-center items-center h-[24px] mb-[1px]">
                      <input
                        type="checkbox"
                        disabled={!item.canConfirm}
                        checked={item.checked}
                        className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        onChange={(e) => {
                          let temp = items;
                          temp[index].checked = e.target.checked;
                          setItems([...temp]);

                          let allChecked: boolean = true;
                          for (let i = 0; i < temp.length; i++) {
                            if (!temp[i].checked) {
                              allChecked = false;
                            }
                          }
                          selectAllheckbox.current!.checked = allChecked;
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <p>{item.created_at}</p>
                  </td>
                  <td>
                    <div className="flex justify-start items-center gap-2 pl-1 pr-1">
                      <div
                        className=" w-2 h-2 rounded-full animate-pulse"
                        style={{
                          backgroundColor: dotColor,
                        }}
                      />
                      <p className=" font-mono">{item.status}</p>
                    </div>
                  </td>
                  <td className=" w-full">
                    <div
                      className="flex justify-center items-center hover:cursor-pointer hover:underline"
                      onClick={() => {
                        if (props.permission["o-dis"]){
                          goTab(`Modify Distribution ${item.redisId}`, `/dashboard/modify-distribution/${item.redisId}`, all_tabs);
                          navigate(
                            `/dashboard/modify-distribution/${item.redisId}`
                          );
                        }
                      }}
                    >
                      <p
                        className=" font-semibold"
                        style={{
                          color: item.canConfirm ? colors['tb-row-text-e'] : colors['tb-row-text-d'],
                        }}
                      >
                        {item.redisId}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-center items-center">
                      <p>{item.warehouse}</p>
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-center items-center">
                      {item.checkedBy ? item.checkedBy : <GrClose />}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="w-full h-fit flex flex-row justify-center items-center gap-5 mt-5"
            style={{
                color: colors['text']
            }}
        >
          {currentPage - 1 > 0 ? (
            <AiOutlineDoubleLeft
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
          ) : (
            <></>
          )}
          <p>{currentPage}</p>
          {currentPage + 1 > totalPage.current ? (
            <></>
          ) : (
            <AiOutlineDoubleRight
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
