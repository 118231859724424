import {
  AiOutlineClose,
  AiOutlinePlus,
  AiOutlineRedo,
  AiOutlineSearch,
  AiOutlineSwapLeft,
} from "react-icons/ai";
import { post } from "../utils/post";
import { useEffect, useRef, useState } from "react";
import { FaShuttleVan, FaWarehouse } from "react-icons/fa";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { saveInvInExcel } from "../utils/report/saveInvInExcel";
import { useWarehouseStore } from "../utils/zustand/useWarehouseStore";

export function ModifyInvIn(props: { permission: any }) {
  const { account } = useAccountStore();
  const { colors } = useStyleStore();
  const { name } = useParams();

  const {total_warehouses} = useWarehouseStore();

  const modelIdInputField = useRef<HTMLInputElement | null>(null);
  const invId = useRef<string>("");

  const [inventory, setInventory] = useState<
    Record<
      string,
      {
        modelId: string;
        name: string;
        style: string;
        style_code: number;
        warehouse: string;
        quantity: number;
        distribution: { to: string; quantity: number }[];
      }[]
    >
  >({});
  const [redistributionRecords, setRedistributionRecords] = useState<
    Record<
      string,
      {
        modelId: string;
        name: string;
        style: string;
        style_code: number;
        quantity: number;
      }[]
    >
  >({});
  const [redistributionInfo, setRedistributionInfo] = useState<
    Record<string, { id: string; status: string; from: string; to: string }>
  >({});

  const [canSave, setCanSave] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!name) return;

    setInventory({});
    setRedistributionRecords({});
    setRedistributionInfo({});

    invId.current = name;

    const f = async () => {
      let result = await post("getInvIn", {
        group_id: account.current_group,
        invId: invId.current,
      });
      setCanSave(result.canSave);

      let temp: typeof inventory = {};
      let temp2: typeof redistributionInfo = {};

      Object.entries(result.inventory).forEach(([modelId, inventory]) => {
        let typedInventory = inventory as any;
        Object.entries(typedInventory).forEach(([name, distribution_items]) => {
          let typed_distribution_items = distribution_items as any;

          Object.values(typed_distribution_items).forEach((item) => {
            let typedItem = item as any;
            if (!temp[typedItem.warehouse]) temp[typedItem.warehouse] = [];

            let index = temp[typedItem.warehouse].findIndex(
              (e) =>
                e.modelId === modelId && e.style_code === typedItem.style_code
            );
            if (index > -1) {
              temp[typedItem.warehouse][index].quantity += parseInt(
                typedItem.quantity
              );
            } else {
              temp[typedItem.warehouse].push({
                name: name,
                modelId: modelId,
                style: result.style_code_ref[typedItem.style_code],
                style_code: typedItem.style_code,
                warehouse: typedItem.warehouse,
                quantity: parseInt(typedItem.quantity),
                distribution: [],
              });
            }
          });
        });
      });
      Object.entries(result.distribution).forEach(
        ([redis_id, distributions]) => {
          let typed_distributions = distributions as any;
          temp2[
            `${typed_distributions.fromWarehouse}-${typed_distributions.toWarehouse}`
          ] = {
            id: redis_id,
            status: typed_distributions.status,
            from: typed_distributions.fromWarehouse,
            to: typed_distributions.toWarehouse,
          };
          Object.values(typed_distributions.distribute).forEach((item) => {
            let typedItem = item as any;
            if (!temp[typed_distributions.fromWarehouse]) {
              temp[typed_distributions.fromWarehouse] = [
                {
                  name: name,
                  modelId: typedItem.model_id,
                  style: result.style_code_ref[typedItem.style_code],
                  style_code: typedItem.style_code,
                  warehouse: typed_distributions.fromWarehouse,
                  quantity: parseInt(typedItem.quantity),
                  distribution: [],
                },
              ];
            }
            for (
              let i = 0;
              i < temp[typed_distributions.fromWarehouse].length;
              i++
            ) {
              let tempItem = temp[typed_distributions.fromWarehouse][i];
              if (
                tempItem.modelId === typedItem.model_id &&
                tempItem.style_code === typedItem.style_code &&
                tempItem.warehouse === typed_distributions.fromWarehouse
              ) {
                temp[typed_distributions.fromWarehouse][i].distribution.push({
                  to: typed_distributions.toWarehouse,
                  quantity: parseInt(typedItem.quantity),
                });
              }
            }
          });
        }
      );
      setInventory({ ...temp });
      setRedistributionInfo({ ...temp2 });
    };
    f();
  }, [name]);

  useEffect(() => {
    let temp = redistributionRecords;
    temp = {};
    let temp2 = redistributionInfo;
    temp2 = {};

    Object.entries(inventory).forEach(([warehouse, items]) => {
      Object.values(items).forEach((item) => {
        Object.values(item.distribution).forEach((dis) => {
          if (dis.to) {
            let key = `${item.warehouse}-${dis.to}`;
            if (!temp[key]){
              temp[key] = [];
            }
            if (redistributionInfo[key]){
              temp2[key] = {
                id: name + '-' + key,
                status: redistributionInfo[key].status,
                from: warehouse,
                to: dis.to,
              };
            } else {
              temp2[key] = {
                id:name + '-' + key,
                status: 'not started',
                from: warehouse,
                to: dis.to,
              };
            }

            temp[key].push({
              modelId: item.modelId,
              name: item.name,
              style: item.style,
              style_code: item.style_code,
              quantity: dis.quantity ? dis.quantity : 0,
            });
          }
        });
      });
    });
    setRedistributionRecords({ ...temp });
    setRedistributionInfo({ ...temp2 });
  }, [inventory]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      
      <div className=" mt-5 w-full lg:w-2/3 h-fit flex justify-end items-end">
        <motion.div className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
            style={{
                backgroundColor: colors['tb-head'],
                color: colors['tb-head-text']
            }}
            initial={{scale: 1}}
            whileHover={{scale: 1.2}}
            onClick={async() =>{
                saveInvInExcel(account.username, name ? name : 'UNDEFINED', redistributionRecords, redistributionInfo)
            }}
        >
            Export Excel
        </motion.div>
    </div>
      
      <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">
        {!canSave ? (
          <div
            className="w-full flex justify-end font-bold"
            style={{ color: colors["text"] }}
          >
            Items in this record were modified by other records. You can't edit
            this record again.
          </div>
        ) : (
          <></>
        )}

        <div
          className="w-full h-fit flex-row justify-end items-center text-white gap-5"
          style={{
            display: canSave ? "flex" : "none",
          }}
        >
          <motion.div
            className=" w-fit h-fit bg-[#649d7f] flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
              if (!props.permission["m-invin"]) return;

              let warehouses = Object.keys(redistributionInfo);
              for (let i = 0; i < warehouses.length; i++) {
                if (redistributionInfo[warehouses[i]].id === "") {
                  alert("Re-Delivery Id cannot be null.");
                  return;
                }
              }

              let _redistributionRecords: {
                status: string;
                from: string;
                to: string;
                items: {
                  modelId: string;
                  name: string;
                  style: string;
                  quantity: number;
                }[];
              }[] = [];
              for (let i = 0; i < warehouses.length; i++) {
                _redistributionRecords.push({
                  status: redistributionInfo[warehouses[i]].status,
                  from: redistributionInfo[warehouses[i]].from,
                  to: redistributionInfo[warehouses[i]].to,
                  items: redistributionRecords[warehouses[i]],
                });
              }

              await post("addInvRedistribution", {
                group_id: account.current_group,
                inv_id: invId.current,
                action: "update",
                items: JSON.stringify(_redistributionRecords),
                user: account.username,
              });
              navigate(-1);
            }}
          >
            Save
          </motion.div>
        </div>
      </div>

      <div className=" w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-2">
        <div
          className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2"
          style={{
            backgroundColor: colors["tb-head"],
            color: colors["tb-head-text"],
          }}
        >
          <p>Inventory to Distribution</p>

          <div
            className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
            onClick={() => {
              // navigate('/dashboard/distribution');
            }}
          >
            <AiOutlineSwapLeft className=" text-lg " />
          </div>
        </div>

        <table
          className="table-auto whitespace-nowrap text-center w-full"
          style={{
            color: colors["row-text"],
          }}
        >
          <tbody>
            <tr style={{ backgroundColor: colors["tb-row-1"] }}>
              <td className=" pl-2">Model Id</td>
              <td className=" w-full pl-2">
                <div className=" flex justify-center items-center gap-2 mr-2">
                  <input
                    ref={modelIdInputField}
                    type="text"
                    className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                    required
                  />
                  <div
                    className="p-2 hover:cursor-pointer"
                    style={{
                      backgroundColor: colors["tb-head"],
                      color: colors["tb-head-text"],
                    }}
                    onClick={async () => {
                      if (!modelIdInputField.current!.value) return;
                      modelIdInputField.current!.value =
                        modelIdInputField.current!.value.trim();
                      let vals = Object.values(inventory);
                      for (let i = 0; i < vals.length; i++) {
                        for (let k = 0; k < vals[i].length; k++) {
                          if (
                            vals[i][k].modelId ===
                            modelIdInputField.current!.value
                          )
                            return;
                        }
                      }

                      let result = await post("getInventory", {
                        group_id: account.current_group,
                        modelId: modelIdInputField.current!.value,
                      });
                      if (result.status === 0){
                        alert('Item not found.');
                        return;
                      }

                      if (!result.data["name"]) {
                        alert("Item not exist.");
                        return;
                      }

                      if (result.data["inv"].length === 0){
                        alert('Item quatity is zero in inventory.');
                        return;
                      }

                      let temp = inventory;
                      Object.values(result.data["inv"]).forEach((item) => {
                        let typedItem = item as any;
                        if (!temp[typedItem.warehouse])
                          temp[typedItem.warehouse] = [];

                        temp[typedItem.warehouse].push({
                          modelId: result.data["model_id"],
                          name: result.data["name"],
                          style:
                            result.data.style_code_ref[typedItem.style_code],
                          style_code: typedItem.style_code,
                          warehouse: typedItem.warehouse,
                          quantity: typedItem.quantity,
                          distribution: [],
                        });
                      });
                      setInventory({ ...temp });
                    }}
                  >
                    <AiOutlineSearch className="text-lg" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          className=" w-full flex flex-col justify-center items-center p-2 mt-5"
          style={{
            backgroundColor: colors["tb-row-1"],
            color: colors["tb-row-text"],
          }}
        >
          <table className="table-auto w-full whitespace-nowrap mb-5">
            <thead className=" text-black text-start font-bold">
              <tr>
                <td>Model Id</td>
                <td>Name</td>
                <td>Style</td>
                <td>Warehouse</td>
                <td>Quantity</td>
              </tr>
            </thead>
            <tbody className=" text-start">
              {Object.entries(inventory).map(([warehouse, items], index) => {
                return Object.values(items).map((item, iIndex) => {
                  let bgColor = colors["tb-row-1-h"];
                  if (index % 2 === 0) bgColor = colors["tb-row-2-h"];
                  return (
                    <tr
                      key={`record-${index}-${iIndex}`}
                      className=" relative"
                      style={{
                        backgroundColor: bgColor,
                      }}
                    >
                      <td>{item.modelId}</td>
                      <td>{item.name}</td>
                      <td>{item.style}</td>
                      <td>{item.warehouse}</td>
                      <td>{item.quantity}</td>
                      <td>
                        <div
                          className=" absolute top-0 right-0 hover:cursor-pointer"
                          onClick={() => {
                            let temp = inventory;
                            temp[warehouse].splice(iIndex, 1);

                            if (temp[warehouse].length === 0)
                              delete temp[warehouse];

                            setInventory({ ...temp });
                          }}
                        >
                          <AiOutlineClose />
                        </div>
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>

          {Object.entries(inventory).map(([warehouse, items], index) => {
            return (
              <div
                key={`inv-${index}`}
                className="w-full flex flex-col justify-center items-start mb-5 gap-2"
              >
                <p className=" font-semibold">{warehouse}</p>
                {Object.values(items).map((item, iIndex) => {
                  let left = item.quantity;
                  for (let i = 0; i < item.distribution.length; i++) {
                    if (item.distribution[i].quantity)
                      left -= item.distribution[i].quantity;
                  }
                  return (
                    <div
                      key={`inv-${index}-${iIndex}`}
                      className=" w-full flex justify-start items-start"
                    >
                      <div className=" flex flex-col w-[200px] min-w-[200px] overflow-x-scroll">
                        <p className=" whitespace-nowrap">{`${item.modelId} (${left})`}</p>
                        <p style={{ color: colors["tb-row-text-e"] }}>
                          {item.style}
                        </p>
                      </div>
                      <div
                        className=" w-full flex flex-col justify-start items-center gap-3"
                        style={{
                          backgroundColor: colors["tb-row-2"],
                        }}
                      >
                        {Object.values(item.distribution).map(
                          (disItem, dIndex) => {
                            return (
                              <div
                                key={`inv-${index}-${iIndex}-${dIndex}`}
                                className=" relative w-full flex justify-between pl-5 pr-5 pt-2 pb-2 items-center gap-5"
                              >
                                <div className=" relative w-full h-[1px] border-dashed border-[1px] border-gray-600">
                                  <FaShuttleVan className=" absolute left-0 -top-5 text-2xl animate-bounce-x hidden xl:block" />
                                  <FaWarehouse className=" absolute right-0 -top-5 text-2xl hidden xl:block" />
                                </div>
                                <select
                                  value={
                                    inventory[warehouse][iIndex].distribution[
                                      dIndex
                                    ].to
                                  }
                                  className=" p-1 max-w-[80px] text-black"
                                  onChange={(e) => {
                                    let temp = inventory;
                                    temp[warehouse][iIndex].distribution[
                                      dIndex
                                    ].to = e.target.value;
                                    setInventory({ ...temp });
                                  }}
                                >
                                  {total_warehouses.map((w, wIndex) => {
                                    if (warehouse !== w)
                                      return (
                                        <option
                                          key={`inv-${index}-${iIndex}-${dIndex}-${wIndex}`}
                                          value={w}
                                        >
                                          {w}
                                        </option>
                                      );
                                  })}
                                </select>
                                <input
                                  value={
                                    inventory[warehouse][iIndex].distribution[
                                      dIndex
                                    ].quantity
                                  }
                                  type="number"
                                  className="p-1 text-black"
                                  onChange={(e) => {
                                    let val = parseInt(e.target.value);

                                    let max = item.quantity;
                                    for (
                                      let i = 0;
                                      i < item.distribution.length;
                                      i++
                                    ) {
                                      if (i === dIndex) continue;
                                      if (item.distribution[i].quantity)
                                        max -= item.distribution[i].quantity;
                                    }
                                    if (val > max) val = max;

                                    let temp = inventory;
                                    temp[warehouse][iIndex].distribution[
                                      dIndex
                                    ].quantity = val;
                                    setInventory({ ...temp });
                                  }}
                                />
                                <AiOutlineClose
                                  className=" absolute right-0 hover:cursor-pointer"
                                  onClick={() => {
                                    let temp = inventory;
                                    temp[warehouse][iIndex].distribution.splice(
                                      dIndex,
                                      1
                                    );

                                    if (temp[warehouse].length === 0)
                                      delete temp[warehouse];

                                    setInventory({ ...temp });
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div
                        className=" p-2 ml-5 hover:cursor-pointer"
                        style={{
                          backgroundColor:
                            item.quantity > 0
                              ? colors["tb-head"]
                              : colors["tb-row-2"],
                          color: colors["tb-head-text"],
                        }}
                        onClick={async () => {
                          if (item.quantity == 0) return;

                          let _warehouse = '';
                          for(let i = 0; i < total_warehouses.length; i++){
                              if (total_warehouses[i] !== warehouse){
                                  _warehouse = total_warehouses[i];
                                  break;
                              }
                          }
                          let temp = inventory;
                          temp[warehouse][iIndex].distribution.push({
                            to: _warehouse,
                            quantity: 0,
                          });
                          setInventory({ ...temp });
                        }}
                      >
                        <AiOutlinePlus className="text-lg" />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div
          className=" w-full flex flex-col justify-center items-center p-2 mt-5"
          style={{
            backgroundColor: colors["tb-row-1"],
            color: colors["tb-row-text"],
          }}
        >
          {Object.entries(redistributionRecords).map(
            ([warehouse, items], index) => {
              let dotColor = "#ED768F";
              if (redistributionInfo[warehouse].status === "incoming")
                dotColor = "#FDC470";
              if (redistributionInfo[warehouse].status === "finished")
                dotColor = "#5AB770";
              return (
                <div
                  key={`redis-${index}`}
                  className=" w-full flex flex-col justify-center items-start"
                >
                  <div className=" w-full flex justify-between items-center mb-2 mt-5">
                    <p className=" font-bold">{warehouse}</p>
                    <div className=" w-fit flex justify-center items-center gap-2">
                      <div
                        className=" w-2 h-2 rounded-full animate-pulse"
                        style={{
                          backgroundColor: dotColor,
                        }}
                      />
                      <select
                        value={redistributionInfo[warehouse].status}
                        className=" p-1 text-black"
                        onChange={(e) => {
                          let temp = redistributionInfo;
                          temp[warehouse].status = e.target.value;

                          setRedistributionInfo({ ...temp });
                        }}
                      >
                        <option value="hidden">Hidden</option>
                        <option value="not_started">Not Started</option>
                        <option value="incoming">Incoming</option>
                        <option value="finished">Finished</option>
                      </select>
                    </div>
                  </div>
                  <div className="w-full flex justify-start items-center gap-2 mb-2">
                    <p className=" whitespace-nowrap">Redelivery Id</p>
                    <input
                      disabled
                      value={redistributionInfo[warehouse].id}
                      id={`redis-${index}-dId`}
                      className=" w-full border-2 border-gray-400 rounded-md p-1 text-black" />
                  </div>
                  {items.map((item, iIndex) => {
                    let bgColor = colors["tb-row-1-h"];
                    if (iIndex % 2 === 0) bgColor = colors["tb-row-2-h"];
                    return (
                      <div
                        key={`redis-${index}-${iIndex}`}
                        className=" w-full flex justify-start items-center gap-5"
                        style={{
                          backgroundColor: bgColor,
                        }}
                      >
                        <p className=" w-1/4">{item.modelId}</p>
                        <p className=" w-1/4">{item.name}</p>
                        <p className=" w-1/4">{item.style}</p>
                        <p className=" w-1/4">{item.quantity}</p>
                      </div>
                    );
                  })}
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}
