import { create } from 'zustand'

interface WarehouseState {
    total_warehouses: string[];
    setTotalWarehouse: (total_warehouses: string[]) => void;
}

export const useWarehouseStore = create<WarehouseState>()((set) => ({
    total_warehouses: [],
    setTotalWarehouse: (_total_warehouses) =>{
        set(() => ({
            total_warehouses: _total_warehouses
        }))
    }
}))