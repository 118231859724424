import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSwapLeft } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";




export function CustomerProfileView(props: {permission: any}){

    const {name} = useParams();
    const navigate = useNavigate();

    const {account} = useAccountStore();
    const {colors} = useStyleStore();

    const [customer, setCustomer] = useState<any>({});
    const [purchaseHistory, setPurchaseHistory] = useState<any[]>([]);
    const style_code_ref = useRef<any>({});

    const location = useLocation();

    useEffect(() =>{
        if (!location.pathname.startsWith('/dashboard/customer-view'))
            return;
        if (!name)
            return;

            const f = async() =>{
    
                let result = await post('getCustomerHistoryById', {
                    user_id: name,
                    group_id: account.current_group
                })
                if (result){
                    setPurchaseHistory(result.data);
                    setCustomer(result.user);
                    style_code_ref.current = result.style_code_ref;
                }
            }
            f();

    }, [name, location])

    if (!props.permission['o-cust'])
        return (<></>)

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center mb-10">

            <div className="mt-14 w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">

                <div className=" mt-5 w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Customer Info</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/customer-profile');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md">
                    <tbody>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Account</td>
                            <td>{customer.account_type}</td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className=" pl-2">Username</td>
                            <td>{customer.username}</td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Deleted</td>
                            <td>{customer.isDeleted === 1 ? 'true' : 'false'}</td>
                        </tr>
                    </tbody>
                </table>

                <div className=" mt-10 w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Purchase History</p>
                </div>

                {
                    purchaseHistory.map((order: any, oindex) =>{
                        let total = 0;
                        order.products.forEach((product: any) =>{
                            total += parseFloat(product.price);
                        })
                        return (
                            <div key={`order-${oindex}`} className=" w-full flex flex-col justify-start items-start">

                                <div className=" w-full inline-flex flex-col md:flex-row gap-5 p-1"
                                    style={{
                                        backgroundColor: oindex % 2 === 0 ? colors['tb-row-1'] : colors['tb-row-2'],
                                        color: colors['tb-row-text']
                                    }}
                                >
                                    <p>Order Id:</p>
                                    <p className=" font-semibold cursor-pointer hover:underline"
                                        onClick={() =>{
                                            navigate(`/dashboard/order-view/${order.order_id}`);
                                        }}
                                    >
                                        {order.order_id}
                                    </p>
                                    <p>Status:</p>
                                    <p className=" font-mono">{order.status}</p>
                                </div>
                                
                                    {
                                        order.products.map((product: any, pIndex: number) =>{
                                            return (
                                            <div key={`order-${oindex}-${pIndex}`} className=" w-full flex flex-row justify-start items-start p-1 border-b"
                                                style={{
                                                    color: colors['tb-row-text'],
                                                    borderColor: colors['text'],
                                                    backgroundColor: colors['tb-row-2']
                                                }}
                                            >
                                                <p className="w-[30%]">{`${product.model_id}${product.style_code ? `-${product.style_code}` : ''}`}</p>
                                                <p className="w-[50%]">{style_code_ref.current[product.style_code]}</p>
                                                <div className="w-[20%] flex flex-col justify-start items-end"
                                                    style={{
                                                        color: colors['tb-row-text-d']
                                                    }}
                                                >
                                                    <p>$ {(parseFloat(product.price) / parseInt(product.quantity)).toFixed(1)}</p>
                                                    <p>x {product.quantity}</p>
                                                    <hr className=" w-full" style={{borderColor: colors['tb-row-text-d']}} />
                                                    <p className=" font-semibold">$ {parseFloat(product.price).toFixed(1)} HKD</p>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }

                                    <p className=" w-full inline-flex justify-end items-center text-lg font-semibold" style={{color: colors['text']}}>$ {total.toFixed(1)} HKD</p>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}