import { useEffect, useRef, useState } from "react";
import { AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../utils/post";
import { motion } from "framer-motion";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { saveDistributionExcel } from "../utils/report/saveDistributionExcel";

export type RedistributionProps = {
    model_id: string;
    name: string;
    style: string;
    style_code: number;
    expected: number;
    actual: number;
    broken: number;
}

export function ModifyDistribution(props: {permission: any}){

    const {account} = useAccountStore();
    const {colors} = useStyleStore();

    const { name } = useParams();

    const navigate = useNavigate();

    const logStatus = useRef<string>('not_started');
    const [checkedBy, setCheckedBy] = useState<string>('');
    const [checkByTime, setCheckByTime] = useState<string>('');
    const [source, setSource] = useState<{id: string, source: string}>({id: '', source: ''});
    const [distributions, setDistributions] = useState<RedistributionProps[]>([]);

    const [canConfirm, setCanConfirm] = useState<boolean>(false);

    useEffect(() =>{
        if (!name)
            return;

        setDistributions([]);

            const f = async() =>{
                let result = await post('getDistribution', {
                    redisId: name
                });
                let temp: RedistributionProps[] = [];
                Object.values(result.data).forEach((item) =>{
                    let typedItem = item as any;
                    temp.push({
                        model_id: typedItem.model_id,
                        name: typedItem.name,
                        style: result.style_code_ref[typedItem.style_code],
                        style_code: typedItem.style_code,
                        expected: parseInt(typedItem.expected),
                        actual: parseInt(typedItem.actual),
                        broken: parseInt(typedItem.broken),
                    });
                })
                setDistributions([...temp]);

                logStatus.current = result.logStatus;
                setCanConfirm(result.canConfirm);
                setCheckedBy(result.checked_by);
                setCheckByTime(result.time);
                setSource({
                    id: result.logId,
                    source: result.source
                })
            }
            f();
    }, [name])

    if (!props.permission['o-dis'])
        return <></>

    return (
        <div className=" w-full h-fit flex flex-col justify-center items-center">

            <div className=" mt-5 w-full lg:w-2/3 h-fit flex justify-end items-end">
                <motion.div className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                    initial={{scale: 1}}
                    whileHover={{scale: 1.2}}
                    onClick={async() =>{
                        saveDistributionExcel(account.username, name ? name : 'UNDEFINED', distributions, checkedBy, checkByTime);
                    }}
                >
                    Export Excel
                </motion.div>
            </div>

            <div className="mt-5 w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">

                <div className=" w-full flex flex-col justify-center items-end">
                    {
                        !canConfirm ?
                        <p className="font-bold" style={{color: colors['text']}}>Items in this record were modified by other records. You can't edit this record again.</p> :
                        <></>
                    }
                    <p style={{color: colors['tb-row-text-d']}} className=" hover:underline cursor-pointer"
                        onClick={() =>{
                            if (source.source === 'inv'){
                                navigate(`/dashboard/modify-inv-in/${source.id}`);
                            } else if (source.source === 'log'){
                                navigate(`/dashboard/modify-stockin/${source.id}`);
                            }
                        }}
                    >
                        {`${source.id} ${source.source}`}
                    </p>
                </div>

                <div className=" mt-5 w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Confirm Distribution</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/distribution');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full"
                    style={{
                        color: colors['tb-row-text']
                    }}
                >
                    <thead style={{backgroundColor: colors['tb-row-1']}}>
                        <tr>
                            <th>Model Id</th>
                            <th>Name</th>
                            <th>Style</th>
                            <th>Delivered</th>
                            <th>Total</th>
                            <th>Broken</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.values(distributions).map((item, index) =>{
                                let bgColor = colors['tb-row-1-h'];
                                if (index % 2 === 0)
                                    bgColor = colors['tb-row-2-h'];
                                return (
                                    <tr key={`dis-${index}`}
                                        style={{
                                            backgroundColor: bgColor
                                        }}>
                                        <td>
                                            {item.model_id}
                                        </td>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            {item.style}
                                        </td>
                                        <td>
                                           {item.expected}
                                        </td>
                                        <td className=" w-20 pr-2">
                                            <input value={distributions[index].actual} type="number" disabled={logStatus.current === 'finished' ? false : true} className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:opacity-70 disabled:border-purple-300 block p-1 w-full" required
                                                onChange={(e) =>{
                                                    let val = parseInt(e.target.value);
                                                    if (val < 0){
                                                        val = 0;
                                                    }
                                                    if (val > distributions[index].expected){
                                                        val = distributions[index].expected;
                                                    }
                                                    let temp = distributions;
                                                    temp[index].actual = val;
                                                    if (val < temp[index].broken){
                                                        temp[index].broken = val;
                                                    }

                                                    setDistributions([...temp]);
                                                }}/>
                                        </td>
                                        <td className=" w-20 pr-1">
                                            <input value={distributions[index].broken} type="number" disabled={logStatus.current === 'finished' ? false : true}  className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:opacity-70 disabled:border-purple-300  block p-1 w-full" required 
                                                onChange={(e) =>{
                                                    let val = parseInt(e.target.value);
                                                    if (val < 0){
                                                        val = 0;
                                                    }
                                                    if (val > distributions[index].expected){
                                                        val = distributions[index].expected;
                                                    }
                                                    if (val > distributions[index].actual){
                                                        val = distributions[index].actual;
                                                    }
                                                    let temp = distributions;
                                                    temp[index].broken = val;

                                                    setDistributions([...temp]);
                                                }}/>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div className="w-full h-fit flex-col justify-center items-end gap-2"
                    style={{
                        display: canConfirm ? 'flex' : 'none'
                    }}>
                    <motion.div className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer select-none"
                        style={{
                            backgroundColor: logStatus.current === 'finished' ? '#649d7f' : '#E1DEE7',
                            pointerEvents: logStatus.current === 'finished' ? 'auto' : 'none'
                        }}
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async() =>{
                            if (!props.permission['m-dis'])
                                return;

                            setCheckedBy(account.username);
                            setCheckByTime('now');

                            let items: Partial<RedistributionProps>[] = [];
                            for(let i = 0; i < distributions.length; i++){
                                let index = -1;
                                if (items.length > 0)
                                    index = items.findIndex((e) =>
                                        e.model_id === distributions[i].model_id &&
                                        e.style_code === distributions[i].style_code
                                    );
                                if (index > -1){
                                    let actual = distributions[i].actual ? distributions[i].actual : 0;
                                    let broken = distributions[i].broken ? distributions[i].broken : 0;
                                    items[index].actual! += actual;
                                    items[index].broken! += broken;
                                } else {
                                    items.push({
                                        model_id: distributions[i].model_id,
                                        style_code: distributions[i].style_code,
                                        style: distributions[i].style,
                                        expected: distributions[i].expected,
                                        actual: distributions[i].actual ? distributions[i].actual : 0,
                                        broken: distributions[i].broken ? distributions[i].broken : 0
                                    })
                                }
                            }

                            await post('setDistribution', {
                                group_id: account.current_group,
                                redisId: name,
                                user_id: account.user_id,
                                items: JSON.stringify(items),
                            });
                        }}
                    >
                        Confirm
                    </motion.div>
                </div>

                {
                    checkedBy ? 
                    <div className=" w-full flex justify-end items-center gap-1 pt-1"
                        style={{
                            color: colors['text']
                        }}
                    >
                        <p>This record was lastly confirmed by</p>
                        <p className=" font-bold underline">{checkedBy}</p>
                        <p>at</p>
                        <p className=" font-bold underline">{checkByTime}</p>
                        <p>.</p>
                    </div> :
                    <p className="w-full flex justify-end pt-1">Not yet confirmed.</p>
                }

            </div>

        </div>
    )
}