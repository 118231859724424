export async function saveAs(blob: Blob, filename: string){
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
    a.remove();
};

export async function toObjectBlob(url: string) {
  let reponse = await fetch(url, {
    method: 'POST'
  });
  let blob = await reponse.blob();
  return blob;
}