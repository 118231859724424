import { useEffect, useRef, useState } from "react";
import { AiFillCaretRight, AiFillDelete, AiFillPlusSquare, AiOutlineClose, AiOutlineExclamation, AiOutlinePlus, AiOutlineRedo, AiOutlineSearch, AiOutlineSwapLeft, AiOutlineWarning } from "react-icons/ai";
import { post } from "../utils/post";
import { motion } from "framer-motion";
import { groupBy } from "../utils/groupBy";
import { getCustomAmount } from "./AddStockIn";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { saveStockInExcel } from "../utils/report/saveStockInExcel";
import { useWarehouseStore } from "../utils/zustand/useWarehouseStore";
import { FaRegCheckCircle } from "react-icons/fa";

export type OptionProps = {
    relative_title: string;
    possible_title: string[];
    title: string;
    details: {name: string, checked: boolean}[];
};

export type CustomAmountProps = {
    conditions: {option: string, name: string}[];
    details: {amount: number, warehouse: string}[];
};

// mId: model Id, n: name, c: conditions, w: warehouse, q: quantity, o: options
export type DistributionProps = {
    mId: string;
    n: string;
    c: string;
    w: string;
    q: string;
    o: string;
};

export function ModifyStockIn(props: {permission: any}){

    const navigate = useNavigate();

    const {account} = useAccountStore();
    const {colors} = useStyleStore();
    const {total_warehouses} = useWarehouseStore();

    const { name } = useParams();

    const [options, setOptions] = useState<OptionProps[]>([]);

    const [isItemApproved, setIsItemApproved] = useState<boolean>(false);

    const [amountType, setAmountType] = useState<string>('total');
    const [customAmounts, setCustomAmounts] = useState<CustomAmountProps[]>([]);

    const logIdInputField = useRef<HTMLInputElement | null>(null);
    const nameInputField = useRef<HTMLInputElement | null>(null);
    const modelIdInputField = useRef<HTMLInputElement | null>(null);

    const [logRecords, setLogRecords] = useState<DistributionProps[]>([]);
    const [redistributionRecords, setRedistributionRecords] = useState<Record<string, DistributionProps[]>>({});
    const [redistributionInfo, setRedistributionInfo] = useState<Record<string, {id: string, status: string}>>({});

    const [canSave, setCanSave] = useState<boolean>(false);
    const [updated_by, set_updated_by] = useState<string>('');

    useEffect(() =>{
        let groupedRecords = groupBy(logRecords, (e) => e.w)
        setRedistributionRecords(groupedRecords);

        let temp = redistributionInfo;
        let allWarehouses = Object.keys(groupedRecords);
        allWarehouses.forEach((warehouse) =>{
            if (!temp[warehouse]){
                temp[warehouse] = {
                    id: '',
                    status: 'not_started'
                }
            }
        })
        Object.keys(temp).forEach((warehouse) =>{
            if (!allWarehouses.includes(warehouse)){
                delete temp[warehouse];
            }
        })
        setRedistributionInfo({...temp});

    }, [logRecords])

    useEffect(() =>{
        if (!name)
            return;

        setAmountType('total');
        setCustomAmounts([]);
        setRedistributionRecords({});
        setRedistributionInfo({});
        setLogRecords([]);

        logIdInputField.current!.value = name;

        const f = async() =>{
            let result = await post('getLog', {
                logId: name
            })

            if (result.status === 0){
                navigate('/dashboard/add-stockin');
                alert('Stock-In record has been deleted.');
                return;
            }

            setCanSave(result.canSave);
            set_updated_by(result.updated_by);

            let temp: DistributionProps[] = [];
            if (result.data.length > 0)
            Object.entries(result.data).forEach(([key, item]) =>{
                let typedItem = item as any;
                temp.push({
                    mId: typedItem.model_id,
                    n: typedItem.name,
                    c: result.style_code_ref[typedItem.style_code],
                    w: typedItem.warehouse,
                    q: typedItem.quantity,
                    o: typedItem.all_styles
                })
            });
            setLogRecords([...temp]);

            let temp2 = redistributionInfo;
            temp2 = {};
            Object.entries(result.redistribute).forEach(([key, item]) =>{
                let typedItem = item as any;
                temp2[typedItem.warehouse] = {
                    id: typedItem.redis_id,
                    status: typedItem.status
                }
            })
            setRedistributionInfo({...temp2});
        };
        f();

    }, [name])

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center">

            <div className=" mt-5 w-full lg:w-2/3 h-fit flex justify-end items-end">
                <motion.div className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                    initial={{scale: 1}}
                    whileHover={{scale: 1.2}}
                    onClick={async() =>{
                        saveStockInExcel(account.username, name ? name : 'UNDEFINED', redistributionRecords, redistributionInfo)
                    }}
                >
                    Export Excel
                </motion.div>
            </div>

            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                {
                    !canSave ?
                    <div className="w-full flex justify-end font-bold" style={{color: colors['text']}}>Items in this record were modified by other records. You can't edit this record again.</div> :
                    <></>
                }

                <div className="w-full h-fit flex flex-row justify-end items-center text-white gap-5">
                <motion.div className=" w-fit h-fit bg-[#649d7f] justify-center items-center p-2 mt-2 hover:cursor-pointer"
                    style={{
                        display: canSave ? 'flex' : 'none'
                    }}
                    initial={{scale: 1}}
                    whileHover={{scale: 1.2}}
                    onClick={async() =>{
                        if (!props.permission['m-stockin'])
                            return;
                        if (logRecords.length === 0){
                            alert('Stock-In record cannot be empty.');
                            return;
                        }

                        let _logRecords: Record<string, {status: string, items: DistributionProps[]}> = {};
                        Object.entries(groupBy(logRecords, (e) => e.w)).forEach(([warehouse, items]) =>{

                                _logRecords[warehouse] = {
                                status: redistributionInfo[warehouse].status,
                                items: items
                            }
                        })

                        let result = await post('addLog', {
                            group_id: account.current_group,
                            log_id: name,
                            user_id: account.user_id,
                            items: JSON.stringify(_logRecords),
                            action: 'update'
                        })
                        if (result.status === 0){
                            alert('Delivery Id already exist.')
                            return;
                        } else if (result.status === 2){
                            alert('Re-Delivery Id already exist.')
                            return;
                        }
                        navigate(-1);
                    }}
                >
                    Save
                </motion.div>
                </div>

                <div className="w-full flex justify-end items-center pt-2">
                    <p>{`Last modified by ${updated_by}.`}</p>
                </div>
            </div>
            

            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">

                <div className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Modify Stock-in Record</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/stockin');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center"
                    style={{
                        color: colors['tb-text']
                    }}
                >
                    <tbody>
                        <tr 
                            style={{
                                backgroundColor: colors['tb-row-1']
                            }}
                        >
                            <td className=" pl-2">Delivery Id</td>
                            <td className=" w-full pl-2"> 
                                <div className=" flex justify-center items-center gap-2 mr-2">
                                    <input ref={logIdInputField} disabled type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            
            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mb-5">

                <div className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Insert Item</p>
                    <div className=" w-fit flex justify-center items-center gap-5">
                        <AiFillPlusSquare className=" text-2xl hover:cursor-pointer" 
                            onClick={() =>{
                                if (!isItemApproved){
                                    alert('Item is not approved.');
                                    return;
                                }
                                if (customAmounts.length === 0){
                                    alert('Item amount not specified');
                                    return;
                                }
                                for(let i = 0; i < logRecords.length; i++){
                                    let log_records = logRecords[i];
                                    if (log_records.mId === modelIdInputField.current!.value){
                                        if (log_records.n !== nameInputField.current!.value){
                                            alert('Duplicated name with same model id.');
                                            return;
                                        }
                                    }
                                }
                                let quantity = getCustomAmount(amountType, customAmounts);

                                if (quantity){

                                    let optionStr: string = '';
                                    for(let i = 0; i < options.length; i++){
                                        optionStr = optionStr + options[i].title + ',';
                                    }
                                    optionStr = optionStr.substring(0, optionStr.length - 1);

                                    let temp: DistributionProps[] = logRecords;
                                    for(let a = 0; a < quantity.length; a++){

                                        for(let k = 0; k < quantity[a].d.length; k++){
                                            let index = logRecords.findIndex((e) =>
                                                e.mId === modelIdInputField.current!.value &&
                                                e.c === quantity![a].c &&
                                                e.w === quantity![a].d[k].w
                                            );
                                            if (index > -1){
                                                let val = parseInt(temp[index].q);
                                                temp[index].q = (val + quantity[a].d[k].a).toString();
                                            } else {
                                                temp.push({
                                                    mId: modelIdInputField.current!.value,
                                                    n: nameInputField.current!.value,
                                                    c: quantity[a].c,
                                                    w: quantity[a].d[k].w,
                                                    q: quantity[a].d[k].a.toString(),
                                                    o: optionStr
                                                })
                                            }
                                        }
                                    }
                                    setLogRecords([...temp]);
                                }

                        }}/>
                        <AiFillDelete className=" text-2xl hover:cursor-pointer" 
                            onClick={() =>{
                                nameInputField.current!.value = '';
                                modelIdInputField.current!.value = '';
                                setOptions([]);
                                setCustomAmounts([]);
                            }}/>
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center">
                    <tbody style={{color: colors['tb-row-text']}}>
                        <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className=" pl-2">Name</td>
                            <td className=" w-full pl-2"> 
                                <input ref={nameInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required
                                    onChange={(e) =>{
                                        let valid = /^[A-Za-z0-9-+'"()#!?$&_ ]*$/.test(e.target.value);
                                        if (valid)
                                            nameInputField.current!.value = e.target.value;
                                        else {
                                            nameInputField.current!.value = '';
                                        }
                                        setIsItemApproved(false);
                                    }}/>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: colors['tb-row-2']}}>
                            <td className=" pl-2">Model Id</td>
                            <td className=" w-full"> 
                                <div className=" w-full p-2 flex justify-start items-center gap-2">
                                    <input ref={modelIdInputField} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        onChange={(e) =>{
                                            let valid = /^[A-Za-z0-9-]*$/.test(e.target.value);
                                            if (valid)
                                                modelIdInputField.current!.value = e.target.value;
                                            else {
                                                modelIdInputField.current!.value = '';
                                            }
                                            setIsItemApproved(false);
                                        }}
                                    />
                                    <div className=" p-2 hover:cursor-pointer"
                                        style={{
                                            backgroundColor: colors['tb-head'],
                                            color: colors['tb-head-text']
                                        }}
                                        onClick={async() =>{
                                            if (nameInputField.current!.value === '' && modelIdInputField.current!.value === '')
                                                return;

                                            let result = await post('generateModelDetail', {
                                                group_id: account.current_group,
                                                name: nameInputField.current!.value,
                                                model_id: modelIdInputField.current!.value
                                            })
                                            if (result.status === 0){
                                                setIsItemApproved(false);
                                                alert('Both name and model id cannot be null.');
                                                return;
                                            }
                                            if (result.status === 2){
                                                setIsItemApproved(false);
                                                alert('Model id already exists.');
                                                return;
                                            }

                                            nameInputField.current!.value = result.name;
                                            modelIdInputField.current!.value = result.model_id;
                                            let temp: OptionProps[] = [];
                                            Object.entries(result.styles).forEach(([title, options]) =>{
                                                let typedOptions: string[] = options as string[];
                                                let details: {name: string, checked: boolean}[] = [];
                                                for(let i = 0; i < typedOptions.length; i++){
                                                    details.push({
                                                        name: typedOptions[i],
                                                        checked: false
                                                    });
                                                }
                                                temp.push({
                                                    relative_title: title,
                                                    possible_title: [title],
                                                    title: title,
                                                    details: details
                                                });
                                            })
                                            setIsItemApproved(true);
                                            setOptions([...temp]);
                                        }}>
                                            {
                                                isItemApproved ? <FaRegCheckCircle className="text-lg" /> : <AiOutlineSearch className="text-lg"  />
                                            }
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="w-full"
                    style={{
                        pointerEvents: isItemApproved ? 'auto' : 'none',
                        userSelect: isItemApproved ? 'text' : 'none',
                        opacity: isItemApproved ? '1' : '0.4'
                    }}
                >
                    <tbody>
                    <tr style={{backgroundColor: colors['tb-row-1']}}>
                            <td className="pl-2">Options</td>
                            <td>
                                <div className=" w-full h-fit flex flex-col justify-center items-center pr-2">
                                {
                                    options.map((item, index) =>{
                                        return (

                                                <div key={`option-item-${index}`} className=" w-full h-fit flex flex-row justify-between items-start gap-10">
            
                                                    <div className=" w-1/3 flex flex-col justify-start items-start">
                                                        <div className="w-full flex flex-row justify-start items-center gap-2">
                                                            <input id={`option-input-item-${index}`} type="text" defaultValue={item.title} className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                                                            <div className=" p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                                                                style={{
                                                                    backgroundColor: colors['tb-head'],
                                                                    color: colors['tb-head-text']
                                                                }}
                                                                onClick={async() =>{
                                                                    let item = document.getElementById(`option-input-item-${index}`);
                                                                    if (!item)
                                                                        return;
                                                                    let typedItem = item as HTMLInputElement;

                                                                    if (typedItem.value === ''){
                                                                        alert('Option name is empty.');
                                                                        return;
                                                                    }
                                                                    

                                                                    for(let i = 0; i < options.length; i++){
                                                                        if (options[i].title === typedItem.value){
                                                                            alert('Option name already exists.');
                                                                            typedItem.value = '';
                                                                            return;
                                                                        }
                                                                    }

                                                                    let temp = options;
                                                                    temp[index].possible_title = [];

                                                                    let result2 = await post('getRelativeStockCollections', {
                                                                        group_id: account.current_group,
                                                                        collection: typedItem.value
                                                                    });
                                                                    Object.values(result2.data).forEach((collection) =>{
                                                                        let typed_collection = collection as string;
                                                                        temp[index].possible_title.push(typed_collection);
                                                                    })
                                                                    setOptions([...temp]);
                                                                }}>
                                                                <AiOutlineSearch />
                                                            </div>
                                                            <div className=" p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                                                                style={{
                                                                    backgroundColor: colors['tb-head'],
                                                                    color: colors['tb-head-text']
                                                                }}
                                                                onClick={async() =>{
                                                                    let temp = options;

                                                                    let temp2 = customAmounts;
                                                                    for(let i = 0; i < temp2.length; i++){
                                                                        for(let k = 0; k < temp2[i].conditions.length; k++){
                                                                            if (temp2[i].conditions[k].option === temp[index].title){
                                                                                temp2[i].conditions[k].option = '';
                                                                                temp2[i].conditions[k].name = '';
                                                                            }
                                                                        }
                                                                    }

                                                                    temp.splice(index, 1);
                                                                    setOptions([...temp]);

                                                                    setCustomAmounts([...temp2]);
                                                                }}>
                                                                <AiOutlineClose />
                                                            </div>
                                                        </div>
                                                        <div className=" w-full flex flex-col justify-start items-start">
                                                            <div className=" w-full flex justify-start items-center gap-2 font-semibold mb-2"
                                                                style={{
                                                                    backgroundColor: colors['tb-head'],
                                                                    color: colors['tb-head-text']
                                                                }}
                                                            >
                                                                <AiFillCaretRight />
                                                                <p>Collections</p>
                                                            </div>
                                                            {
                                                                item.possible_title.map((possible_collection, pIndex) =>{
                                                                    let bgColor = colors['tb-row-1-h'];
                                                                    if (pIndex % 2 === 0)
                                                                        bgColor = colors['tb-row-2-h'];
                                                                    return (
                                                                        <div key={`option-item-${index}-${pIndex}`} style={{backgroundColor: bgColor}} className="pl-1 pr-1 w-full select-none cursor-pointer hover:opacity-70 hover:underline"
                                                                            onClick={async() =>{
                                                                                if (possible_collection === item.title)
                                                                                    return;
                                                                                
                                                                                let temp = options;
                                                                                temp[index].details = [];

                                                                                let result = await post('getStockOptions', {
                                                                                    group_id: account.current_group,
                                                                                    title: possible_collection
                                                                                });
            
                                                                                temp[index].title = possible_collection;
                                                                                for(let i = 0; i < result.data.length; i++){
                                                                                    temp[index].details.push({
                                                                                        name: result.data[i],
                                                                                        checked: false
                                                                                    })
                                                                                }
            
                                                                                setOptions([...temp]);
                                                                            }}
                                                                        >
                                                                            <div className=" inline-flex justify-center items-center gap-1">
                                                                                <p>{possible_collection}</p>
                                                                                <p>{possible_collection === item.title ? '>' : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
            
                                                    <div className=" w-2/3 flex flex-col justify-between items-start">
            
                                                        <div className="w-full flex justify-between items-center gap-2">
                                                            <input id={`option-field-item-${index}`} type="text" className="mb-2 mt-2 border-[2px] border-gray-300 bg-[#f1eff3] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                                                            <div className=" p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                                                                style={{
                                                                    backgroundColor: colors['tb-head'],
                                                                    color: colors['tb-head-text']
                                                                }}
                                                                onClick={async() =>{
                                                                    let item = document.getElementById(`option-field-item-${index}`);
                                                                    if (!item)
                                                                        return;
                                                                    let typedItem = item as HTMLInputElement;
    
                                                                    if (options[index].title === ''){
                                                                        alert('Option name is empty.')
                                                                        return;
                                                                    }

                                                                    if (typedItem.value === ''){
                                                                        alert('Field name is empty.')
                                                                        return;
                                                                    }

                                                                    for(let i = 0; i < options[index].details.length; i++){
                                                                        if (options[index].details[i].name === typedItem.value){
                                                                            typedItem.value = '';
                                                                            return;
                                                                        }
                                                                    }

                                                                    let temp = options;
                                                                    temp[index].details.push({name: typedItem.value, checked: false});
    
                                                                    let result = await post('addStockOption', {
                                                                        group_id: account.current_group,
                                                                        title: temp[index].title,
                                                                        name: typedItem.value
                                                                    })
                                                                    if (result.status === 0){
                                                                        alert('Option already exists.');
                                                                        return;
                                                                    }
                                                                    setOptions([...temp]);

                                                                    typedItem.value = '';
                                                                }}>
                                                                <AiOutlinePlus />
                                                            </div>
                                                        </div>

                                                        <div className=" w-full flex justify-start items-center gap-2 font-semibold mb-2"
                                                            style={{
                                                                backgroundColor: colors['tb-head'],
                                                                color: colors['tb-head-text']
                                                            }}
                                                        >
                                                            <AiFillCaretRight />
                                                            <p>{item.title}</p>
                                                        </div>

                                                        {
                                                            item.details.length > 0 ?
                                                                item.details.map((detail, dIndex) =>{
                                                                    let bgColor = colors['tb-row-1-h'];
                                                                    if (dIndex % 2 === 0)
                                                                        bgColor = colors['tb-row-2-h'];
                                                                    return (
                                                                        <div key={`option-detail-${dIndex}`} className=" w-full flex justify-between items-center gap-2"
                                                                            style={{
                                                                                background: bgColor
                                                                            }}>

                                                                            <div className=" flex justify-start items-center gap-2">
                                                                                <input type="checkbox" checked={detail.checked} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                                                    onChange={(e) =>{
                                                                                        let temp = options;
                                                                                        temp[index].details[dIndex].checked = e.target.checked;

                                                                                        setOptions([...temp]);
                                                                                    }}/>
                                                                                <p>{detail.name}</p>
                                                                            </div>

                                                                            <div className=" text-sm hover:cursor-pointer hover:text-gray-600"
                                                                                onClick={async() =>{
                                                                                    let temp = options;

                                                                                    await post('deleteStockOption', {
                                                                                        group_id: account.current_group,
                                                                                        title: temp[index].title,
                                                                                        name: temp[index].details[dIndex].name
                                                                                    })

                                                                                    temp[index].details.splice(dIndex, 1);
                                                                                    setOptions([...options]);
                                                                                }}>
                                                                                <AiOutlineClose />
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                }) :
                                                                item.title === '' ?
                                                                <></> :
                                                                <div className=" w-full h-fit flex flex-col justify-center items-center bg-[#f6f8c3] p-2 rounded-md mb-2">
                                                                    <AiOutlineWarning />
                                                                    <p>Nothing to select in this option.</p>
                                                                    <p>Try to add fields with the input bar above first.</p>
                                                                </div>
                                                        }                                    

                                                    </div>
            
                                                </div>
                                        )
                                    })
                                }

                                    <div className="w-full flex justify-end items-center mt-5">
                                        <div className=" p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                                            style={{
                                                backgroundColor: modelIdInputField.current?.value === '' ? colors['tb-row-text-d'] : colors['tb-head'],
                                                color: colors['tb-head-text']
                                            }}
                                            onClick={() =>{
                                                if (modelIdInputField.current!.value === '')
                                                    return;
                                                let temp: OptionProps = {
                                                    relative_title: '',
                                                    possible_title: [],
                                                    title: '',
                                                    details: []
                                                }
                                                setOptions([...options, temp])
                                            }}>
                                            <AiOutlinePlus />
                                        </div>
                                    </div>

                                </div>
                            </td>
                        </tr>
                        <tr style={{ backgroundColor: colors['tb-row-2']}}> 
                            <td>
                                <select className=" m-2.5 text-black" defaultValue={'total'}
                                    onChange={(e) =>{
                                        let _warehouse = '';
                                        if (total_warehouses.length > 0){
                                            _warehouse = total_warehouses[0];
                                        }

                                        setAmountType(e.target.value)
                                        setCustomAmounts([{
                                            conditions: [],
                                            details: [{amount: 0, warehouse: _warehouse}]
                                        }]);
                                    }}>
                                    <option value='total'>Total Quantity</option>
                                    <option value='custom'>Custom Quantity</option>
                                </select>
                            </td>   
                            <td>
                                {
                                    amountType === 'total' ?
                                    <div className=" w-full h-fit flex flex-col justify-center items-center p-2 gap-5">
                                        {
                                            options.length === 0 ? <></> :
                                            <div className=" w-full h-fit flex flex-col justify-center items-center p-2 rounded-md"
                                                style={{
                                                    backgroundColor: colors['tb-head'],
                                                    color: colors['tb-head-text']
                                                }}
                                            >
                                                <AiOutlineExclamation />
                                                <p>Options are disabled using Total Amount.</p>
                                            </div>
                                        }

                                        {
                                            customAmounts.map((item, index) =>{
                                                return (
                                                    <div key={`total-amount-item-${index}`} className=" w-full flex flex-col justify-center items-center">
                                                        <div className=" relative p-1 w-full flex justify-start items-center gap-2"
                                                            style={{
                                                                backgroundColor: colors['tb-head'],
                                                                color: colors['tb-head-text']
                                                            }}
                                                        >
                                                            <AiFillCaretRight />
                                                            <p>Location {index + 1}</p>
                                                            <AiOutlineClose className=" absolute right-0 text-base m-1 hover:cursor-pointer" 
                                                                onClick={() =>{
                                                                    let temp = customAmounts;
                                                                    temp.splice(index, 1);
                                                                    setCustomAmounts([...temp]);
                                                                }}
                                                            />
                                                        </div>
                                                        <select value={customAmounts[index].details[0].warehouse} className="w-full p-2.5 mb-2 mt-2 text-black"
                                                            onChange={(e) =>{
                                                                let temp = customAmounts;
                                                                temp[index].details[0].warehouse = e.target.value;

                                                                setCustomAmounts([...temp]);
                                                            }}>
                                                            {
                                                                total_warehouses.map((option, oIndex) =>{
                                                                    return (
                                                                        <option key={`total-amount-${index}-option-${oIndex}`} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <input value={customAmounts[index].details[0].amount} type="number" className="border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                            onChange={(e) =>{
                                                                let temp = customAmounts;
                                                                temp[index].details[0].amount = parseFloat(e.target.value);

                                                                setCustomAmounts([...temp]);
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }

                                        <div className="w-full flex justify-end items-center">
                                            <div className=" p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                                                style={{
                                                    backgroundColor: modelIdInputField.current?.value === '' ? colors['tb-row-text-d'] : colors['tb-head'],
                                                    color: colors['tb-head-text']
                                                }}
                                                onClick={() =>{
                                                    if (modelIdInputField.current!.value === '')
                                                        return;
                                                    let _warehouse = '';
                                                    if (total_warehouses.length > 0){
                                                        _warehouse = total_warehouses[0];
                                                    }
                                                    let temp: CustomAmountProps = {
                                                        conditions: [],
                                                        details: [{amount: 0, warehouse: _warehouse}]
                                                    }

                                                    setCustomAmounts([...customAmounts, temp]);
                                                }}>
                                                <AiOutlinePlus />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    options.length > 0 ?
                                    <div className="w-full h-fit flex flex-col justify-center items-center pr-2">

                                        <div className="w-full flex flex-col justify-start items-center gap-2 mb-2">
                                        {
                                            customAmounts.map((item, index) =>{
                                                return (
                                                    <div key={`custom-amount-item-${index}`} className="relative w-full flex flex-col justify-center items-center gap-1">

                                                        <div className="w-full flex justify-start items-center gap-2"
                                                            style={{
                                                                backgroundColor: colors['tb-head'],
                                                                color: colors['tb-head-text']
                                                            }}
                                                        >
                                                            <AiFillCaretRight />
                                                            <p>Style {index + 1}</p>
                                                        </div>

                                                        <div className=" w-full flex flex-row justify-start items-center gap-2"
                                                            style={{
                                                                color: colors['tb-row-1-text']
                                                            }}
                                                        >

                                                            <p>For</p>

                                                            {
                                                                item.conditions.map((option, cIndex) =>{
                                                                    let isEmpty = true;

                                                                    return (
                                                                        <div key={`custom-amount-select-${index}-${cIndex}`} className=" w-fit flex relative justify-center items-center gap-1">
                                                                            <select value={customAmounts[index].conditions[cIndex].option}
                                                                                style={{
                                                                                    backgroundColor: colors['tb-head'],
                                                                                    color: colors['tb-head-text']
                                                                                }}
                                                                                onChange={(e) =>{
                                                                                    let temp = customAmounts;

                                                                                    temp[index].conditions[cIndex].option = e.target.value;
                                                                                    setCustomAmounts([...temp]);
                                                                                }}>
                                                                                <option value=''></option>
                                                                                {
                                                                                    options.map((option, oIndex) =>{
                                                                                        return (
                                                                                            <option value={option.title}>{option.title}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            <p>=</p>
                                                                                {
                                                                                    item.conditions[cIndex].option === '' || options.findIndex((e) => e.title === item.conditions[cIndex].option) === -1 ? <p className=" italic">NULL</p> :
                                                                                    <select value={customAmounts[index].conditions[cIndex].name}
                                                                                        style={{
                                                                                            backgroundColor: colors['tb-head'],
                                                                                            color: colors['tb-head-text']
                                                                                        }}
                                                                                        onChange={(e) =>{
                                                                                            let temp = customAmounts;

                                                                                            temp[index].conditions[cIndex].name = e.target.value;
                                                                                            setCustomAmounts([...temp]);
                                                                                        }}>
                                                                                        <option value=''></option>
                                                                                        {
                                                                                        
                                                                                            options[options.findIndex((e) => e.title === item.conditions[cIndex].option)].details.map((name, nIndex) =>{
                                                                                                if (name.checked === true){
                                                                                                    isEmpty = false;
                                                                                                    return (
                                                                                                        <option value={name.name}>{name.name}</option>
                                                                                                    )
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        {isEmpty ? <option value=''>NULL</option> :  <></>}
                                                                                    </select>
                                                                                }
                                                                            {
                                                                                cIndex < item.conditions.length - 1 ? <p>&</p> : <></>
                                                                            }

                                                                            <AiOutlineClose className=" absolute -left-1 -top-1 text-base p-1 bg-[#e17497] rounded-full opacity-0 hover:opacity-100 hover:cursor-pointer" 
                                                                                onClick={() =>{
                                                                                    let temp = customAmounts;
                                                                                    temp[index].conditions.splice(cIndex, 1);
                                            
                                                                                    setCustomAmounts([...temp]);
                                                                                }}
                                                                            />

                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            <div className=" p-2 w-5 h-5 flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer"
                                                                style={{
                                                                    backgroundColor: colors['tb-head'],
                                                                    color: colors['tb-head-text']
                                                                }}
                                                                onClick={() =>{
                                                                    let temp = customAmounts;
                                                                    temp[index].conditions.push({option: '', name: ''});

                                                                    setCustomAmounts([...temp]);
                                                                }}>
                                                                <p>&</p>
                                                            </div>
                                                            <p>,</p>
                                                        </div>

                                                        <div className=" w-full h-fit pl-3">
                                                        {
                                                            item.details.map((detail, dIndex) =>{
                                                                return (
                                                                    <div key={`custom-amount-${index}-${dIndex}`} className=" p-1 relative w-full flex flex-col justify-start items-start gap-1 mb-5"
                                                                        style={{backgroundColor: colors['tb-row-2-h'], color: colors['tb-row-text']}}
                                                                    >
                                                                        
                                                                        <AiOutlineClose className=" absolute right-0 top-0 hover:cursor-pointer" 
                                                                        onClick={() =>{
                                                                            let temp = customAmounts;
                                                                            temp[index].details.splice(dIndex, 1);

                                                                            setCustomAmounts([...temp]);
                                                                        }}/>
                                                                        <p>Warehouse:</p>
                                                                        <select value={customAmounts[index].details[dIndex].warehouse} className="w-full p-2 text-black"
                                                                            onChange={(e) =>{
                                                                                let temp = customAmounts;
                                                                                temp[index].details[dIndex].warehouse = e.target.value;
                                                                                setCustomAmounts([...temp]);
                                                                            }}>
                                                                            {
                                                                                total_warehouses.map((option, oIndex) =>{
                                                                                    return (
                                                                                        <option key={`custom-amount-${index}-${dIndex}-option-${oIndex}`} value={option}>
                                                                                            {option}
                                                                                        </option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>

                                                                        <p>Amount:</p>
                                                                        <input type="number" value={customAmounts[index].details[dIndex].amount} className="border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-1" 
                                                                            onChange={(e) =>{
                                                                                let temp = customAmounts;
                                                                                temp[index].details[dIndex].amount = parseFloat(e.target.value);
            
                                                                                setCustomAmounts([...temp]);
                                                                            }}/>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </div>

                                                        <div className=" w-full flex justify-end items-center">
                                                            <div className=" mr-10 p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                                                                style={{
                                                                    backgroundColor: colors['tb-row-2-h'],
                                                                    color: colors['tb-row-text']
                                                                }}
                                                                onClick={() =>{

                                                                    let temp = customAmounts;
                                                                    let _warehouse = '';
                                                                    if (total_warehouses.length > 0){
                                                                        _warehouse = total_warehouses[0];
                                                                    }
                                                                    temp[index].details.push({amount: 0, warehouse: _warehouse});

                                                                    setCustomAmounts([...temp]);
                                                                }}>
                                                                <AiOutlinePlus />
                                                            </div>
                                                        </div>

                                                        <AiOutlineClose className=" absolute -right-1 -top-1 text-lg m-2 hover:cursor-pointer" 
                                                            style={{
                                                                color: colors['tb-head-text']
                                                            }}
                                                            onClick={() =>{
                                                                let temp = customAmounts;
                                                                temp.splice(index, 1);
                        
                                                                setCustomAmounts([...temp]);
                                                            }}
                                                        />

                                                    </div>
                                                )
                                            })
                                        }
                                        </div>

                                        <div className="w-full flex justify-end items-center">
                                            <div className=" p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                                                style={{
                                                    backgroundColor: colors['tb-head'],
                                                    color: colors['tb-head-text']
                                                }}
                                                onClick={() =>{
                                                    let _warehouse = '';
                                                    if (total_warehouses.length > 0){
                                                        _warehouse = total_warehouses[0];
                                                    }

                                                    let temp: CustomAmountProps = {
                                                        conditions: [{option: '', name: ''}],
                                                        details: [{amount: 0, warehouse: _warehouse}]
                                                    }

                                                    setCustomAmounts([...customAmounts, temp]);

                                                }}>
                                                <AiOutlinePlus />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className=" w-full h-fit flex flex-col justify-center items-center p-2 bg-[#f6f8c3] rounded-md">
                                        <AiOutlineWarning />
                                        <p>No option added.</p>
                                        <p>Try to add options before setting custom amount.</p>
                                    </div>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5 p-2"
                style={{
                    backgroundColor: colors['tb-row-1'],
                    color: colors['tb-row-text']
                }}
            >
                <p className=" w-full inline-flex justify-start font-semibold">Summary</p>
                <table className="table-auto w-full whitespace-nowrap mb-5">
                    <thead className=" text-start font-bold">
                        <tr>
                            <td>
                                Model Id
                            </td>
                            <td>
                                Name
                            </td>
                            <td>
                                Style
                            </td>
                            <td>
                                Warehouse
                            </td>
                            <td>
                                Quantity
                            </td>
                        </tr>
                    </thead>
                    <tbody className=" text-start">
                        {
                            logRecords.map((item, index) =>{
                                let bgColor = colors['tb-row-1-h'];
                                if (index % 2 === 0)
                                    bgColor = colors['tb-row-2-h'];
                                return (
                                    <tr key={`log-record-${index}`} className=" relative"
                                        style={{
                                            backgroundColor: bgColor
                                        }}>
                                        <td>{item.mId}</td>
                                        <td>{item.n}</td>
                                        <td>{item.c}</td>
                                        <td>{item.w}</td>
                                        <td>{item.q}</td>

                                        <td className=" absolute right-0 hover:cursor-pointer">
                                            <div
                                                onClick={() =>{
                                                    let temp = logRecords;
                                                    temp.splice(index, 1);

                                                    setLogRecords([...temp]);
                                                }}>
                                                <AiOutlineClose />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>


            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5 p-2"
                style={{
                    backgroundColor: colors['tb-row-1'],
                    color: colors['tb-row-text']
                }}
            >
                <p className=" w-full inline-flex justify-start font-semibold">Group By Warehouse</p>
                {
                    Object.entries(redistributionRecords).map(([warehouse, items], index) =>{
                        let dotColor = '#ED768F';
                        if (redistributionInfo[warehouse].status === 'incoming')
                            dotColor = '#FDC470';
                        if (redistributionInfo[warehouse].status === 'finished')
                            dotColor = '#5AB770';
                        return (
                            <div key={`redis-${index}`} className=" w-full flex flex-col justify-center items-start">
                                
                                <div className=" w-full flex justify-between items-center mt-5">
                                    <p className=" font-bold">{warehouse}</p>
                                    <div className=" w-fit flex justify-center items-center gap-2">
                                        <div className=" w-2 h-2 rounded-full animate-pulse"
                                            style={{
                                                backgroundColor: dotColor
                                            }}/>
                                        <select value={redistributionInfo[warehouse].status} className=" p-1 text-black"
                                            onChange={(e) =>{
                                                let temp = redistributionInfo;
                                                temp[warehouse].status = e.target.value;

                                                setRedistributionInfo({...temp});
                                            }}>
                                            <option value="hidden">Hidden</option>
                                            <option value="not_started">Not Started</option>
                                            <option value="incoming">Incoming</option>
                                            <option value="finished">Finished</option>
                                        </select>
                                    </div>
                                </div>

                                <table className="w-full">
                                    <thead className="text-start font-bold">
                                        <tr>
                                            <td>Model Id</td>
                                            <td>Name</td>
                                            <td>Style</td>
                                            <td>Quantity</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        items.map((item, iIndex) =>{
                                            let bgColor = colors['tb-row-1-h'];
                                            if (iIndex % 2 === 0)
                                                bgColor = colors['tb-row-2-h'];
                                            return (
                                                <tr key={`redis-${index}-${iIndex}`}
                                                    style={{
                                                        backgroundColor: bgColor
                                                    }}
                                                >
                                                    <td>{item.mId}</td>
                                                    <td>{item.n}</td>
                                                    <td>{item.c}</td>
                                                    <td>{item.q}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}