import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { post } from "../utils/post";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AiOutlineSwapLeft,
} from "react-icons/ai";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { saveQuotationExcel } from "../utils/report/saveQuotationExcel";

export function QuotationView(props: { permission: any }) {
  const { name } = useParams();
  const navigate = useNavigate();

  const { account } = useAccountStore();
  const { colors } = useStyleStore();

  const firstNameInputField = useRef<HTMLInputElement | null>(null);
  const lastNameInputField = useRef<HTMLInputElement | null>(null);
  const emailInputField = useRef<HTMLInputElement | null>(null);
  const phoneInputField = useRef<HTMLInputElement | null>(null);
  const countrtInputField = useRef<HTMLInputElement | null>(null);
  const address1InputField = useRef<HTMLInputElement | null>(null);
  const address2InputField = useRef<HTMLInputElement | null>(null);
  const cityInputField = useRef<HTMLInputElement | null>(null);
  const regionInputField = useRef<HTMLInputElement | null>(null);
  const postalCodeInputField = useRef<HTMLInputElement | null>(null);
  const shippingMethodInputField = useRef<HTMLInputElement | null>(null);
  const shippingOptionInputField = useRef<HTMLInputElement | null>(null);
  const remarksInputField = useRef<HTMLTextAreaElement | null>(null);
  const receipt = useRef<string>("");
  const created_at = useRef<string>('');

  const [converted, setConverted] = useState<boolean>(false);
  const [saleId, setSaleId] = useState<string>('');

  //model_id,style_code -> other
  const [products, setProducts] = useState<
    Record<
      string,
      {
        model_id: string;
        name: string;
        price: string;
        quantity: string;
        total: string;
        style: string;
        style_code: number;
      }
    >
  >({});
  const [status, setStatus] = useState<string>("not started");
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [customerId, setCustomerId] = useState<string>("Anonymous");

  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/dashboard/quote-view")) return;
    if (!name) return;

    setProducts({});

    const f = async () => {
      let result = await post("getQuotation", {
        group_id: account.current_group,
        quote_id: name,
      });

      if (result.status === 1) {
        if (result.customer_id) {
          setCustomerId(result.customer_id);
          if (result.customer_id === "-1") setCustomerId("Anonymous");
        }
        firstNameInputField.current!.value = result.first_name;
        lastNameInputField.current!.value = result.last_name;
        emailInputField.current!.value = result.email;
        phoneInputField.current!.value = result.phone;
        countrtInputField.current!.value = result.country;
        address1InputField.current!.value = result.address1;
        address2InputField.current!.value = result.address2;
        cityInputField.current!.value = result.city;
        regionInputField.current!.value = result.region;
        postalCodeInputField.current!.value = result.postal_code;
        shippingMethodInputField.current!.value = result.shipping_method;
        shippingOptionInputField.current!.value = result.shipping_option;
        remarksInputField.current!.value = result.remarks;
        created_at.current = result.created_at;
        receipt.current = result.hashed_order_id;

        setConverted(result.converted === 0 ? false : true);
        setSaleId(result.sale_id);

        let p: typeof products = {};

        Object.values(result.products).forEach((item: any) =>{
            let id = item.model_id + ',' + item.style_code;
            p[id] = {
                model_id: item.model_id,
                name: item.name,
                price: item.price,
                quantity: item.quantity,
                total: result.quantities[id].toString(),
                style: item.style,
                style_code: item.style_code
            }
        })

        setProducts(p);
        setStatus(result.order_status);

        let total = 0;
        Object.values(result.products).forEach((product: any) => {
          total += parseFloat(product.price);
        });
        setTotalPrice(total);
      } else {
        alert("Quotation not found.");
        navigate("/dashboard/quote");
      }
    };
    f();
  }, [name, location]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" mt-5 w-full lg:w-2/3 h-fit flex justify-end items-end gap-5">
        <motion.div
          className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer select-none"
          style={{
            backgroundColor: colors["tb-head"],
            color: colors["tb-head-text"],
          }}
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.2 }}
          onClick={async () => {
            saveQuotationExcel(account.username, name!, products, firstNameInputField.current!.value, lastNameInputField.current!.value, emailInputField.current!.value, phoneInputField.current!.value, countrtInputField.current!.value, address1InputField.current!.value, address2InputField.current!.value, cityInputField.current!.value, regionInputField.current!.value, postalCodeInputField.current!.value, shippingMethodInputField.current!.value, shippingOptionInputField.current!.value, created_at.current);
          }}
        >
          Export Excel
        </motion.div>

        <motion.div
            className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
              if (converted){
                navigate(`/dashboard/modify-sale/${saleId}`);
              } else {
                let result = await post('convertQuotationToSaleOrder', {
                  group_id: account.current_group,
                  quote_id: name
                });
                setSaleId(result.data);
                setConverted(true);
              }
            }}
          >
            <p>
              {
                converted ? `View Converted Sale Order ${saleId}` : 'Convert To Sale Order'
              }
            </p>
        </motion.div>

      </div>

      <div className=" mt-14 w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">
        <div
          className="w-full h-fit flex flex-row justify-between items-start gap-5"
          style={{
            color: colors["text"],
          }}
        >
          <div className=" flex flex-col justify-start items-start">
            <div className=" inline-flex gap-2">
              <p>Quotation Id:</p>
              <p>{name}</p>
            </div>
          </div>

          <motion.div
            className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {

              await post('updateQuotation', {
                group_id: account.current_group,
                quote_id: name,
                status: status
              }, true);

              navigate("/dashboard/quote");
            }}
          >
            Save
          </motion.div>
        </div>

        <div className=" w-full inline-flex justify-end h-fit mt-10 mb-2">
          <AiOutlineSwapLeft
            className=" text-lg hover:text-[#a9abc6] cursor-pointer"
            onClick={() => {
              navigate("/dashboard/quote");
            }}
          />
        </div>

        <div className="w-full inline-flex flex-col justify-start items-start gap-1">
          <p style={{ color: colors["text"] }}>Status</p>
          <select
            className=" w-full h-8 border border-black p-1"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="cancelled">Cancelled</option>
            <option value="not started">Not Started</option>
            <option value="transporting">Transporting</option>
            <option value="finished">Finished</option>
          </select>
        </div>

        <table className="w-full overflow-hidden whitespace-nowrap rounded-tl-md rounded-tr-md mt-5">
          <thead
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <tr>
              <th className=" pt-2 pb-2">Model Id</th>
              <th>Name</th>
              <th>Style</th>
              <th>Quantity</th>
              <th>Total</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody className=" text-center">
            {Object.values(products).map((item, index) => {
              let bg = colors["tb-row-1"];
              if (index % 2 === 1) bg = colors["tb-row-2"];
              return (
                <tr
                  key={`prod-${index}`}
                  style={{
                    backgroundColor: bg,
                    color: colors["tb-row-text"],
                  }}
                >
                  <td
                    className=" font-semibold cursor-pointer hover:underline"
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                      navigate(`/dashboard/inventory-view/${item.model_id}`);
                    }}
                  >
                    {item.model_id}
                  </td>
                  <td>{item.name}</td>
                  <td>{item.style}</td>
                  <td>{item.quantity}</td>
                  <td>{item.total}</td>
                  <td>${item.price}</td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className=" font-semibold">Total</td>
              <td>
                <p className=" underline font-semibold">${totalPrice}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="mt-5 inline-flex w-full justify-start items-center"
          style={{ color: colors["text"] }}
        >
          Customer Info
        </p>
        <div
          className="w-full flex flex-col justify-center items-start p-4 border border-dashed rounded-md"
          style={{ borderColor: colors["text"] }}
        >
          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th className="p-2">Contact</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ color: colors["tb-row-text"] }}>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Customer Id</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <div
                      className="mb-2 cursor-pointer hover:border-gray-400 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm text-start rounded-md w-full p-2.5"
                      onClick={() => {
                        if (customerId === "Anonymous") return;

                        navigate(`/dashboard/customer-view/${customerId}`);
                      }}
                    >
                      {customerId}
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">First Name</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={firstNameInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Last Name</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={lastNameInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Email</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={emailInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Phone</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={phoneInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th className="p-2">
                  Delivery
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ color: colors["tb-row-text"] }}>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Country/ Region</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={countrtInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Address 1</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={address1InputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Address 2</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={address2InputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">City</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={cityInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Region</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={regionInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Postal Code</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={postalCodeInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th className="p-2">Shipping Method</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ color: colors["tb-row-text"] }}>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td></td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center mr-2">
                    <input
                      ref={shippingMethodInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Method Option</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      ref={shippingOptionInputField}
                      disabled
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th className="p-2 text-left">Remarks (Filled By Customer)</th>
              </tr>
            </thead>
            <tbody style={{ color: colors["tb-row-text"] }}>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td>
                  <div className=" w-full p-2">
                      <textarea disabled ref={remarksInputField} className=" border-[2px] min-h-[50px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-1">

                      </textarea>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  );
}
