import { motion } from "framer-motion";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineSearch } from "react-icons/ai";
import { useWarehouseStore } from "../utils/zustand/useWarehouseStore";
import { FaEquals } from "react-icons/fa";

export function InventoryAdjust(props: { permission: any }) {
  const { colors } = useStyleStore();
  const { account } = useAccountStore();
  const {total_warehouses} = useWarehouseStore();

  const navigate = useNavigate();

    const modelIdInputField = useRef<HTMLInputElement | null>(null);
    const [adjustmentWarehouse, setAdjustmentWarehouse] = useState<string>('');

    const [adjustmentItems, setAdjustmentItems] = useState<{redis_id: string, name: string, model_id: string, style_code: number, style: string, quantity: number, adjust: number, is_set: boolean}[]>([]);

    const [actions, setActions] = useState<{redis_id: string, model_id: string, name: string, style_code: number, style: string, warehouse: string, quantity: number}[]>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() =>{
        updateRedisPage(currentPage);
    }, [currentPage])

    const changeRedisPage = (p: number) =>{
        if (p === currentPage){
            updateRedisPage(p);
        } else {
            setCurrentPage(p);
        }
    }

    const updateRedisPage = async(p: number) =>{
        if (adjustmentWarehouse === ''){
            return;
        }
        if (modelIdInputField.current!.value === ''){
            return;
        }

        let result = await post('searchInventoryRedistributions', {
            group_id: account.current_group,
            warehouse: adjustmentWarehouse,
            model_id: modelIdInputField.current!.value,
            page: p
        })
        let temp: typeof adjustmentItems = [];
        Object.values(result.data).forEach((item: any) =>{
            temp.push({
                redis_id: item.redis_id,
                model_id: item.model_id,
                name: item.name,
                style_code: parseInt(item.style_code),
                style: result.style_code_ref[parseInt(item.style_code)],
                quantity: parseInt(item.quantity),
                adjust: 0,
                is_set: false
            });
        })
        setAdjustmentItems(temp);
    }

  if (!props.permission['v-invadjust'])
    return <></>;

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" w-full lg:w-2/3 h-fit flex flex-col justify-end items-center mt-5">
        <div
          className="w-full h-fit flex flex-row justify-end items-start gap-5"
          style={{
            color: colors["text"],
          }}
        >
          <motion.div
            className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {

                if (actions.length === 0){
                    alert('No item to save yet.');
                    return;
                }

                await post('updateInventoryAmounts', {
                    group_id: account.current_group,
                    items: JSON.stringify(actions)
                }, true);


                setAdjustmentItems([]);
                setActions([]);
                navigate("/dashboard/invadjust");
            }}
          >
            Update
          </motion.div>
        </div>

        <div className="w-full h-fit flex flex-col justify-center items-center">
          <div
            className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <p className=" font-semibold">Inventory Adjustment</p>
          </div>
        </div>

        <table
          className="table-auto whitespace-nowrap text-center"
          style={{
            color: colors["tb-row-text"],
          }}
        >
          <tbody>
            <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Warehouse</td>
                <td className=" w-full pl-2">
                    <select value={adjustmentWarehouse} className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) =>{
                            setAdjustmentWarehouse(e.target.value);
                        }}
                    >
                        <option value=''></option>
                        {
                            total_warehouses.map((warehouse, index) =>{
                                return (
                                    <option key={`warehouse-${index}`} value={warehouse}>{warehouse}</option>
                                )
                            })
                        }
                    </select>
                </td>
            </tr>
            <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Model Id</td>
                <td className=" w-full pl-2">
                    <div className="w-full inline-flex justify-start items-center gap-2">
                        <input
                            ref={modelIdInputField}
                            type="text"
                            className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        />
                        <div className=" p-2 text-xl select-none cursor-pointer hover:opacity-80" style={{backgroundColor: colors['tb-head'], color: colors['tb-head-text']}}
                            onClick={async () =>{
                                if (adjustmentWarehouse === ''){
                                    alert('Warehouse field is not selected.')
                                    return;
                                }
                                if (modelIdInputField.current!.value === ''){
                                    alert('Model id field cannot be empty.')
                                    return;
                                }

                                changeRedisPage(1);
                            }}
                        >
                            <AiOutlineSearch />
                        </div>
                    </div>
                </td>
            </tr>
          </tbody>
        </table>

        <div className="mt-5 w-full flex flex-col justify-center items-center border border-dashed p-2"
            style={{
                borderColor: colors['text'],
                color: colors['text']
            }}
        >
            <table className="w-full table-auto">
                <thead>
                    <tr>
                        <th>Distribution Id</th>
                        <th>Model Id</th>
                        <th>Name</th>
                        <th>Style</th>
                        <th>Quantity</th>
                        <th>Reset</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        adjustmentItems.map((item, index) =>{
                            return (
                                <tr key={`adjust-${index}`} className=" text-center"
                                    style={{
                                        backgroundColor: index % 2 === 0 ? colors['tb-row-1'] : colors['tb-row-2']
                                    }}
                                >
                                    <td>
                                        <p className=" p-2">{item.redis_id}</p>
                                    </td>
                                    <td>
                                        {item.model_id}
                                    </td>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        {item.style}
                                    </td>
                                    <td>
                                        {item.quantity}
                                    </td>
                                    <td>
                                        <input value={item.adjust} type="number" className=" p-1 w-[80px] h-[25px] rounded-md text-center border bg-white text-black"
                                            onChange={(e) =>{
                                                let val = parseInt(e.target.value);
                                                let temp = adjustmentItems;
                                                temp[index].adjust = val;
                                                setAdjustmentItems([...temp]);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <div className="w-fit p-2 text-lg cursor-pointer hover:opacity-80 select-none"
                                            onClick={() =>{
                                                if (item.is_set){
                                                    let index = actions.findIndex((e) => e.redis_id + e.model_id + e.style_code === item.redis_id + item.model_id + item.style_code);
                                                    if (index > -1){
                                                        item.is_set = false;
                                                        let temp = actions;
                                                        temp.splice(index, 1);
                                                        setActions([...temp]);
                                                    }
                                                    return;
                                                }
                                                if (isNaN(item.adjust)){
                                                    alert('Quantity is not defined.')
                                                    return;
                                                }
                                                if (item.adjust < 0){
                                                    alert('Cannot set quantity to negative amount.')
                                                    return;
                                                }
                                                item.is_set = true;
                                                setActions([
                                                    ... actions,
                                                    {
                                                        redis_id: item.redis_id,
                                                        model_id: item.model_id,
                                                        name: item.name,
                                                        style: item.style,
                                                        style_code: item.style_code,
                                                        warehouse: adjustmentWarehouse,
                                                        quantity: item.adjust
                                                    }
                                                ])
                                            }}
                                        >
                                            {
                                                item.is_set ?
                                                <AiOutlineClose /> :
                                                <FaEquals />
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            <div className=" inline-flex justify-center items-center gap-5">
                {
                    currentPage - 1 > 0 ? <AiOutlineDoubleLeft className=' text-lg hover:cursor-pointer' 
                        onClick={() =>{
                            setCurrentPage(currentPage - 1);
                        }}/> : <></>
                }
                <p>{currentPage}</p>

                <AiOutlineDoubleRight className=' text-lg hover:cursor-pointer'
                        onClick={() =>{
                            setCurrentPage(currentPage + 1);
                }} />
            </div>

        </div>

        <div className="mt-5 w-full flex flex-col justify-start items-start"
            style={{
                color: colors['text']
            }}
        >
            <table className="w-full table-auto mt-5 text-center">
                <thead>
                    <tr>
                        <th>Distribution Id</th>
                        <th>Model Id</th>
                        <th>Name</th>
                        <th>Style</th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody
                    style={{
                        color: colors['tb-row-text']
                    }}
                >
                    {
                        actions.map((item, index) =>{
                            return (
                                <tr key={`action-${index}`}
                                    className="p-2"
                                    style={{
                                        backgroundColor: index % 2 === 0 ? colors['tb-row-1'] : colors['tb-row-2']
                                    }}
                                >
                                    <td>
                                        <p className="p-2">{item.redis_id}</p>
                                    </td>
                                    <td>{item.model_id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.style}</td>
                                    <td>{item.quantity}</td>
                                    <td>
                                      <div className="w-fit p-1 select-none cursor-pointer hover:opacity-80"
                                        onClick={() =>{
                                          let temp = actions;
                                          temp.splice(index, 1);
                                          setActions([...temp]);
                                          let index2 = adjustmentItems.findIndex((e) => e.redis_id + e.model_id + e.style_code === item.redis_id + item.model_id + item.style_code);
                                          if (index2 > -1){
                                            let temp2 = adjustmentItems;
                                            temp2[index2].is_set = false;
                                            temp2[index2].adjust = 0;
                                            setAdjustmentItems([...temp2]);
                                          }
                                        }}
                                      >
                                        <AiOutlineClose />
                                      </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

      </div>
    </div>
  );
}
