import { motion } from "framer-motion";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useWarehouseStore } from "../utils/zustand/useWarehouseStore";

export function AddWarehouse(props: { permission: any }) {
  const { colors } = useStyleStore();
  const { account } = useAccountStore();

  const {total_warehouses, setTotalWarehouse} = useWarehouseStore();

  const navigate = useNavigate();

    const nameInputField = useRef<HTMLInputElement | null>(null);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" w-full lg:w-2/3 h-fit flex flex-col justify-end items-center mt-5">
        <div
          className="w-full h-fit flex flex-row justify-end items-start gap-5"
          style={{
            color: colors["text"],
          }}
        >
          <motion.div
            className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
                if (nameInputField.current!.value === ''){
                    alert('Warehouse name cannot be empty.');
                    return;
                }
                await post('addWarehouse', {
                    group_id: account.current_group,
                    name: nameInputField.current!.value
                }, true)
                setTotalWarehouse([...total_warehouses, nameInputField.current!.value]);
              navigate("/dashboard/warehouse");
            }}
          >
            Save
          </motion.div>
        </div>

        <div className="w-full h-fit flex flex-col justify-center items-center">
          <div
            className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <p className=" font-semibold">Add Warehouse</p>
          </div>
        </div>

        <table
          className="table-auto whitespace-nowrap text-center"
          style={{
            color: colors["tb-row-text"],
          }}
        >
          <tbody>
            <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Name</td>
                <td className=" w-full pl-2">
                <input
                    ref={nameInputField}
                    type="text"
                    className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    onChange={(e) => {
                      let valid = /^[A-Za-z0-9 ]*$/.test(e.target.value);
                      if (valid) nameInputField.current!.value = e.target.value;
                      else {
                        nameInputField.current!.value = "";
                      }
                    }}
                  />
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
