import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { post } from "../utils/post";
import { useNavigate, useParams } from "react-router-dom";

export function AddSlide(props: {permission: any}) {

  const {name} = useParams();
  const allTypes: string[] = ['main', 'frame'];

  const [inputFile, setInputFile] = useState<File | null>(null);
  const [images, setImages] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() =>{
        if (!name || !allTypes.includes(name)){
            navigate('/dashboard/slide');
            return;
        }

        const f = async() =>{
            let result = await post('getSlide', {
                modelId: name
            })
            let typedItems = result.data as any;
            if (typedItems){
                setImages([...typedItems.split(',')]);
            }
        };
        f();

    }, [name])

  useEffect(() => {
    if (!props.permission['m-slide'])
      return;
    if (inputFile === null) return;
    if (inputFile.type.split("/")[0] !== "image") {
      alert("Only accept image format file.");
      setInputFile(null);
      return;
    }
    const f = async () => {
      let buffer = await inputFile.arrayBuffer();
      let data = new FormData();
      data.append(
        "file",
        new Blob([buffer], { type: inputFile.type }),
        inputFile.name
      );
      data.append("id", name!);
      data.append("filename", inputFile.name);
      data.append("table", "slides");
      data.append("path", name! + '/');

      let result = fetch(
        "https://inanin.com/shopping-platform/commerce-backend/upload.php",
        {
          method: "POST",
          body: data,
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const getData = await response.json();

          if (getData.status === "upload success") {
            let temp = images;
            temp.push(inputFile.name);
            setImages([...temp]);
          } else {
            alert(getData.status);
          }
        })
        .catch((e) => {
          alert("There was a problem with the file upload.");
          console.log(e);
        })
        .finally(() => {
          setInputFile(null);
        });
    };
    f();
  }, [inputFile]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" mt-14 w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">
        <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
          <thead className="bg-[#4a4f5a] text-white">
            <tr>
              <th className="p-2">Slide Info</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className=" bg-[#F5F3F9]">
              <td className=" pl-2">Type</td>
              <td className=" w-full pl-2">
                <p className="mb-2 mt-2 bg-white border-[2px] border-gray-300 text-gray-900 text-start text-sm rounded-md w-full p-2.5">{name!}</p>
              </td>
            </tr>
            <tr className=" bg-[#F5F3F9]">
              <td className=" pl-2 align-top pt-2">Images</td>
              <td className=" w-full pl-2">
                <input
                  type="file"
                  className=" mb-2 flex left-0"
                  disabled={inputFile ? true : false}
                  onChange={(e) => {
                    if (!props.permission['m-slide'])
                      return;
                    if (inputFile) return;
                    if (e.target.files)
                      if (e.target.files[0]) setInputFile(e.target.files[0]);
                  }}
                />
                <div className=" w-full max-h-52 align-top flex flex-col justify-start items-start mr-2 overflow-y-scroll">
                  {images.map((item, index) => {
                    let bgColor = "#FDFCFF";
                    if (index % 2 === 0) bgColor = "#D5CFDF";
                    return (
                      <div
                        key={`img-${index}`}
                        className=" w-full p-1 pr-3 text-start flex justify-between items-start"
                        style={{
                          backgroundColor: bgColor,
                        }}
                      >
                        <div className=" w-fit h-fit flex flex-col justify-center items-start">
                          <p
                            className="hover:cursor-pointer hover:underline w-fit"
                            onClick={() => {
                              window.open(
                                `https://inanin.com/shopping-platform/commerce-backend/uploads/${name!}/${name!}%23${item}`
                              );
                            }}
                          >
                            {item}
                          </p>
                          <img
                            src={`https://inanin.com/shopping-platform/commerce-backend/uploads/${name!}/${name!}%23${item}`}
                            className=" w-full max-w-[10vw] h-full max-h-[10vw]"
                          />
                        </div>

                        <AiOutlineClose
                          className=" hover:cursor-pointer"
                          onClick={async () => {
                            await post("deleteImage", {
                              table: "slides",
                              path: `${name!}/`,
                              modelId: name!,
                              image: item,
                            });

                            let temp: string[] = [];
                            for (let i = 0; i < images.length; i++) {
                              if (i !== index) temp.push(images[i]);
                            }
                            setImages([...temp]);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
