import { useEffect, useRef, useState } from "react";
import { useAccountStore } from "../utils/zustand/useAccountStore"
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { post } from "../utils/post";


export function AddUser(props: {permission: any}){

    const navigate = useNavigate();
    const {account, groups, setAccount, setGroups} = useAccountStore();

    const [groupIndex, setGroupIndex] = useState<number>(-1);

    const [groupName, setGroupName] = useState<string>('');
    const [cartRefreshDate, setCartRefreshDate] = useState<number>(0);
    const [quoteExpiryDate, setQuoteExpiryDate] = useState<number>(0);

    const [displayDeleteGroup, setDisplayDeleteGroup] = useState<boolean>(false);
    const deleteGroupInputField = useRef<HTMLInputElement | null>(null);

    const [email, setEmail] = useState<string>('');

    const [users, setUsers] = useState<
    {
      user_id: number;
      username: string;
      permission: string;
    }[]
  >([]);

    const totalLength = useRef<number>(0);
    const totalPage = useRef<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() =>{
        let index = groups.findIndex(e => e.id === account.current_group);
        if (index > -1){
            setGroupIndex(index);
            setGroupName(groups[index].name);
        }
    }, [])

    useEffect(() =>{
        if (groupIndex === -1)
            return;
        const f = async() =>{
            let result = await post("getUsers", {
                group_id: account.current_group,
                page: currentPage,
                admin: true
              });

              let arr = [];
              for (let i = 0; i < result.data.length; i++) {
                let data = result.data[i];
                arr.push({
                  user_id: data.id,
                  username: data.username,
                  permission: data.permission,
                });
              }
        
              setQuoteExpiryDate(parseInt(result.info.quote_expiry_date));
              setCartRefreshDate(parseInt(result.info.cart_refresh_date));

              totalLength.current = result.length;
              totalPage.current = Math.ceil(totalLength.current / 10);
        
              setUsers([...arr]);
        };
        f();
    }, [currentPage])

    if (!props.permission['a-admin'])
        return <></>;

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center">
            
            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                <div className="w-full h-fit flex flex-row justify-end items-center text-white gap-5">

                    {
                        groups[groupIndex] && groups[groupIndex].created_by === account.user_id.toString() || account.permission === 'super' ?
                        <motion.div className=" w-fit h-fit bg-[#a03b58] flex justify-center select-none items-center p-2 mt-2 hover:cursor-pointer"
                            initial={{scale: 1}}
                            whileHover={{scale: 1.2}}
                            onClick={async() =>{
                                if (!props.permission['a-admin'])
                                    return;
                                
                                setDisplayDeleteGroup(!displayDeleteGroup);
                            }}
                        >
                            Delete Group
                        </motion.div> : <></>
                    }
                    
                    <motion.div className=" w-fit h-fit bg-[#649d7f] flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async() =>{
                            if (!props.permission['a-admin'])
                                return;
                            if (groupIndex === -1)
                                return;

                            await post('setGroupName', {
                                group_id: account.current_group,
                                name: groupName,
                                cart_refresh_date: cartRefreshDate,
                                quote_expiry_date: quoteExpiryDate
                            }, true)

                            let temp = groups;
                            temp[groupIndex].name = groupName;
                            setGroups([...temp]);

                            alert('Saved.');
                        }}
                    >
                        Save
                    </motion.div>
                </div>
                
                <div className=" w-full flex-col justify-center items-end pt-2 gap-2"
                    style={{
                        display: displayDeleteGroup ? 'flex' : 'none'
                    }}
                >
                    <p>{`Enter "Delete ${groupName}" to confirm.`}</p>
                    <input ref={deleteGroupInputField} placeholder={`Delete ${groupName}`} className=" w-full border border-zinc-800 rounded-md bg-white p-1" />
                    <div className=" p-1 pl-2 pr-2 font-semibold bg-[#56596C] text-white select-none cursor-pointer hover:opacity-70"
                        onClick={async() =>{
                            if (deleteGroupInputField.current!.value !== `Delete ${groupName}`){
                                alert('Invalid input.')
                                return;
                            }

                                await post('deleteGroup', {
                                    group_id: account.current_group,
                                }, true)

                                let temp = groups;
                                temp.splice(groupIndex, 1);

                                setGroupIndex(-1);

                                setAccount({
                                    ...account,
                                    current_group: -1,
                                    permission: '{}'
                                })
                                setGroups([...temp]);

                                navigate('/dashboard/');
                        }}
                    >
                        I confirm to delete this group.
                    </div>
                </div>

                {
                    groups[groupIndex] && groups[groupIndex].created_by === account.user_id.toString() || account.permission === 'super' ?
                    <p className=" mt-5 p-1 text-sm w-full inline-flex justify-start bg-[#f6f8c3] text-black">
                        * Deleted groups would be stored in our system for a month. Contact user1.inanin@gmail.com to get back deleted groups. Data over a month would be permanently deleted.
                    </p> : <></>
                }

                <div className=" mt-5 w-full h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                    <p>Group Info</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/admin');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full">
                    <tbody>
                        <tr className=" bg-[#F5F3F9]">
                            <td className=" pl-2">Group Name</td>
                            <td className=" w-full pl-2 p-2"> 
                                <input value={groupName} className=" w-full p-1 border border-zinc-800 rounded-md" 
                                    onChange={(e) =>{
                                        setGroupName(e.target.value);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr className=" bg-[#ECE9F1]">
                            <td className=" pl-2">Cart Refresh Period</td>
                            <td className=" w-full pl-2 p-2 flex flex-col"> 
                                <div className=" flex flex-col justify-start items-start text-sm whitespace-pre-wrap text-start p-2 bg-[#f6f8c3] gap-2">
                                    <p className=" font-bold">What Does That Mean?</p>
                                    <p>Let's say a customer added an item to the shopping cart at $100. He didn't proceed to checkout afterward. Then you raised the price of that item to $500. Should the price of that item follows the latest price, or the price when the item was added to the shopping cart?</p>
                                    <p>If the cart refresh period is zero, then the price would always be the latest price ($500). Else, the price would be the old price ($100) until the period when the item was added to the cart exceeded the cart refresh period.</p>
                                    <p className=" font-semibold">This apply to customers with accounts only. Anonymous customers will always pay the latest price.</p>
                                </div>
                                <input value={cartRefreshDate} className=" w-full p-1 border border-zinc-800 rounded-md" 
                                    onChange={(e) =>{
                                        let val = parseInt(e.target.value);
                                        if (isNaN(val))
                                            val = 0;
                                        if (val < 0)
                                            val = 0
                                        setCartRefreshDate(val);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr className=" bg-[#F5F3F9]">
                            <td className=" pl-2">Quotation Expiry Period</td>
                            <td className=" w-full pl-2 p-2 flex flex-col"> 
                                <div className=" flex flex-col justify-start items-start text-sm whitespace-pre-wrap text-start p-2 bg-[#f6f8c3] gap-2">
                                    <p className=" font-bold">What Does That Mean?</p>
                                    <p>Let's say a customer added a quotation record. If the issued period exceeded the quotation expiry period, then the quotation record would be outdated.</p>
                                    <p>Outdated quotation records would be highlighted and the status would change to "expired". Customers are able to view the quotation status outside of the system.</p>
                                    <p className=" font-semibold">-1 = never expire, 0 = always expire, 1 ... n = expire after n days.</p>
                                    <p className=" font-semibold">Auto update at 12:00 am everyday.</p>
                                </div>
                                <input value={quoteExpiryDate} className=" w-full p-1 border border-zinc-800 rounded-md" 
                                    onChange={(e) =>{
                                        let val = parseInt(e.target.value);
                                        if (isNaN(val))
                                            val = 0;
                                        if (val < -1)
                                            val = -1
                                        setQuoteExpiryDate(val);
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="w-full mt-5 h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                    <p>All Users</p>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full">
                    <tbody>
                        {
                            users.map((user, index) =>{
                                return (
                                    <tr key={`user-${index}`}>
                                        <td>{user.permission.startsWith('{') ? 'user' : `~${user.permission}`}</td>
                                        <td className=" cursor-pointer hover:underline"
                                            onClick={() =>{
                                                navigate(`/dashboard/user-permission/${user.user_id}`)
                                            }}
                                        >
                                            {user.username}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div className='w-full h-fit flex flex-row justify-center items-center gap-5 mt-5'>
                    {
                        currentPage - 1 > 0 ? <AiOutlineDoubleLeft className=' text-lg hover:cursor-pointer' 
                            onClick={() =>{
                                setCurrentPage(currentPage - 1);
                            }}/> : <></>
                    }
                    <p>{currentPage}</p>
                    {
                        currentPage + 1 > totalPage.current ? <></> : <AiOutlineDoubleRight className=' text-lg hover:cursor-pointer'
                            onClick={() =>{
                                setCurrentPage(currentPage + 1);
                            }} />
                    }
                </div>

                <div className="w-full mt-5 h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                    <p>Add User</p>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full">
                    <tbody>
                        <tr className=" bg-[#F5F3F9]">
                            <td className=" pl-2">
                                Name
                            </td>
                            <td className=" w-full pl-2 p-2"> 
                                <input value={email} className=" w-full p-1 border border-zinc-800 rounded-md" 
                                    onChange={(e) =>{
                                        setEmail(e.target.value);
                                    }}
                                />
                            </td>
                            <td>
                                <div className=" mr-1 p-1 bg-[#30323f] text-white cursor-pointer hover:bg-opacity-80"
                                    onClick={async() =>{
                                        let result = await post('addUser', {
                                            group_id: account.current_group,
                                            email: email
                                        })
                                        if (result.status === 0){
                                            alert('Email not found.')
                                        } else if (result.status === 1){
                                            alert('User added.');
                                        } else {
                                            alert('Email already exists in this group.');
                                        }
                                    }}
                                >
                                    <FaArrowRight />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    )
}