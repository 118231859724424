import { allPermissions, superAdminPermission } from "../admin/UserPermission";


export function getPermission(permissionStr: string, fillAll?: boolean){
    let dict: Record<string, boolean> = {};

    if (fillAll)
        Object.keys(allPermissions).forEach((key) =>{
            dict[key] = false;
        })

    if (permissionStr === 'admin'){
        Object.keys(allPermissions).forEach((key) => {
            dict[key] = true;
        })
    } else if (permissionStr === 'super'){
        Object.keys(allPermissions).forEach((key) => {
            dict[key] = true;
        })
        Object.keys(superAdminPermission).forEach((key) =>{
            dict[key] = true;
        })
    } else {
        permissionStr.split(',').forEach((val) =>{
            dict[val] = true;
        })
    }
    return dict;
}