import { FaUser, FaBell, FaGear, FaDoorClosed } from "react-icons/fa6";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";

export function Placeholder() {

  const {account} = useAccountStore();
  const {colors} = useStyleStore();

  return (
    <div className=" w-full h-screen flex flex-col justify-center items-center">
      <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center gap-2 select-none"
        style={{
          color: colors['tb-row-text-d']
        }}
      >
            <p>
            {
                account.current_group === -1 ?
                'You have not selected any groups.' :
                'You have not selected any functions.'
            }
            </p>
            <div className=" w-full inline-flex justify-center items-center gap-2 mt-5">
                <p>Click</p>
                <FaUser />
                <p>to select group.</p>
            </div>
            <div className=" w-full inline-flex justify-center items-center gap-2">
                <p>Click</p>
                <FaBell />
                <p>to see notifications.</p>
            </div>
            <div className=" w-full inline-flex justify-center items-center gap-2">
                <p>Click</p>
                <FaGear />
                <p>to edit personal settings.</p>
            </div>
            <div className=" w-full inline-flex justify-center items-center gap-2">
                <p>Click</p>
                <FaDoorClosed />
                <p>to logout.</p>
            </div>
      </div>
    </div>
  );
}
