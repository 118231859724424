import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useTabStore } from "../utils/zustand/useTabStore";
import { post } from "../utils/post";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlinePlus } from "react-icons/ai";
import { FaStamp } from "react-icons/fa";



export function PendingAccount(props: {permission: any}){

    const selectAllheckbox = useRef<HTMLInputElement | null>(null);

    const [items, setItems] = useState<{checked: boolean, username: string, user_id: string}[]>([]);

    const {colors} = useStyleStore();

    const totalLength = useRef<number>(0);
    const totalPage = useRef<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const fetchCount = useRef<number>(0);

    useEffect(() =>{

        const f = async () =>{
            fetchCount.current++;
            let length = await post('getLength', {
                name: 'platform_accounts',
                where: `\`account_type\` = 'business' AND \`isPending\` = '2'`
            });
            totalLength.current = length.data;
            totalPage.current = Math.ceil(totalLength.current / 20);

            setCurrentPage(1);
            fetchCount.current--;
        }
            f();
    }, [])

    useEffect(() =>{
        if (totalPage.current === 0)
        return;

        const f = async() =>{
            let result = await post('getAllPendingAccounts', {
                type: 2
            });

            let temp: typeof items = [];
            Object.values(result.data).forEach((item) =>{
                let typed_item = item as any;
                temp.push({
                    checked: false,
                    username: typed_item.username,
                    user_id: typed_item.id
                });
            })
            setItems([...temp]);
        };
        f();
    }, [currentPage])

    if (!props.permission['v-stockin-opt'])
        return <></>;

    return (
        <div className=" w-full h-fit flex flex-col justify-center items-center">

            <div className="w-full lg:w-2/3 h-fit flex flex-row justify-end items-center mt-5 gap-2"
                style={{
                    color: colors['tb-head-text']
                }}
            >

                {
                    props.permission['a-pend-ac'] ?
                    <div className=' p-2 rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer'
                        style={{
                            backgroundColor: colors['tb-head']
                        }}
                        onClick={() =>{

                        }}>
                        <p>Approve All</p>
                    </div> : <></>
                }

{
                    props.permission['a-pend-ac'] ?
                    <div className=' p-2 rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer'
                        style={{
                            backgroundColor: colors['tb-head']
                        }}
                        onClick={() =>{

                        }}>
                        <p>Disapprove All</p>
                    </div> : <></>
                }

            </div>

            <div className=" mt-5 w-full lg:w-2/3 h-fit">
                <table className="table-auto  whitespace-nowrap">
                    <thead
                        style={{
                            color: colors['tb-head-text']
                        }}
                    >
                        <tr>
                            <th>
                                <div className=" rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]"
                                    style={{
                                        backgroundColor: colors['tb-head']
                                    }}
                                >
                                    <input ref={selectAllheckbox} type="checkbox" className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" 
                                        onChange={(e) =>{
                                            let temp = items;
                                            for(let i = 0; i < temp.length; i++){
                                                temp[i].checked = selectAllheckbox.current!.checked;
                                            }
                                            setItems([...temp]);
                                        }} />
                                </div>
                            </th>
                            <th className="text-start w-full">
                                <div className=" flex justify-center items-center"
                                    style={{
                                        backgroundColor: colors['tb-head']
                                    }}
                                >
                                    <p>Username</p>
                                </div>
                            </th>
                            <th>
                                <div className="pl-1 pr-1" style={{backgroundColor: colors['tb-head']}}>Approve</div>
                            </th>
                            <th>
                                <div className="pl-1 pr-1" style={{backgroundColor: colors['tb-head']}}>Disapprove</div>
                            </th>
                        </tr> 
                    </thead>
                    <tbody>
                        {
                            items.map((item, index) =>{
                                let bgColor = colors['tb-row-1'];
                                if (index % 2 === 0)
                                    bgColor = colors['tb-row-2'];
                                return (
                                    <tr key={`stockin-${index}`} style={{
                                        backgroundColor: bgColor
                                    }}>
                                        <td>
                                            <div className="flex justify-center items-center h-[24px] mb-[1px]">
                                                <input type="checkbox" checked={item.checked} className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" 
                                                    onChange={(e) =>{
                                                        let temp = items;
                                                        temp[index].checked = e.target.checked;
                                                        setItems([...temp]);

                                                        let allChecked: boolean = true;
                                                        for(let i = 0; i < temp.length; i++){
                                                            if (!temp[i].checked){
                                                                allChecked = false;
                                                            }
                                                        }
                                                        selectAllheckbox.current!.checked = allChecked;
                                                }} />
                                            </div>
                                        </td>
                                        <td className=" w-full">
                                            <div className="  flex justify-center items-center"
                                                onClick={() =>{

                                                }}>
                                                <p className="hover:cursor-pointer font-semibold">
                                                    {item.username}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-full inline-flex justify-center select-none">
                                                <div className="pl-2 pr-2 rounded-md cursor-pointer hover:opacity-70"
                                                    style={{
                                                        backgroundColor: colors['tb-head'],
                                                        color: colors['tb-head-text']
                                                    }}
                                                    onClick={async() =>{
                                                        await post('setAccountPending', {
                                                            user_id: item.user_id,
                                                            type: 0
                                                        }, true);

                                                        let temp = items;
                                                        temp.splice(index, 1);
                                                        setItems([...temp]);
                                                    }}
                                                >
                                                    <FaStamp />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-full inline-flex justify-center select-none">
                                                <div className="pl-2 pr-2 rounded-md cursor-pointer hover:opacity-70"
                                                    style={{
                                                        backgroundColor: colors['tb-head'],
                                                        color: colors['tb-head-text']
                                                    }}
                                                    onClick={async() =>{
                                                        await post('deleteAccount', {
                                                            user_id: item.user_id
                                                        }, true);

                                                        let temp = items;
                                                        temp.splice(index, 1);
                                                        setItems([...temp]);
                                                    }}
                                                >
                                                    <FaStamp />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>

                <div className='w-full h-fit flex flex-row justify-center items-center gap-5 mt-5'
                    style={{
                        color: colors['text']
                    }}
                >
                    {
                        currentPage - 1 > 0 ? <AiOutlineDoubleLeft className=' text-lg hover:cursor-pointer' 
                            onClick={() =>{
                                setCurrentPage(currentPage - 1);
                            }}/> : <></>
                    }
                    <p>{currentPage}</p>
                    {
                        currentPage + 1 > totalPage.current ? <></> : <AiOutlineDoubleRight className=' text-lg hover:cursor-pointer'
                            onClick={() =>{
                                setCurrentPage(currentPage + 1);
                            }} />
                    }
                </div>

            </div>

        </div>
    )
}