import { create } from 'zustand'

interface TabState {
    current_tab: string;
    current_link: string;
    // name, link
    all_tabs: Record<string, string>;
    total_tabs: number;
    goTab: (tab: string, link: string, all_tabs: Record<string, string>) => void;
    deleteTabs: (tabs: string[], all_tabs: Record<string, string>) => void;
    deleteAllTabs: () => void;
}

export const useTabStore = create<TabState>()((set) => ({
    current_tab: '',
    current_link: '/dashboard',
    all_tabs: {},
    total_tabs: 0,
    goTab: (_tab, _link, _all_tabs) =>{
        _all_tabs[_tab] = _link;
        set(() => ({
            current_tab: _tab,
            current_link: _link,
            all_tabs: _all_tabs,
            total_tabs: Object.keys(_all_tabs).length
        }));
    },
    deleteTabs: (_tabs, _all_tabs) =>{
        for(let i = 0; i < _tabs.length ; i++){
            delete _all_tabs[_tabs[i]];
        }
        set(() => ({
            all_tabs: _all_tabs,
            total_tabs: Object.keys(_all_tabs).length
        }));
    },
    deleteAllTabs: () =>{
        set(() => ({
            current_tab: '',
            current_link: '',
            all_tabs: {},
            total_tabs: 0
        }))
    }
}))