import { motion } from "framer-motion";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useNavigate, useParams } from "react-router-dom";
import { post, postEShop } from "../utils/post";
import {
  AiFillCaretRight,
  AiFillPlusSquare,
  AiOutlineClose,
  AiOutlineExclamation,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlineSwapLeft,
  AiOutlineWarning,
} from "react-icons/ai";
import { FaRegStar, FaExternalLinkAlt } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { CustomAmountProps, OptionProps } from "../stock-in/ModifyStockIn";
import { getCustomAmount } from "../stock-in/AddStockIn";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { FaPlus } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

type SaleItem = {
  model_id: string;
  style: string;
  name: string;
  quantity: number;
  price: number;
  is_custom: number;
  style_code: number;
};

export function ModifySale() {
  const { account } = useAccountStore();
  const { colors } = useStyleStore();

  const {name} = useParams();
  const navigate = useNavigate();

  const [items, setItems] = useState<SaleItem[]>([]);

  const nameInputField = useRef<HTMLInputElement | null>(null);
  const modelIdInputField = useRef<HTMLInputElement | null>(null);
  const priceInputField = useRef<HTMLInputElement | null>(null);

  const custom_nameInputField = useRef<HTMLInputElement | null>(null);
  const custom_priceInputField = useRef<HTMLInputElement | null>(null);

  const [isItemApproved, setIsItemApproved] = useState<boolean>(false);
  const [amountType, setAmountType] = useState<string>("total");
  const [customAmounts, setCustomAmounts] = useState<CustomAmountProps[]>([]);
  const [options, setOptions] = useState<OptionProps[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [converted, setConverted] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>('');
  const [quoteId, setQuoteId] = useState<string>('');
  const [hashed_sale_id, setHashedSaleId] = useState<string>('');
  const [copied_sale_id, setCopiedSaleId] = useState<string>('');

  const forwardInputField = useRef<HTMLInputElement | null>(null);
  const [forwardTo, setForwardTo] = useState<string[]>([]);

  const companyInputField = useRef<HTMLInputElement | null>(null);
  const enquiryInputField = useRef<HTMLInputElement | null>(null);
  const customerInputField = useRef<HTMLInputElement | null>(null);

  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => {
    if (!name)
        return;

    setAmountType('total');
    setCustomAmounts([]);
    setOptions([]);
    setItems([]);
    
    const f = async() =>{
        let result = await post('getSale', {
            sale_id: name,
            group_id: account.current_group
        });

        if (!result.data)
            return;

        let temp: typeof items = [];
        Object.values(result.data).forEach((item: any) =>{
            let is_custom = 0;
            if (item.model_id === '')
                is_custom = 1;
            temp.push({
                model_id: item.model_id,
                name: item.name,
                style: item.style,
                style_code: item.style_code,
                price: parseFloat(item.price) / parseInt(item.quantity),
                quantity: item.quantity,
                is_custom: is_custom
            })
        })
        
        setConverted(result.converted === 1 ? true : false);
        setOrderId(result.order_id);
        setQuoteId(result.quote_id);
        setHashedSaleId(result.hashed_sale_id);
        setCopiedSaleId(result.copied_sale_id);
        if (result.email)
          setForwardTo([result.email]);

        companyInputField.current!.value = result.group_name;
        customerInputField.current!.value = result.first_name;

        setItems([...temp]);
    };
    f();

  }, [name]);

  useEffect(() => {
    let temp = 0;
    items.forEach((item) => {
      temp += item.price * item.quantity;
    });
    setTotalPrice(temp);
  }, [items]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      
      <div className=" mt-5 w-full lg:w-2/3 h-fit flex flex-col justify-end items-end gap-2">
        <motion.div
          className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
          style={{
            backgroundColor: colors["tb-head"],
            color: colors["tb-head-text"],
          }}
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.2 }}
          onClick={async () => {

          }}
        >
          Export Excel
        </motion.div>

        <motion.div
            className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
              let result = await post('copySale', {
                group_id: account.current_group,
                sale_id: name
              })
              navigate(`/dashboard/modify-sale/${result.sale_id}`);
            }}
          >
            <p>
              Copy To New Sale Order
            </p>
        </motion.div>

        <motion.div
            className=" w-fit h-fit justify-center items-center p-2 hover:cursor-pointer"
            style={{
              display: converted ? 'flex' : 'none',
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
              if (converted){
                navigate(`/dashboard/order-view/${orderId}`);
              }
            }}
          >
            <p>
              {`View Converted Customer Order ${orderId}`}
            </p>
        </motion.div>

        <div className=" flex flex-col justify-end items-end gap-2">
          <input ref={companyInputField} placeholder="Company Name" className=" placeholder:italic placeholder:text-center bg-white text-black border border-zinc-800 rounded-md p-1" />
          <input ref={enquiryInputField} placeholder="Enquiry Contact (Optional)" className=" placeholder:italic placeholder:text-center placeholder:text-xs bg-white text-black border border-zinc-800 rounded-md p-1" />
          <input ref={customerInputField} placeholder="Customer Name" className=" placeholder:italic placeholder:text-center bg-white text-black border border-zinc-800 rounded-md p-1" />
          <div className=" inline-flex gap-2">
            <div className=" p-2 text-xl select-none cursor-pointer hover:opacity-80" style={{backgroundColor: colors['tb-head'], color: colors['tb-head-text']}}
              onClick={() =>{
                if (forwardInputField.current!.value === '')
                  return;
                for(let i = 0; i < forwardTo.length; i++){
                  if (forwardTo[i] === forwardInputField.current!.value){
                    forwardInputField.current!.value = '';
                    return;
                  }
                }

                setForwardTo([...forwardTo, forwardInputField.current!.value])
                forwardInputField.current!.value = '';
              }}
            >
              <FaPlus />
            </div>
            <input ref={forwardInputField} placeholder="Send Email To ..." className=" placeholder:italic placeholder:text-center bg-white text-black border border-zinc-800 rounded-md p-1" />
          </div>
        </div>

        <div className="w-fit h-fit p-2 flex justify-end items-end gap-2" style={{color: colors['text']}}>
            {
              forwardTo.map((item, index) =>{
                return (
                  <div key={`forward-${index}`} className=" inline-flex gap-2 justify-start items-start">
                    <p>{item}</p>
                    <div className=" p-1 select-none cursor-pointer hover:opacity-80 rounded-full text-xs" style={{backgroundColor: colors['tb-row-1'], color: colors['bg']}}
                      onClick={() =>{
                        let temp = forwardTo;
                        temp.splice(index, 1);
                        setForwardTo([...temp]);
                      }}
                    >
                      <MdClose />
                    </div>
                  </div>
                )
              })
            }
        </div>

        <motion.div
            className=" w-fit h-fit justify-center items-center p-2 hover:cursor-pointer"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
                if (isSending)
                  return;
                if (forwardTo.length === 0){
                  alert('Forward field must at least has one email.');
                  return;
                }
                if (companyInputField.current!.value === ''){
                  alert('Company name cannot be empty');
                  return;
                }
                if (customerInputField.current!.value === ''){
                  alert('Customer name cannot be empty');
                  return;
                }
                setIsSending(true);
                await postEShop('sendSaleEmail', {
                  token: 're_XfBUHzQo_QDKgPjn6dG2r5b1mrwPyaUEj',
                  sale_id: hashed_sale_id,
                  forwards: JSON.stringify(forwardTo),
                  enquiry: enquiryInputField.current!.value,
                  sender_company: companyInputField.current!.value,
                  receiver_name: customerInputField.current!.value
                })
                setIsSending(false);
            }}
          >
            <p>
              {isSending ? 'Sending...' : 'Send Sale Order To Customer'}
            </p>
        </motion.div>

      </div>
      
      <div className=" w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">

        <div
          className=" mt-5 w-full h-fit flex flex-row justify-between items-start gap-5"
          style={{
            color: colors["text"],
          }}
        >

          <div className="flex flex-col justify-start items-start">

            {
              copied_sale_id ?
              <div className=" inline-flex gap-2">
                <p>Copied Sale Id:</p>

                <div className=" cursor-pointer hover:underline inline-flex justify-center items-center gap-2"
                  onClick={() =>{
                    navigate(`/dashboard/modify-sale/${copied_sale_id}`);
                  }}
                >
                  <p>{copied_sale_id}</p>
                  <FaExternalLinkAlt className="text-xs" />
                </div>
              </div> :
              <></>
            }

            <div className=" inline-flex gap-2">
              <p>Sale Id:</p>
              <p>{name}</p>
            </div>

            <div className=" gap-2"
              style={{
                display: quoteId ? 'inline-flex' : 'none'
              }}
            >
              <p>Quotation Id:</p>
              <div className=" cursor-pointer hover:underline inline-flex justify-center items-center gap-2"
                onClick={() =>{
                  navigate(`/dashboard/quote-view/${quoteId}`)
                }}
              >
                <p>{quoteId}</p>
                <FaExternalLinkAlt className="text-xs" />
              </div>
            </div>

            <div className=" inline-flex gap-2 justify-center items-center">
              <p>Payment Page</p>
              <div className=" w-fit p-1 cursor-pointer select-none hover:underline"
                style={{
                  backgroundColor: colors['tb-head'],
                  color: colors['tb-head-text']
                }}
                onClick={() =>{
                  navigator.clipboard.writeText(`https://shopping-platform-front.vercel.app/sale/${hashed_sale_id}`);
                }}
              >
                Copy
              </div>
              <div className=" w-fit p-1 cursor-pointer select-none hover:underline"
                style={{
                  backgroundColor: colors['tb-head'],
                  color: colors['tb-head-text']
                }}
                onClick={() =>{
                  window.open(`https://shopping-platform-front.vercel.app/sale/${hashed_sale_id}`)
                }}
              >
                Open
              </div>
            </div>

          </div>

          <motion.div
            className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
                await post('updateSale', {
                  group_id: account.current_group,
                  sale_id: name,
                  order_id: orderId,
                  orders: JSON.stringify(items)
                }, true);

              navigate("/dashboard/sale");
            }}
          >
            Save
          </motion.div>
        </div>

        <div className=" w-full inline-flex justify-end h-fit mt-5 mb-2">
          <AiOutlineSwapLeft
            className=" text-lg hover:opacity-40 cursor-pointer"
            style={{
              color: colors['text']
            }}
            onClick={() => {
              navigate("/dashboard/sale");
            }}
          />
        </div>

        <div className="w-full h-fit flex flex-col justify-center items-center">
          <div
            className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <p className=" font-semibold">Insert Item From Inventory</p>
            <div className=" w-fit flex justify-center items-center gap-5">
              <AiFillPlusSquare
                className=" text-2xl hover:cursor-pointer"
                onClick={() => {
                  if (customAmounts.length === 0) return;

                  let quantity = getCustomAmount(amountType, customAmounts);

                  if (quantity) {
                    let optionStr: string = "";
                    for (let i = 0; i < options.length; i++) {
                      optionStr = optionStr + options[i].title + ",";
                    }
                    optionStr = optionStr.substring(0, optionStr.length - 1);

                    let temp = items;
                    for (let a = 0; a < quantity.length; a++) {
                      for (let k = 0; k < quantity[a].d.length; k++) {
                        let index = items.findIndex(
                          (e) =>
                            e.model_id === modelIdInputField.current!.value &&
                            e.style === quantity![a].c
                        );
                        if (index > -1) {
                          let val = temp[index].quantity;
                          temp[index].quantity = val + quantity[a].d[k].a;
                        } else {
                          temp.push({
                            model_id: modelIdInputField.current!.value,
                            name: nameInputField.current!.value,
                            style: quantity[a].c,
                            quantity: quantity[a].d[k].a,
                            price: parseFloat(priceInputField.current!.value),
                            is_custom: 0,
                            style_code: 0,
                          });
                        }
                      }
                    }
                    setItems([...temp]);
                    nameInputField.current!.value = "";
                    modelIdInputField.current!.value = "";
                    setOptions([]);
                    setCustomAmounts([]);
                  }
                }}
              />
            </div>
          </div>

          <table
            className="table-auto whitespace-nowrap text-center"
            style={{
              color: colors["tb-row-text"],
            }}
          >
            <tbody>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Name</td>
                <td className=" w-full pl-2">
                  <input
                    ref={nameInputField}
                    type="text"
                    className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    onChange={(e) => {
                      let valid = /^[A-Za-z0-9-+'"()#!?$&_ ]*$/.test(e.target.value);
                      if (valid) nameInputField.current!.value = e.target.value;
                      else {
                        nameInputField.current!.value = "";
                      }

                      priceInputField.current!.value = "";
                      setAmountType("total");
                      setOptions([]);
                      setCustomAmounts([]);
                      setIsItemApproved(false);
                    }}
                  />
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Model Id</td>
                <td className=" w-full">
                  <div className=" w-full p-2 flex justify-start items-center gap-2">
                    <input
                      ref={modelIdInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      onChange={(e) =>{
                        let valid = /^[A-Za-z0-9-]*$/.test(e.target.value);
                        if (valid)
                            modelIdInputField.current!.value = e.target.value;
                        else {
                            modelIdInputField.current!.value = '';
                        }

                        setIsItemApproved(false);
                        setOptions([]);
                        setCustomAmounts([]);
                      }}
                    />
                    <div
                      className=" p-2 hover:cursor-pointer"
                      style={{
                        backgroundColor: colors["tb-head"],
                        color: colors["tb-head-text"],
                      }}
                      onClick={async () => {
                        let result = await post("generateModelDetail", {
                          group_id: account.current_group,
                          name: nameInputField.current!.value,
                          model_id: modelIdInputField.current!.value
                        });
                        console.log(result)
                        if (result.is_new) {
                          setIsItemApproved(false);
                          alert("Inventory does not contain this item.");
                          return;
                        }
                        setIsItemApproved(true);
                        setAmountType("total");
                        setCustomAmounts([
                          {
                            conditions: [],
                            details: [{ amount: 0, warehouse: "" }],
                          },
                        ]);
                        nameInputField.current!.value = result.name;
                        modelIdInputField.current!.value = result.model_id;
                        priceInputField.current!.value = result.price;
                        let temp: OptionProps[] = [];
                        Object.entries(result.styles).forEach(
                          ([title, options]) => {
                            let typedOptions: string[] = options as string[];
                            let details: { name: string; checked: boolean }[] =
                              [];
                            for (let i = 0; i < typedOptions.length; i++) {
                              details.push({
                                name: typedOptions[i],
                                checked: false,
                              });
                            }
                            temp.push({
                              relative_title: title,
                              possible_title: [],
                              title: title,
                              details: details,
                            });
                          }
                        );
                        setOptions([...temp]);
                      }}
                    >
                      <AiOutlineSearch className="text-lg" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Price</td>
                <td className=" w-full">
                  <div className=" w-full p-2 flex justify-start items-center gap-2">
                    <input
                      ref={priceInputField}
                      type="text"
                      disabled
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td>Options</td>
                <td>
                  <div className=" w-full h-fit flex flex-col justify-center items-center pr-2">
                    {options.map((item, index) => {
                      return (
                        <div
                          key={`option-item-${index}`}
                          className=" w-full h-fit flex flex-row justify-between items-start gap-10"
                        >
                          <div className=" w-full flex flex-col justify-between items-start">
                            <div
                              className=" w-full flex justify-start items-center gap-2 font-semibold mb-2"
                              style={{
                                backgroundColor: colors["tb-head"],
                                color: colors["tb-head-text"],
                              }}
                            >
                              <AiFillCaretRight />
                              <p>{item.title}</p>
                            </div>

                            {item.details.length > 0 ? (
                              item.details.map((detail, dIndex) => {
                                let bgColor = colors["tb-row-1-h"];
                                if (dIndex % 2 === 0)
                                  bgColor = colors["tb-row-2-h"];
                                return (
                                  <div
                                    key={`option-detail-${dIndex}`}
                                    className=" w-full flex justify-between items-center gap-2"
                                    style={{
                                      background: bgColor,
                                    }}
                                  >
                                    <div className=" flex justify-start items-center gap-2">
                                      <input
                                        type="checkbox"
                                        checked={detail.checked}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        onChange={(e) => {
                                          let temp = options;
                                          temp[index].details[dIndex].checked =
                                            e.target.checked;

                                          setOptions([...temp]);
                                        }}
                                      />
                                      <p>{detail.name}</p>
                                    </div>
                                  </div>
                                );
                              })
                            ) : item.title === "" ? (
                              <></>
                            ) : (
                              <div className=" w-full h-fit flex flex-col justify-center items-center bg-[#f6f8c3] p-2 rounded-md mb-2">
                                <AiOutlineWarning />
                                <p>Nothing to select in this option.</p>
                                <p>
                                  Try to add fields with the input bar above
                                  first.
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div className="w-full flex justify-end items-center mt-5">
                      <div
                        className=" p-2 hover:text-[#a9abc6] hover:cursor-pointer"
                        style={{
                          backgroundColor:
                            modelIdInputField.current?.value === ""
                              ? colors["tb-row-text-d"]
                              : colors["tb-head"],
                          color: colors["tb-head-text"],
                        }}
                        onClick={() => {
                          if (modelIdInputField.current!.value === "") return;
                          let temp: OptionProps = {
                            relative_title: "",
                            possible_title: [],
                            title: "",
                            details: [],
                          };
                          setOptions([...options, temp]);
                        }}
                      >
                        <AiOutlinePlus />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td>
                  <select
                    className=" m-2.5 text-black"
                    defaultValue={"total"}
                    onChange={(e) => {
                      setAmountType(e.target.value);
                      setCustomAmounts([
                        {
                          conditions: [],
                          details: [{ amount: 0, warehouse: "" }],
                        },
                      ]);
                    }}
                  >
                    <option value="total">Total Quantity</option>
                    <option value="custom">Custom Quantity</option>
                  </select>
                </td>
                <td>
                  {amountType === "total" ? (
                    <div className=" w-full h-fit flex flex-col justify-center items-center p-2 gap-5">
                      {options.length === 0 ? (
                        <></>
                      ) : (
                        <div
                          className=" w-full h-fit flex flex-col justify-center items-center p-2 rounded-md"
                          style={{
                            backgroundColor: colors["tb-head"],
                          }}
                        >
                          <AiOutlineExclamation />
                          <p>Options are disabled using Total Amount.</p>
                        </div>
                      )}

                      {customAmounts.map((item, index) => {
                        return (
                          <div
                            key={`total-amount-item-${index}`}
                            className=" w-full flex flex-col justify-center items-center"
                          >
                            <div
                              className=" relative p-1 w-full flex justify-start items-center gap-2"
                              style={{
                                backgroundColor: colors["tb-head"],
                                color: colors["tb-head-text"],
                              }}
                            >
                              <AiFillCaretRight />
                              <p>Quantity</p>
                            </div>
                            <input
                              value={customAmounts[index].details[0].amount}
                              type="number"
                              className="border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              onChange={(e) => {
                                let temp = customAmounts;
                                temp[index].details[0].amount = parseFloat(
                                  e.target.value
                                );

                                setCustomAmounts([...temp]);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : options.length > 0 ? (
                    <div className="w-full h-fit flex flex-col justify-center items-center pr-2">
                      <div className="w-full flex flex-col justify-start items-center gap-2 mb-2">
                        {customAmounts.map((item, index) => {
                          return (
                            <div
                              key={`custom-amount-item-${index}`}
                              className="relative w-full flex flex-col justify-center items-center"
                            >
                              <div
                                className="w-full flex justify-start items-center gap-2 p-1"
                                style={{
                                  backgroundColor: colors["tb-head"],
                                  color: colors["tb-head-text"],
                                }}
                              >
                                <AiFillCaretRight />
                                <p>Quantity</p>
                              </div>

                              <div
                                className=" w-full flex flex-row justify-start items-center gap-2 pl-3"
                                style={{
                                  backgroundColor: colors["tb-row-1"],
                                  color: colors["tb-row-1-text"],
                                }}
                              >
                                <p>For</p>

                                {item.conditions.map((option, cIndex) => {
                                  let isEmpty = true;

                                  return (
                                    <div
                                      key={`custom-amount-select-${index}-${cIndex}`}
                                      className=" w-fit flex relative justify-center items-center gap-1"
                                    >
                                      <select
                                        value={
                                          customAmounts[index].conditions[
                                            cIndex
                                          ].option
                                        }
                                        style={{
                                          backgroundColor: colors["tb-head"],
                                          color: colors["tb-head-text"],
                                        }}
                                        onChange={(e) => {
                                          let temp = customAmounts;

                                          temp[index].conditions[
                                            cIndex
                                          ].option = e.target.value;
                                          setCustomAmounts([...temp]);
                                        }}
                                      >
                                        <option value=""></option>
                                        {options.map((option, oIndex) => {
                                          return (
                                            <option value={option.title}>
                                              {option.title}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <p>=</p>
                                      {item.conditions[cIndex].option === "" ||
                                      options.findIndex(
                                        (e) =>
                                          e.title ===
                                          item.conditions[cIndex].option
                                      ) === -1 ? (
                                        <p className=" italic">NULL</p>
                                      ) : (
                                        <select
                                          value={
                                            customAmounts[index].conditions[
                                              cIndex
                                            ].name
                                          }
                                          style={{
                                            backgroundColor: colors["tb-head"],
                                            color: colors["tb-head-text"],
                                          }}
                                          onChange={(e) => {
                                            let temp = customAmounts;

                                            temp[index].conditions[
                                              cIndex
                                            ].name = e.target.value;
                                            setCustomAmounts([...temp]);
                                          }}
                                        >
                                          <option value=""></option>
                                          {options[
                                            options.findIndex(
                                              (e) =>
                                                e.title ===
                                                item.conditions[cIndex].option
                                            )
                                          ].details.map((name, nIndex) => {
                                            if (name.checked === true) {
                                              isEmpty = false;
                                              return (
                                                <option value={name.name}>
                                                  {name.name}
                                                </option>
                                              );
                                            }
                                          })}
                                          {isEmpty ? (
                                            <option value="">NULL</option>
                                          ) : (
                                            <></>
                                          )}
                                        </select>
                                      )}
                                      {cIndex < item.conditions.length - 1 ? (
                                        <p>&</p>
                                      ) : (
                                        <></>
                                      )}

                                      <AiOutlineClose
                                        className=" absolute -left-1 -top-1 text-base p-1 bg-[#e17497] rounded-full opacity-0 hover:opacity-100 hover:cursor-pointer"
                                        onClick={() => {
                                          let temp = customAmounts;
                                          temp[index].conditions.splice(
                                            cIndex,
                                            1
                                          );

                                          setCustomAmounts([...temp]);
                                        }}
                                      />
                                    </div>
                                  );
                                })}

                                <div
                                  className=" p-2 w-5 h-5 flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer"
                                  style={{
                                    backgroundColor: colors["tb-head"],
                                    color: colors["tb-head-text"],
                                  }}
                                  onClick={() => {
                                    let temp = customAmounts;
                                    temp[index].conditions.push({
                                      option: "",
                                      name: "",
                                    });

                                    setCustomAmounts([...temp]);
                                  }}
                                >
                                  <p>&</p>
                                </div>
                                <p>,</p>
                              </div>

                              <div className=" w-full h-fit">
                                {item.details.map((detail, dIndex) => {
                                  return (
                                    <div
                                      key={`custom-amount-${index}-${dIndex}`}
                                      className=" p-1 relative w-full flex flex-col justify-start items-start gap-1 mb-5"
                                      style={{
                                        backgroundColor: colors["tb-row-1"],
                                        color: colors["tb-row-text"],
                                      }}
                                    >
                                      <input
                                        type="number"
                                        value={
                                          customAmounts[index].details[dIndex]
                                            .amount
                                        }
                                        className="border-[2px] w-2/3 border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-1"
                                        onChange={(e) => {
                                          let temp = customAmounts;
                                          temp[index].details[dIndex].amount =
                                            parseFloat(e.target.value);

                                          setCustomAmounts([...temp]);
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className=" w-full h-fit flex flex-col justify-center items-center p-2 bg-[#f6f8c3] text-black rounded-md">
                      <AiOutlineWarning />
                      <p>No option added.</p>
                      <p>Try to add options before setting custom amount.</p>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="w-full overflow-hidden whitespace-nowrap text-left rounded-tl-md rounded-tr-md mt-5"
          style={{
            backgroundColor: colors["tb-head"],
            color: colors["tb-head-text"],
          }}
        >
          <div className=" w-full inline-flex justify-between items-center p-2">
            <p className=" font-semibold">Insert Custom Item</p>
            <div className=" w-fit flex justify-center items-center gap-5">
              <AiFillPlusSquare
                className=" text-2xl hover:cursor-pointer"
                onClick={() => {
                  let temp = items;
                  temp.push({
                    model_id: "",
                    name: custom_nameInputField.current!.value,
                    style: "",
                    price: parseFloat(custom_priceInputField.current!.value),
                    quantity: 1,
                    is_custom: 1,
                    style_code: 0
                  });
                  setItems([...temp]);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className=" w-full p-2 grid grid-cols-5 text-center items-center"
          style={{
            color: colors["tb-row-text"],
            backgroundColor: colors["tb-row-1"],
          }}
        >
          <p className=" col-span-1">Name</p>
          <input
            ref={custom_nameInputField}
            className=" col-span-4 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div
          className=" w-full p-2 grid grid-cols-5 text-center items-center"
          style={{
            color: colors["tb-row-text"],
            backgroundColor: colors["tb-row-2"],
          }}
        >
          <p className=" col-span-1">Price</p>
          <input
            ref={custom_priceInputField}
            className=" col-span-4 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div
          className="w-full overflow-hidden whitespace-nowrap text-left rounded-tl-md rounded-tr-md mt-5"
          style={{
            backgroundColor: colors["tb-head"],
            color: colors["tb-head-text"],
          }}
        >
          <p className=" p-2 font-semibold">Sale Order</p>
        </div>

        <div
          className=" w-full p-2 grid grid-cols-6 text-center"
          style={{
            backgroundColor: colors["tb-row-1"],
            color: colors["tb-row-text"],
          }}
        >
          <p>Model Id</p>
          <p className=" col-span-2">Name</p>
          <p>Style</p>
          <p>Quantity</p>
          <p>Price</p>
        </div>

        {items.map((item, index) => {
          let bg = colors["tb-row-1"];
          if (index % 2 === 0) bg = colors["tb-row-2"];
          return (
            <div
              key={`item-${index}`}
              className=" relative w-full p-2 grid grid-cols-6 text-center"
              style={{
                backgroundColor: bg,
                color: colors["tb-row-text"],
              }}
            >
              <p>{item.model_id}</p>
              <p className=" col-span-2">{item.name}</p>
              <p>{item.style}</p>
              <div className="inline-flex justify-center">
                <input
                  type="number"
                  className=" p-1 border border-zinc-800 rounded-md text-black min-w-0 w-1/2 text-center"
                  value={item.quantity}
                  onChange={(e) => {
                    let val = parseInt(e.target.value);
                    if (val < 0) val = 0;
                    let temp = items;
                    temp[index].quantity = val;
                    setItems([...temp]);
                  }}
                />
              </div>
              <p>{item.price}</p>
              <div
                className=" absolute right-0 cursor-pointer"
                onClick={() => {
                  let temp = items;
                  temp.splice(index, 1);
                  setItems([...temp]);
                }}
              >
                <AiOutlineClose />
              </div>
              {item.is_custom === 1 ? (
                <div className=" absolute left-0">
                  <FaRegStar />
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}

        <hr className=" mt-5 w-full border" />

        <div
          className=" w-full inline-flex justify-between items-center"
          style={{
            color: colors["text"],
          }}
        >
          <p className=" font-semibold text-xl">Total</p>
          <p>{`$ ${totalPrice.toFixed(1)} HKD`}</p>
        </div>
      </div>
    </div>
  );
}
