import { useEffect, useRef, useState } from "react";
import { AiFillCaretRight, AiFillDelete, AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai"
import {GoIssueClosed} from 'react-icons/go';
import{GrClose} from 'react-icons/gr';
import { useNavigate } from "react-router-dom";
import { post } from "../utils/post";
import { groupBy } from "../utils/groupBy";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { motion } from "framer-motion";
import { saveInventoriesExcel } from "../utils/report/saveInventoriesExcel";
import { useTabStore } from "../utils/zustand/useTabStore";


export function Inventory(props: {permission: any}){

    const navigate = useNavigate();

    const {account} = useAccountStore();
    const {colors} = useStyleStore();
    const {goTab, all_tabs} = useTabStore();

    const selectAllheckbox = useRef<HTMLInputElement | null>(null);

    const [items, setItems] = useState<Record<string, {checked: boolean, modelId: string, style: string, name: string, stocks: {warehouse: string, quantity: number}[]}>>({});

    const selectedItems = useRef<Record<string, {checked: boolean, modelId: string, style: string, name: string, stocks: {warehouse: string, quantity: number}[]}>>({});

    const totalLength = useRef<number>(0);
    const totalPage = useRef<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const modelIdInputField = useRef<HTMLInputElement | null>(null);
    const styleInputField = useRef<HTMLInputElement | null>(null);
    const nameInputField = useRef<HTMLInputElement | null>(null);

    useEffect(() =>{
        const f = async () =>{
            let length = await post('getLength', {
                name: 'inventories',
                where: `\`group_id\` = '${account.current_group}'`
            });
            totalLength.current = length.data;
            totalPage.current = Math.ceil(totalLength.current / 20);

            setCurrentPage(1);
        }
        f();
    }, [])

    const triggerPage = (page: number) =>{
        if (page === currentPage)
            refreshPage(page);
        else
            setCurrentPage(page);
    }

    const refreshPage = async(page: number) =>{
        let result = await post('getAllInventories', {
            page: page,
            group_id: account.current_group,
            modelId: modelIdInputField.current!.value,
            style: styleInputField.current!.value,
            name: nameInputField.current!.value,
        });

        if (result.status != 1)
            return;

        
        let temp: typeof items = {};
        Object.values(result.inventories).forEach((item: any) =>{
            let key = item.model_id + item.style_code;
            if (!temp[key])
                temp[key] = {
                    checked: selectedItems.current[key] ? true : false,
                    modelId: item.model_id,
                    style: result.style_code_ref[parseInt(item.style_code)],
                    name: result.model_ref[item.model_id],
                    stocks: []
                };
            temp[key].stocks.push({
                warehouse: item.warehouse,
                quantity: parseInt(item.quantity)
            });
        })

        setItems({...temp});
    }

    useEffect(() =>{
        if (totalPage.current === 0)
        return;

        refreshPage(currentPage)
    }, [currentPage])

    return (
        <div className=" w-full h-fit flex flex-col justify-center items-center">

            <div className=" mt-5 w-full lg:w-2/3 h-fit flex justify-end items-end">
                <motion.div className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                    initial={{scale: 1}}
                    whileHover={{scale: 1.2}}
                    onClick={async() =>{
                        let arr = Object.values(selectedItems.current);
                        if (arr.length === 0)
                            return;

                        saveInventoriesExcel(account.username, arr);
                    }}
                >
                    Export Excel
                </motion.div>
            </div>

            <div className=" mt-2 w-full lg:w-2/3 h-fit flex justify-end items-end text-sm md:gap-10 flex-col md:flex-row gap-1"
                style={{
                    color: colors['text']
                }}
            >
                <div className=" flex flex-col justify-center items-start">
                    <p>Model Id</p>
                    <input ref={modelIdInputField} type="text" className=" border-2 h-6 text-black border-gray-200 rounded-md p-1 min-w-0 w-20" />
                </div>
                <div className=" flex flex-col justify-center items-start">
                    <p>Style</p>
                    <input ref={styleInputField} type="text" className=" border-2 h-6 text-black border-gray-200 rounded-md p-1 min-w-0 w-20" />
                </div>
                <div className=" flex flex-col justify-center items-start">
                    <p>Name</p>
                    <input ref={nameInputField} type="text" className=" border-2 h-6 text-black border-gray-200 rounded-md p-1 min-w-0 w-20" />
                </div>
                <div className=" p-2 hover:cursor-pointer"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                    onClick={async() =>{
                        triggerPage(1);
                    }}>
                    <AiOutlineSearch className="text-lg" />
                </div>
            </div>

            <div className="mt-2 w-full lg:w-2/3 h-fit">
                <table className="table-auto whitespace-nowrap w-full">
                    <thead style={{color: colors['tb-head-text']}}>
                        <tr>
                            <th>
                                <div className=" rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]"
                                    style={{backgroundColor: colors['tb-head']}}
                                >
                                    <input ref={selectAllheckbox} type="checkbox" className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" 
                                        onChange={(e) =>{
                                            let temp = items;
                                            Object.values(temp).forEach((t) =>{
                                                t.checked = selectAllheckbox.current!.checked;

                                                let id = t.modelId + t.style;
                                                if (selectAllheckbox.current!.checked){
                                                    selectedItems.current[id] = t;
                                                } else {
                                                    delete selectedItems.current[id];
                                                }
                                            })
                                            setItems({...temp});
                                        }} />
                                </div>
                            </th>
                            <th className="text-start">
                                <div className=" flex justify-center items-center gap-2 pl-2 pr-2"
                                    style={{backgroundColor: colors['tb-head']}}
                                >
                                    <p>Model Id</p>
                                </div>
                            </th>
                            <th className="text-start">
                                <div className=" flex justify-center items-center gap-2"
                                    style={{backgroundColor: colors['tb-head']}}
                                >
                                    <p>Style</p>

                                </div>
                            </th>
                            <th className="text-start">
                                <div className=" flex justify-center items-center gap-2"
                                    style={{backgroundColor: colors['tb-head']}}
                                >
                                    <p>Name</p>
                                </div>
                            </th>
                            <th className="text-start">
                                <div className=" flex justify-center items-center gap-2 pl-2 pr-2"
                                    style={{backgroundColor: colors['tb-head']}}
                                >
                                    <p>Quantity</p>
                                </div>
                            </th>
                        </tr> 
                    </thead>
                    <tbody style={{color: colors['tb-row-text']}}>
                        {
                            Object.entries(items).map(([key, item], index) =>{
                                let bgColor = colors['tb-row-1'];
                                if (index % 2 === 0)
                                    bgColor = colors['tb-row-2'];

                                let stockTotal = 0;
                                if (item.stocks.length > 1){
                                    for(let i = 0; i < item.stocks.length; i++){
                                        stockTotal += item.stocks[i].quantity;
                                    }
                                }

                                return (
                                    <tr key={`stockin-${index}`} className=" text-center"
                                        style={{
                                            backgroundColor: bgColor
                                        }}>
                                        <td>
                                            <div className="flex justify-center items-center h-[24px]">
                                                <input type="checkbox" checked={item.checked} className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" 
                                                    onChange={(e) =>{
                                                        let temp = items;
                                                        temp[key].checked = e.target.checked;
                                                        setItems({...temp});

                                                        if (e.target.checked){
                                                            selectedItems.current[key] = temp[key];
                                                        } else {
                                                            delete selectedItems.current[key];
                                                        }
                                                        let allChecked: boolean = true;
                                                        Object.values(temp).forEach((t) =>{
                                                            if (!t.checked){
                                                                allChecked = false;
                                                            }
                                                        })
                                                        selectAllheckbox.current!.checked = allChecked;
                                                }} />
                                            </div>
                                        </td>
                                        <td className=" text-left">
                                            <p className="w-fit hover:cursor-pointer hover:underline"
                                                onClick={() =>{
                                                    if (props.permission['o-inv']){
                                                        goTab(`inventory ${item.modelId}`, `/dashboard/inventory-view/${item.modelId}`, all_tabs);
                                                        navigate(`/dashboard/inventory-view/${item.modelId}`)
                                                    }
                                                }}>
                                                {item.modelId}
                                            </p>
                                        </td>
                                        <td>
                                                <p>{item.style}</p>
                                        </td>
                                        <td>
                                            <p>{item.name}</p>
                                        </td>
                                        <td>
                                            <div className=" flex flex-col justify-center items-start">
                                            {
                                                item.stocks.map((stock, sIndex) =>{
                                                    let bgColor = colors['tb-row-1-h'];
                                                    if (sIndex % 2 === 0)
                                                        bgColor = colors['tb-row-2-h'];
                                                    return (
                                                        <div key={`stock-${index}-${sIndex}`} className="w-full flex justify-between items-center pr-5"
                                                            style={{
                                                                backgroundColor: bgColor
                                                            }}
                                                        >
                                                            <div className=" flex justify-center items-center">
                                                                <AiFillCaretRight />
                                                                <p>{stock.warehouse}</p>
                                                            </div>
                                                            <p>{stock.quantity}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                item.stocks.length > 1 ? 
                                                <div key={`stock-total-${index}`} className=" w-full flex justify-between items-center pr-5"
                                                    style={{
                                                        backgroundColor: colors['tb-head'],
                                                        color: colors['tb-head-text']
                                                    }}
                                                >
                                                    <div className=" flex justify-center items-center">
                                                        <AiFillCaretRight />
                                                        <p>Total</p>
                                                    </div>
                                                    <p>{stockTotal}</p>  
                                                </div> : <></>
                                            }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div className='w-full h-fit flex flex-row justify-center items-center gap-5 mt-5'
                    style={{
                        color: colors['text']
                    }}
                >
                    {
                        currentPage - 1 > 0 ? <AiOutlineDoubleLeft className=' text-lg hover:cursor-pointer' 
                            onClick={() =>{
                                setCurrentPage(currentPage - 1);
                            }}/> : <></>
                    }
                    <p>{currentPage}</p>
                    {
                        currentPage + 1 > totalPage.current ? <></> : <AiOutlineDoubleRight className=' text-lg hover:cursor-pointer'
                            onClick={() =>{
                                setCurrentPage(currentPage + 1);
                            }} />
                    }
                </div>

            </div>

        </div>
    )
}