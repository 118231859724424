import React from 'react';
import { Login } from './basic/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Dashboard } from './basic/Dashboard';
import { Auth } from './basic/Auth';


function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>

        <Route index element={<Login />} />
        <Route path='/auth' element={<Auth />} />
        <Route path='/dashboard/:category?/:name?' element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
