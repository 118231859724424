import { useNavigate, useParams } from "react-router-dom";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useEffect, useState } from "react";
import { post } from "../utils/post";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { CiCoins1 } from "react-icons/ci";
import { AiOutlineSwapLeft } from "react-icons/ai";
import { motion } from "framer-motion";
import { saveBalanceExcel } from "../utils/report/saveBalanceExcel";


export function ViewBalance() {

    const {colors} = useStyleStore();
    const {account} = useAccountStore();

  const navigate = useNavigate();

    const {name} = useParams();

    const [timespan, setTimespan] = useState<string>('');
    const [timeframe, setTimeframe] = useState<{start: string, end: string}>({start: '', end: ''});
    const [date, setDate] = useState<string>('');

    const [items, setItems] = useState<any[]>([]);

    const [total, setTotal] = useState<number>(0);

    useEffect(() =>{
        if (!name)
            return;

        const params: string[] = name.split('&');
        for(let i = 0; i < params.length; i++){
            const param: string[] = params[i].split('=');
            if (param.length !== 2)
                break;
            let key = param[0];
            let val = param[1];

            switch (key){
                case 'timespan': {
                    setTimespan(val);
                    break;
                }
                case 'start': {
                    setTimeframe({
                        ...timeframe,
                        start: val
                    });
                    break;
                }
                case 'end': {
                    setTimeframe({
                        ...timeframe,
                        end: val
                    });
                    break;
                }
                case 'date': {
                    setDate(val);
                    break;
                }
            }
        }
    }, [name])

    useEffect(() =>{
        if (!timespan || !date)
            return;

        const f = async() =>{
            let result = await post('getBalance', {
                group_id: account.current_group,
                timespan: timespan,
                start: timeframe.start,
                end: timeframe.end,
                date: date
            })

            let temp: typeof items = [];
            let _total = 0;
            Object.values(result.data).forEach((item: any) =>{
              temp.push(item);
              _total += item.price;
            })
            setItems([...temp]);
            setTotal(_total);
        };
        f();
    }, [timespan, timeframe, date])

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">

      <div className=" w-full lg:w-2/3 h-fit flex flex-col justify-end items-center mt-5">

        <div className="w-full h-fit flex flex-row justify-end items-center gap-5">
          <motion.div className=" w-fit h-fit flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
              style={{
                  backgroundColor: colors['tb-head'],
                  color: colors['tb-head-text']
              }}
              initial={{scale: 1}}
              whileHover={{scale: 1.2}}
              onClick={async() =>{
                if (!timespan)
                  return;
                if (!timeframe)
                  return;
                if (!date)
                  return;
                let t = timespan.charAt(0).toUpperCase() + timespan.slice(1);
                let filename = `${t} Balance Report - `;
                if (timeframe.start && timeframe.end){
                  filename += `${timeframe.start} to ${timeframe.end}`;
                } else if (timeframe.start){
                  filename += `${timeframe.start}`;
                } else {
                  filename += `${timeframe.end}`;
                }
                saveBalanceExcel(account.username, filename, t, items);
              }}
          >
              {`Export ${timespan.charAt(0).toUpperCase() + timespan.slice(1)} Excel`}
          </motion.div>
        </div>

        <div className="w-full h-fit flex flex-col justify-center items-center">
          <div
            className="w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2 mt-5"
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <p className=" font-semibold">{`${timespan.charAt(0).toUpperCase() + timespan.slice(1)} Balance Report`}</p>
            <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
              onClick={() =>{
                  navigate('/dashboard/balance');
              }}>
              <AiOutlineSwapLeft className=" text-lg " />
              </div>
          </div>
        </div>

        <table
          className="w-full table-auto whitespace-nowrap text-center"
          style={{
            color: colors["tb-row-text"],
          }}
        >
          <thead>
            <tr className="font-semibold"
              style={{
                backgroundColor: colors['tb-row-2']
              }}
            >
              <td className=" text-start pl-2">Order Id</td>
              <td>Creation Date</td>
              <td>Price</td>
            </tr>
          </thead>
          <tbody>
            {
              items.map((item, index) =>{
                return (
                  <tr key={`tb-${index}`}
                    style={{
                      backgroundColor: index % 2 === 0 ? colors['tb-row-1'] : colors['tb-row-2']
                    }}
                  >
                    <td>
                      <div className="w-fit pl-2 font-semibold select-none cursor-pointer hover:underline text-start"
                        onClick={() =>{
                          navigate(`/dashboard/order-view/${item.order_id}`);
                        }}
                      >
                        {item.order_id}
                      </div>
                    </td>
                    <td>
                      <p>{item.created_at}</p>
                    </td>
                    <td>
                      <p>${item.price.toFixed(1)}</p>
                    </td>
                  </tr>
                )
              })
            }
            <tr>
              <td />
              <td />
              <td>
                <hr className=" mt-5" style={{borderColor: colors['text']}}/>
              </td>
            </tr>
            <tr>
              <td className=" pl-2 text-start font-semibold">
                  <div className=" inline-flex gap-2 justify-center items-center">
                    <CiCoins1 />
                    <p>Total</p>
                  </div>
                </td>
              <td></td>
              <td className=" font-semibold">${total.toFixed(1)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
