import { useEffect, useRef, useState } from 'react';
import {AiFillEdit, AiFillDelete, AiOutlinePlus, AiOutlineDoubleLeft, AiOutlineDoubleRight} from 'react-icons/ai';
import {MdArrowCircleRight} from 'react-icons/md';
import { post } from '../utils/post';
import { useNavigate } from 'react-router-dom';
import { useAccountStore } from '../utils/zustand/useAccountStore';

export function Categories(props: {permission: any}){

    const [categoryList, setCategoryList] = useState<{name: string, checked: boolean, path: string[]}[]>([]);

    const {account} = useAccountStore();

    const totalLength = useRef<number>(0);
    const totalPage = useRef<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const selectAllheckbox = useRef<HTMLInputElement | null>(null);

    const navigate = useNavigate();

    useEffect(() =>{
        const f = async () =>{
            let length = await post('getLength', {
                name: 'categories'
            });
            totalLength.current = length.data;
            totalPage.current = Math.ceil(totalLength.current / 10);

            setCurrentPage(1);
        }
        f();

    }, [])

    useEffect(() =>{
        if (totalPage.current === 0)
        return;

        selectAllheckbox.current!.checked = false;

        const f = async() =>{
            let data = await post('getAllCategories', {
                page: currentPage
            });

            if (data.status != 1)
            return;

            let temp: {name: string, checked: boolean, path: string[]}[] = [];
            Object.entries(data.data).forEach(([key, item], index) =>{
                let dirs = item as string[];
                temp.push({
                    name: dirs[dirs.length - 1],
                    checked: false,
                    path: dirs
                })
            })
    
            setCategoryList([...temp]);
        }
        f();

    }, [currentPage])

    return (
        <div className=" w-full h-fit flex flex-col justify-center items-center">

            <div className='w-full lg:w-2/3 h-fit mt-5 flex flex-row justify-end items-center text-[#e9eaee] text-lg gap-2'>

                {
                    props.permission['a-cate'] ?
                    <div className=' w-10 h-10 bg-[#30323f] rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer'
                    onClick={() =>{
                        if (props.permission['a-cate'])
                        navigate('/dashboard/add-category');
                    }}>
                    <AiOutlinePlus />
                    </div> : <></>
                }

                {
                    props.permission['d-cate'] ?
                    <div className=' w-10 h-10 bg-[#30323f] rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer'
                        onClick={async (e) => {
                            if (!props.permission['d-cate'])
                                return;

                            let t = e.currentTarget;
                            t.style.pointerEvents = 'none';

                            let checked: string[] = [];
                            for(let i = 0; i < categoryList.length; i++){
                                if (categoryList[i].checked){
                                    checked.push(categoryList[i].name);
                                }
                            }

                            if (checked.length > 0){
                                await post('deleteCategoriesReq', {
                                    checked: checked
                                })
                                navigate(0);
                            } else {
                                alert('No category selected.');
                            }

                            t.style.pointerEvents = 'auto';
                        }}>
                        <AiFillDelete />
                    </div> : <></>
                }

            </div>

            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                <div className=' w-full h-fit flex flex-row justify-around items-center'>

                    <div className=" w-full h-fit bg-[#56596c] flex flex-row justify-start items-center gap-5 rounded-tl-md">
                        <div className=" w-10 h-10 flex justify-center items-center border-r-[2px]"
                            style={{
                                borderRightColor: '#FBFBFB'
                            }}>
                            <input ref={selectAllheckbox} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                onChange={(e) =>{
                                    let temp = categoryList;
                                    for(let i = 0; i < temp.length; i++){
                                        temp[i].checked = e.target.checked;
                                    }
                                    setCategoryList([...temp]);

                                }}/>
                        </div>
                        <p className=' text-white'>Category Name</p>
                    </div>

                    <div className='bg-[#30323f] w-10 h-10 rounded-tr-md'/>

                </div>
                
                {
                    categoryList.map((item, index) =>{
                        let bgColor = '#F5F3F9';
                        let borderColor = '#FBFBFB';
                        if (index % 2 === 0)
                            bgColor = '#ECE9F1';
                        if (item.checked){
                            bgColor = '#F9E5BE';

                            if (index % 2 === 0)
                                bgColor = '#F9E5BE';
                            else
                                bgColor = '#F7F1B3';

                            borderColor = '#C3C1DA';
                        }

                        return (
                            <div key={`category-item-${index}`} className=" w-full h-fit flex flex-row justify-around items-center">

                                <div className=" w-full h-fit flex flex-row justify-start items-center gap-5"
                                    style={{
                                        backgroundColor: bgColor
                                    }}>
                                    <div className=" w-11 h-10 flex justify-center items-center border-r-[2px]"
                                        style={{
                                            borderRightColor: borderColor
                                        }}>
                                        <input type="checkbox" checked={item.checked} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        onChange={(e) =>{
                                            let temp = categoryList;
                                            temp[index].checked = e.target.checked;
                                            setCategoryList([...temp]);

                                            let checkedItems = 0;
                                            for(let i = 0; i < temp.length; i++){
                                                if (temp[i].checked)
                                                    checkedItems++;
                                            }

                                            if (checkedItems === temp.length){
                                                selectAllheckbox.current!.checked = true;
                                            } else{
                                                selectAllheckbox.current!.checked = false;
                                            }
                                        }}/>
                                    </div>
                                    <div className=' w-full flex flex-row justify-start items-center gap-5 flex-wrap'>
                                        {
                                            item.path.map((pathItem, pathIndex) =>{
                                                let isName: boolean = item.name === pathItem;
                                                return (
                                                    <div key={`path-item-${pathIndex}`} className=' w-fit flex flex-row items-center justify-start gap-5'
                                                        style={{
                                                            color: isName ? '#615674' : '#7E8A99',
                                                        }}>
                                                        <p style={{
                                                            fontWeight: isName ? 500 : 400
                                                        }}>
                                                            {pathItem}
                                                        </p>
                                                        {isName ? <></> : <MdArrowCircleRight className=' text-sm text-[#A39CAF]' />}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                
                                {
                                    props.permission['a-cate'] ?
                                    <div className=" bg-[#30323f] w-10 h-10 flex justify-center items-center text-lg text-[#e9eaee] hover:text-[#a9abc6] hover:cursor-pointer"
                                        onClick={() =>{
                                            if (props.permission['a-cate'])
                                            navigate(`/dashboard/modify-category/${item.name}`);
                                        }}>
                                        <AiFillEdit />
                                    </div> :
                                    <></>
                                }

                            </div>
                        )
                    })
                }

                <div className='w-full h-fit flex flex-row justify-center items-center gap-5 mt-5'>
                    {
                        currentPage - 1 > 0 ? <AiOutlineDoubleLeft className=' text-lg hover:cursor-pointer' 
                            onClick={() =>{
                                setCurrentPage(currentPage - 1);
                            }}/> : <></>
                    }
                    <p>{currentPage}</p>
                    {
                        currentPage + 1 > totalPage.current ? <></> : <AiOutlineDoubleRight className=' text-lg hover:cursor-pointer'
                            onClick={() =>{
                                setCurrentPage(currentPage + 1);
                            }} />
                    }
                </div>

            </div>

        </div>
    )
}