import { AiFillDelete, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/post";
import { useEffect, useRef, useState } from "react";
import { useAccountStore } from "../utils/zustand/useAccountStore";

export function Balance(props: { permission: any }) {
  const { colors } = useStyleStore();
  const { account } = useAccountStore();
  const navigate = useNavigate();
  const selectAllheckbox = useRef<HTMLInputElement | null>(null);

    const [timespan, setTimespan] = useState<string>('daily');
    const [timeframe, setTimeframe] = useState<{start: string, end: string}>({start: '', end: ''});

  const [items, setItems] = useState<{ checked: boolean; created_at: string, price: number }[]>(
    []
  );

  const totalLength = useRef<number>(0);
  const totalPage = useRef<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const f = async () => {
      let length = await post("getLength", {
        name: "order_remarks",
        where: `\`group_id\` = '${account.current_group}' AND \`status\` = 'finished'`,
      });
      totalLength.current = length.data;
      totalPage.current = Math.ceil(totalLength.current / 10);

      setCurrentPage(1);
    };
    f();
  }, []);

  function updateItems(result: any){
    if (result.status != 1) return;

    let temp: typeof items = [];
    Object.values(result.data).forEach((item: any) => {
      temp.push({
        checked: false,
        created_at: item.created_at,
        price: item.price
      });
    });
    setItems([...temp]);
  }

  useEffect(() => {
    if (totalPage.current === 0) return;

    const f = async () => {
      let result = await post("getAllBalances", {
        group_id: account.current_group,
        page: currentPage,
        timespan: timespan,
        start: timeframe.start,
        end: timeframe.end
      });
      updateItems(result);
    };
    f();
  }, [currentPage]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">

        <div className=" mt-5 w-full lg:w-2/3 h-fit flex flex-col md:flex-row justify-end items-end gap-2 p-1"
            style={{
                color: colors['text']
            }}
        >
            <p>Timespan</p>
            <select value={timespan} className=" p-1 border border-zinc-800 text-black bg-white"
                onChange={(e) =>{
                    setTimespan(e.target.value);
                }}
            >
                <option value='daily'>Daily</option>
                <option value='weekly'>Weekly</option>
                <option value='monthly'>Monthly</option>
                <option value='yearly'>Yearly</option>
            </select>

            <p>Timeframe</p>
            <input value={timeframe.start} className=" p-1 border border-zinc-800 bg-white text-black w-[150px]" placeholder="YYYY-MM-DD"
                onChange={(e) =>{
                    setTimeframe({
                        ...timeframe,
                        start: e.target.value
                    });
                }}
            />
            <p>to</p>
            <input value={timeframe.end} className=" p-1 border border-zinc-800 bg-white text-black w-[150px]" placeholder="YYYY-MM-DD"
                onChange={(e) =>{
                    setTimeframe({
                        ...timeframe,
                        end: e.target.value
                    });
                }}
            />
            <div className=" p-1 text-2xl select-none cursor-pointer hover:opacity-80"
                style={{
                    backgroundColor: colors['tb-head'],
                    color: colors['tb-head-text']
                }}
                onClick={async() =>{
                    let result = await post("getAllBalances", {
                        group_id: account.current_group,
                        page: currentPage,
                        timespan: timespan,
                        start: timeframe.start,
                        end: timeframe.end
                      });
                      updateItems(result);
                }}
            >
                <AiOutlineSearch />
            </div>
        </div>

      <div className=" mt-5 w-full lg:w-2/3 h-fit">
        <table className=" table-auto  whitespace-nowrap">
          <thead style={{ color: colors["tb-head-text"] }}>
            <tr>
              <th>
                <div
                  className=" rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]"
                  style={{
                    backgroundColor: colors["tb-head"],
                  }}
                >
                  <input
                    ref={selectAllheckbox}
                    type="checkbox"
                    className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    onChange={(e) => {
                      let temp = items;
                      for (let i = 0; i < temp.length; i++) {
                        temp[i].checked = selectAllheckbox.current!.checked;
                      }
                      setItems([...temp]);
                    }}
                  />
                </div>
              </th>
              <th className=" w-full"
                style={{
                    backgroundColor: colors["tb-head"]
                }}
              >
                <p>Date</p>
              </th>
              <th className=" pl-2 pr-2"
                style={{
                    backgroundColor: colors["tb-head"]
                }}
              >
                <p>Total</p>
              </th>
            </tr>
          </thead>
          <tbody style={{ color: colors["tb-row-text"] }}>
            {items.map((item, index) => {
              let bgColor = colors["tb-row-1"];
              if (index % 2 === 0) bgColor = colors["tb-row-2"];
              return (
                <tr
                  key={`stockin-${index}`}
                  style={{
                    backgroundColor: bgColor,
                  }}
                >
                  <td>
                    <div className="flex justify-center items-center h-[24px] mb-[1px]">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        className="w-4 h-4 ml-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        onChange={(e) => {
                          let temp = items;
                          temp[index].checked = e.target.checked;
                          setItems([...temp]);

                          let allChecked: boolean = true;
                          for (let i = 0; i < temp.length; i++) {
                            if (!temp[i].checked) {
                              allChecked = false;
                            }
                          }
                          selectAllheckbox.current!.checked = allChecked;
                        }}
                      />
                    </div>
                  </td>
                  <td className=" text-center hover:underline font-semibold cursor-pointer select-none"
                    onClick={() =>{
                        if (!props.permission['o-bal'])
                            return;
                        navigate(`/dashboard/view-balance/timespan=${timespan}&start=${timeframe.start}&end=${timeframe.end}&date=${item.created_at}`)
                    }}
                  >
                    <p>{item.created_at}</p>
                  </td>
                  <td>
                    <p>${item.price.toFixed(1)}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        className="w-full h-fit flex flex-row justify-center items-center gap-5 mt-5"
        style={{
          color: colors["text"],
        }}
      >
        {currentPage - 1 > 0 ? (
          <AiOutlineDoubleLeft
            className=" text-lg hover:cursor-pointer"
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          />
        ) : (
          <></>
        )}
        <p>{currentPage}</p>
        {currentPage + 1 > totalPage.current ? (
          <></>
        ) : (
          <AiOutlineDoubleRight
            className=" text-lg hover:cursor-pointer"
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          />
        )}
      </div>
    </div>
  );
}
