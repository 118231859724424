import { useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlinePlus, AiOutlineSearch, AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../utils/post";
import { MdArrowCircleRight } from "react-icons/md";
import { motion } from "framer-motion";
import { AvailableLanguages } from "./AddCategory";


export function ModifyCategory(props: {permission: any}){

    const { name } = useParams();

    const navigate = useNavigate();

    const categoryName = useRef<HTMLInputElement | null>(null);
    const parentName = useRef<HTMLInputElement | null>(null);

    const originalCategoryName = useRef<string>('');
    const originalParentName = useRef<string>('');

    const [parentPaths, setParentPaths] = useState<string[][]>([]);

    const [languages, setLanguages] = useState<{code: string, name: string}[]>([]);

    useEffect(() =>{
        if (!name)
            return;

        categoryName.current!.value = name;
        originalCategoryName.current = name;

        const f = async() =>{
            let data = await post('getCategoryReq', {
                name: name,
                translate: 1
            });

            if (data.status === 1){
                parentName.current!.value = data.data.parent;
                originalParentName.current = data.data.parent;

                let temp: {code: string, name: string}[] = [];
                Object.entries(data.translation).forEach(([key, item]) =>{
                    temp.push(item as any);
                })

                setLanguages([...temp])

            }
        }
        f();

    }, [])

    return (
        <div className=" w-full h-fit flex flex-col justify-center items-center">

            <div className=" w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">

                <div className="w-full h-fit flex flex-row justify-end items-center text-white gap-5">
                    <motion.div className=" w-fit h-fit bg-[#649d7f] flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async(e) =>{
                            if (!props.permission['m-cate'])
                                return;

                            let t = e.currentTarget;
                            t.style.pointerEvents = 'none';

                            let approval = true;

                            if (categoryName.current!.value === ''){
                                alert('Category name must be filled.');
                                approval = false;
                            }
                            if (categoryName.current!.value.length > 20){
                                alert('Category name must not be more than 20 characters.');
                                approval = false;
                            }

                            if (!approval){
                                t.style.pointerEvents = 'auto';
                                return;
                            }

                            if (originalCategoryName.current !== categoryName.current!.value){
                                let data = await post('getCategoryReq', {
                                    name: categoryName.current!.value,
                                    translate: 0
                                });

                                if (data.status === 1){
                                    alert('Category name already exist.');
                                    approval = false;
                                }
                            }

                            if (!approval){
                                t.style.pointerEvents = 'auto';
                                return;
                            }

                            if (parentName.current!.value !== ''){
                                if (originalParentName.current !== parentName.current!.value){
                                    let data = await post('getCategoryReq', {
                                        name: parentName.current!.value,
                                        translate: 0
                                    });
    
                                    if (data.status === 0){
                                        alert('Parent name not found.');
                                        approval = false;
                                    } else {
                                        if (data.data.length > 1){
                                            alert('Multiple parent name found.');
                                            approval = false;
                                        }
                                    }
    
                                    if (!approval){
                                        t.style.pointerEvents = 'auto';
                                        return;
                                    }
                                }
                            }

                            let finalLanguages = [];
                            for(let i = 0; i < languages.length; i++){
                                if (languages[i].code !== 'none'){
                                    finalLanguages.push(languages[i]);
                                }
                            }
                            
                            if (approval){
                                let data = await post('updateCategory', {
                                    oldName: originalCategoryName.current,
                                    newName: categoryName.current!.value,
                                    parent: parentName.current!.value,
                                    languages: JSON.stringify(finalLanguages)
                                });
                            }

                            t.style.pointerEvents = 'auto';

                            navigate('/dashboard/category');
                        }}>
                        Save
                    </motion.div>

                </div>

                <div className="w-full h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2 mt-5">
                    <p>Modify Category</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/categories');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <div className="w-full h-fit flex justify-start items-center text-black p-2 gap-5 bg-[#F5F3F9] whitespace-nowrap">
                    <p>Name</p>
                    <input ref={categoryName} type="text" className="border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                </div>

                <div className="w-full h-fit flex justify-start items-center text-black p-2 gap-5 bg-[#ECE9F1] whitespace-nowrap">
                    <p>Parent</p>
                    <input ref={parentName} type="text" className="border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    <div className=" w-10 h-10 text-xl bg-[#30323f] text-white flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={async(e) =>{
                            let t = e.currentTarget;
                            t.style.pointerEvents = 'none';
                            let data = await post('queryCategory', {
                                name: parentName.current!.value
                            });
                            t.style.pointerEvents = 'auto';

                            setParentPaths(data.data);
                        }}>
                        <AiOutlineSearch />
                    </div>
                </div>

                {
                    parentPaths.map((item, index) => {

                        return (
                            <div key={`parent-path-item-${index}`} className="w-full h-fit flex flex-row justify-start items-center gap-5 text-white p-2 bg-[#404252] mb-1 hover:cursor-pointer hover:bg-[#F1DCA6] hover:text-black flex-wrap"
                                onClick={() =>{
                                    parentName.current!.value = item[item.length - 1]
                                }}>
                                {
                                    item.map((name, nIndex) => {
                                        return (
                                            <div key={`parent-path-item-${index}-text-${nIndex}`} className=" flex flex-row justify-center items-center gap-5">
                                                <p>{name}</p>
                                                {
                                                    name === item[item.length - 1] ? <></> : <MdArrowCircleRight />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }

            </div>

            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

            <div className="w-full h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                <p>Languages</p>

            </div>

            {
                languages.map((item, index) =>{
                    return (
                        <div key={`language-item-${index}`} className=" w-full h-fit flex flex-row justify-start items-center bg-[#F5F3F9] p-2 gap-5"> 

                            {/* alpha-2 code */}
                            <select className=" w-1/3 p-2.5"
                                value={item.code}
                                onChange={(e) =>{
                                    for(let i = 0; i < languages.length; i++){
                                        if (languages[i].code === e.target.value){
                                            e.target.value = 'none';
                                            alert('Input field with same country code found.');
                                            return;
                                        }
                                    }

                                    let temp = languages;
                                    temp[index].code = e.target.value;

                                    setLanguages([...temp]);
                                }}>
                                    {
                                        Object.entries(AvailableLanguages).map(([key, value], index) =>{
                                            return (
                                                <option key={`lang-${index}`} value={key}>{value}</option>
                                            )
                                        })
                                    }
                            </select>

                            <div className="w-2/3">
                                <input type="text" className="p-2.5 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full"
                                    value={item.name}
                                    onChange={(e) =>{
                                        let temp = languages;
                                        temp[index].name = e.target.value;

                                        setLanguages([...temp]);
                                    }}/>
                            </div>

                            <AiOutlineClose className=" hover:cursor-pointer" 
                                onClick={() =>{
                                    let temp = languages;
                                    temp.splice(index, 1);

                                    setLanguages([...temp]);
                                }}/>
                        </div>
                    )
                })
            }

            <motion.div className=" w-14 h-14 bg-[#404252] rounded-full mt-5 flex justify-center items-center text-white hover:cursor-pointer"
                initial={{scale: 1}}
                whileHover={{scale: 1.2}}
                onClick={(e) =>{
                    let temp = languages;
                    temp.push({
                        code: '',
                        name: ''
                    })

                    setLanguages([...temp]);
                }}>
                    <AiOutlinePlus />
            </motion.div>

            </div>

        </div>
    )
}