import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useEffect, useRef, useState } from "react";
import { post } from "../utils/post";
import { FaBell } from "react-icons/fa6";

type NotificationProps = {
    type: string;
    message: string;
    created_at: string;
    has_read: boolean;
  };

export function Notification(){

    const navigate = useNavigate();
    const {account, groups, setAccount, setGroups} = useAccountStore();
    const {style, colors, setStyle } = useStyleStore();

    const totalLength = useRef<number>(0);
    const totalPage = useRef<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [items, setItems] = useState<NotificationProps[]>([]);

    useEffect(() => {
        if (account.current_group === -1)
            return;

        const f = async () => {
          let length = await post("getLength", {
            name: "notifications",
            where: `\`group_id\` = '${account.current_group}'`,
          });
          totalLength.current = length.data;
          totalPage.current = Math.ceil(totalLength.current / 10);
    
          setCurrentPage(1);
        };
        f();
      }, []);

    useEffect(() =>{
        if (account.current_group === -1)
            return;
        if (currentPage === 0)
            return;

        const f = async() =>{
            let result = await post("getAllNotifications", {
                page: currentPage,
                group_id: account.current_group,
                user_id: account.user_id
            });

            let temp = items;
            temp = [];
            Object.values(result.data).forEach((item) => {
              let typedItem = item as any;
              let typed_id = parseInt(typedItem.id);
              temp.push({
                type: typedItem.type,
                message: typedItem.message,
                created_at: typedItem.created_at,
                has_read: result.current >= typed_id ? true : false
              });
            });
            setItems([...temp]);

            let temp_groups = groups;
            if (temp_groups[account.current_group]){
                temp_groups[account.current_group].latest_notification = result.latest.toString();
                setGroups(temp_groups);
            }
        };
        f();
    }, [currentPage])

    if (account.current_group === -1){
        return (
            <div className="w-full h-fit flex flex-col justify-center items-center">
                <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5 text-zinc-400 select-none">
                    <p>You need to select a group first to view notifications within the group.</p>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center">

            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                <div className="mt-5 w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <div className=" inline-flex gap-2 justify-center items-center">
                        <FaBell />
                        <p>Messages</p>
                    </div>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full"
                    style={{
                        color: colors['tb-row-text'],
                        borderColor: colors['tb-row-text-d']
                    }}
                >
                    <tbody>
                        {
                            items.map((item, index) =>{
                                return (
                                    <tr key={`msg-${index}`}
                                        style={{
                                            backgroundColor: item.has_read ? colors['tb-row-1'] : ''
                                        }}
                                    >
                                        <td className=" p-2 w-full flex flex-col justify-center items-start pb-4">
                                            <div className=" pl-1 pr-1 w-full flex flex-row justify-between items-center border-b"
                                                style={{
                                                    backgroundColor: colors['tb-row-2']
                                                }}
                                            >
                                                <p className=" font-semibold">{item.type}</p>
                                                <p style={{color: item.has_read ? colors['tb-row-text-e'] : colors['text']}} className=" font-mono text-sm">{item.created_at}</p>
                                            </div>
                                            <p className=" pl-1 pr-1">{item.message}</p>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div
                    className="w-full h-fit flex flex-row justify-center items-center gap-5 mt-5"
                    style={{
                        color: colors["text"],
                    }}
                    >
                    {currentPage - 1 > 0 ? (
                        <AiOutlineDoubleLeft
                        className=" text-lg hover:cursor-pointer"
                        onClick={() => {
                            setCurrentPage(currentPage - 1);
                        }}
                        />
                    ) : (
                        <></>
                    )}
                    <p>{currentPage}</p>
                    {currentPage + 1 > totalPage.current ? (
                        <></>
                    ) : (
                        <AiOutlineDoubleRight
                        className=" text-lg hover:cursor-pointer"
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                        }}
                        />
                    )}
                </div>

            </div>

        </div>
    )
}