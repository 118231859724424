import { AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { useRef, useState } from "react";
import { useTabStore } from "../utils/zustand/useTabStore";
import Resizer from "react-image-file-resizer";
import { post } from "../utils/post";


export function Settings(){

    const navigate = useNavigate();
    const {account, setAccount} = useAccountStore();
    const {style, colors, setStyle } = useStyleStore();
    const {deleteAllTabs} = useTabStore();

    const [displayChangePassword, setDisplayChangePassword] = useState<boolean>(false);
    const [changePassword, setChangePassword] = useState<{current_password: string, new_password1: string, new_password2: string}>({current_password: '', new_password1: '', new_password2: ''});

    const profileInput = useRef<HTMLInputElement | null>(null);

    const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

    const profileFileHandler = async (file: File) =>{
        let imgBlob = await resizeFile(file) as Blob;
    
        let data = new FormData();
        data.append( "file", imgBlob, file.name );
        data.append("filename", file.name);
        data.append("user_id", account.user_id.toString());
        data.append("path", 'user-icon/');
    
        fetch(
          "https://inanin.com/shopping-platform/commerce-backend/upload_user_icon.php",
          {
            method: "POST",
            body: data,
          }
        )
          .then(async (response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const getData = await response.json();
    
            if (getData.status === "upload success") {
                setAccount({
                    ...account,
                    image: getData.link
                });
            } else {
              alert(getData.status);
            }
          })
          .catch((e) => {
            alert("There was a problem with the file upload.");
            console.log(e);
          })
          .finally(() => {
    
          });
    
      }

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center">

            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                <div className="mt-5 w-full h-fit flex justify-between items-center rounded-tl-md rounded-tr-md p-2"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Account</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full"
                    style={{
                        color: colors['tb-row-text']
                    }}
                >
                    <tbody>
                        <tr
                            style={{
                                backgroundColor: colors['tb-row-1']
                            }}
                        >
                            <td className=" pl-2 text-left">
                                <div className=" w-full flex flex-col md:flex-row justify-between items-start gap-2 pr-2">
                                    <div className=" inline-flex gap-4">
                                        <p>{account.username}</p>
                                        <p className="font-mono" style={{color: colors['tb-row-text-d']}}>#{account.user_id}</p>
                                    </div>
                                    <div className=" w-[100px] h-[100px] rounded-full p-2 select-none cursor-pointer hover:opacity-80"
                                        style={{
                                            backgroundColor: colors['bar-1']
                                        }}
                                        onClick={() =>{
                                            profileInput.current!.click();
                                        }}
                                    >
                                        {
                                            account.image ?
                                            <img className="contain rounded-full"
                                                src={`https://inanin.com/shopping-platform/commerce-backend/uploads/${account.image}`}
                                            /> :
                                            <></>
                                        }
                                        <input ref={profileInput} type="file" accept=".jpg,.jpeg,.png" className=" hidden"
                                            onChange={(e) =>{
                                                let files = e.target.files;
                                                if (!files)
                                                  return;
                                                if (files.length === 0)
                                                  return;
                              
                                                profileFileHandler(files[0]);
                                            }}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style={{
                            backgroundColor: colors['tb-row-2']
                        }}>
                            <td className="pl-2 pr-2 pt-2">
                                <div className=" w-full flex flex-col justify-start items-start">
                                    <div className="w-full flex justify-between items-center">
                                        <div className=" w-fit flex flex-row justify-center items-center gap-2">
                                            <p>Current Password</p>
                                            <input type="password" disabled value={account.password} className="pl-1 pr-1 w-fit" />
                                        </div>
                                        <div style={{color: colors['tb-row-text-d']}} className=" underline hover:no-underline select-none cursor-pointer"
                                            onClick={() =>{
                                                setDisplayChangePassword(!displayChangePassword)
                                            }}
                                        >
                                            Change Password
                                        </div>
                                    </div>
                                    <div className=" w-full flex-col justify-center items-start pt-5 pb-5 gap-2"
                                        style={{
                                            display: displayChangePassword ? 'flex' : 'none'
                                        }}
                                    >
                                        <div className="w-full inline-flex gap-2 justify-between items-center">
                                            <p>Enter Current Password</p>
                                            <input type="password" value={changePassword.current_password} className=" rounded-md bg-white pr-1 pl-1"
                                                onChange={(e) =>{
                                                    setChangePassword({...changePassword, current_password: e.target.value});
                                                }}
                                            />
                                        </div>
                                        <div className="w-full inline-flex gap-2 justify-between items-center">
                                            <p>Enter New Password</p>
                                            <input type="password" value={changePassword.new_password1} className=" rounded-md bg-white pr-1 pl-1"
                                                onChange={(e) =>{
                                                    setChangePassword({...changePassword, new_password1: e.target.value});
                                                }}
                                            />
                                        </div>
                                        <div className="w-full inline-flex gap-2 justify-between items-center">
                                            <p>Enter New Password Again</p>
                                            <input type="password" value={changePassword.new_password2} className=" rounded-md bg-white pr-1 pl-1"
                                                onChange={(e) =>{
                                                    setChangePassword({...changePassword, new_password2: e.target.value});
                                                }}
                                            />
                                        </div>
                                        <div className=" w-full flex justify-end pt-2 select-none">
                                            <div className="p-1 pl-2 pr-2 font-semibold cursor-pointer hover:opacity-70"
                                                style={{
                                                    backgroundColor: colors['tb-head'],
                                                    color: colors['tb-head-text']
                                                }}
                                                onClick={async() =>{
                                                    if (changePassword.current_password === '' || changePassword.new_password1 === '' || changePassword.new_password2 === ''){
                                                        alert('Password cannot be empty');
                                                        return;
                                                    }
                                                    if (changePassword.new_password1 !== changePassword.new_password2){
                                                        alert('New password invalid.');
                                                        return;
                                                    }
                                                    if (changePassword.current_password !== account.password){
                                                        alert('Current password invalid.');
                                                        return;
                                                    }
                                                    if (changePassword.new_password1.length < 8){
                                                        alert('New password length must be greater than 7.');
                                                        return;
                                                    }
                                                    if (changePassword.current_password === changePassword.new_password1){
                                                        alert('Current password and new password cannot be the same.');
                                                        return;
                                                    }
                                                    await post('setPassword', {
                                                        user_id: account.user_id,
                                                        password: changePassword.new_password1
                                                    }, true);
                                                    setAccount({...account, password: changePassword.new_password1});
                                                    setChangePassword({current_password: '', new_password1: '', new_password2: ''});
                                                    alert('Password changed.');
                                                }}
                                            >
                                                Confirm
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="mt-5 w-full h-fit flex justify-start items-center rounded-tl-md rounded-tr-md p-2"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Style</p>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full"
                    style={{
                        color: colors['tb-row-text']
                    }}
                >
                    <tbody>
                        <tr>
                            <td className=" text-left">
                                <div className=" w-full flex flex-col justify-center items-start">
                                    <div className="w-full inline-flex justify-between gap-4 p-2"
                                        style={{
                                            backgroundColor: colors['tb-row-1']
                                        }}
                                    >
                                        <p>Default</p>
                                        <input type="radio" name="style" value="default" checked={style === 'default' ? true: false}
                                            onChange={(e) =>{
                                                setStyle(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="w-full inline-flex justify-between gap-4 p-2"
                                        style={{
                                            backgroundColor: colors['tb-row-2']
                                        }}
                                    >
                                        <p>Dark Mode</p>
                                        <input type="radio" name="style" value="dark" checked={style === 'dark' ? true: false}
                                            onChange={(e) =>{
                                                setStyle(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className=" bg-[#F5F3F9]">
                        </tr>
                    </tbody>
                </table>

                <div className="mt-5 w-full h-fit flex justify-start items-center rounded-tl-md rounded-tr-md p-2"
                    style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                    }}
                >
                    <p>Helper</p>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full"
                    style={{
                        color: colors['tb-row-text']
                    }}
                >
                    <tbody>
                        <tr
                            style={{
                                backgroundColor: colors['tb-row-1']
                            }}
                        >
                            <td className=" pl-2 text-left">
                                <div className="p-2 cursor-pointer hover:underline"
                                    onClick={() =>{
                                        deleteAllTabs();
                                    }}
                                >
                                    Close All Tabs
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
    )
}