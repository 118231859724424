import * as Excel from "exceljs";
import {saveAs } from './saveAs';

export async function saveInventoriesExcel(username: string, inventories: {
    checked: boolean;
    modelId: string;
    style: string;
    name: string;
    stocks: {
        warehouse: string;
        quantity: number;
    }[];
}[]){

    const today = new Date();

    const workbook = new Excel.Workbook();
    workbook.creator = username;
    workbook.lastModifiedBy = username;
    workbook.created = today;
    workbook.modified = today;
    workbook.lastPrinted = today;

    const sheet = workbook.addWorksheet('Inventories');
    sheet.columns = [
        { header: '', key: 'A'},
        { header: '', key: 'B'},
        { header: '', key: 'C'},
        { header: '', key: 'D'},
        { header: '', key: 'E'},
        { header: '', key: 'F'}
    ];

    let currentRow = 0;
    sheet.insertRow(++currentRow, {A: 'Inventory Records'});
    sheet.insertRow(++currentRow, {A: 'Date', B: today.toISOString()});

    ++currentRow;

    sheet.insertRow(++currentRow, {A: 'Model Id', B: 'Style', C: 'Name', D: 'Warehouse', E: 'Quantity'});

    for(let i = 0; i < inventories.length; i++){
        let inventory = inventories[i];
        let quantity: any[] = [];
        let total = 0;
        inventory.stocks.forEach((item) =>{
            quantity.push(item);
        })

        if (quantity.length > 0){
            if (quantity.length === 1){
                sheet.insertRow(++currentRow, {A: inventory.modelId, B: inventory.style, C: inventory.name, D:  quantity[0].warehouse, E: quantity[0].quantity});
            } else {
                let total = 0;
                sheet.insertRow(++currentRow, {A: inventory.modelId, B: inventory.style, C: inventory.name, D:  quantity[0].warehouse, E: quantity[0].quantity});
                total += parseInt(quantity[0].quantity);

                for(let k = 1; k < quantity.length; k++){
                    let item = quantity[k];
                    sheet.insertRow(++currentRow, {D: item.warehouse, E: item.quantity});
                    total += parseInt(item.quantity);
                }
                if (total > 0)
                    sheet.insertRow(++currentRow, {D: 'Total', E: total});
            }
        }
        ++currentRow;
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([buffer], {type: fileType});
    saveAs(blob, `Inventories.xlsx`);
}