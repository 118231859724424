import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { post } from "../utils/post";

export function Auth() {
  const { account, setAccount } = useAccountStore();

  const [code, setCode] = useState<string>("");
    const [rememberDate, setRememberDate] = useState<boolean>(false);

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const navigate = useNavigate();

    useEffect(() =>{
        if (!account.username || !account.password || account.user_id === -1)
        {
            navigate('/');
            return;
        }
    }, [account])

  const enter = async (code: string) => {
    if (isLoggingIn) return;
    if (!code) return;

    setIsLoggingIn(true);

    let result = await post('authOneTimePasscode', {
        code: code,
        remember_date: rememberDate,
        user_id: account.user_id
    });
    if (result.status === 0){
        alert('Passcode incorrect');
    } else {
      window.sessionStorage.setItem('bypass_auth', '1');
      navigate('/dashboard');
    }

    setIsLoggingIn(false);
  };

  if (!account.username || !account.password || account.user_id === -1)
    return <></>

  return (
    <>
      <div className=" w-[100vw] h-[100vh] flex justify-start items-center">
        <div className=" relative w-full h-full flex justify-center items-center">
          <div className="w-full h-full absolute -z-10 select-none pointer-events-none">
            <img
              alt="bg"
              src="./images/color-gradient1.png"
              className=" cover"
            />
            <p className=" absolute right-0 top-0 m-2 text-white text-lg cursor-pointer hover:underline"
              onClick={() =>{
                navigate('/');
              }}
            >
                Back
            </p>
          </div>

          <div className=" relative z-10 w-full sm:w-1/2 h-fit pr-2 pt-2 pb-2 m-5 shadow-[0_0px_60px_10px_rgba(255,255,255,0.3)] rounded-lg flex flex-col items-center justify-center gap-3">
            <p className=" font-bold text-white">Auth</p>

            <p className=" pl-1 w-full text-start text-white">
              An authentication code has been sent to your email. Please enter
              the code below.
            </p>

            <div className=" w-full h-fit flex items-center justify-center gap-2">
              <p className=" text-white pl-1">code</p>
              <input
                type="text"
                id="username"
                defaultValue={code}
                className="border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    enter(code);
                  }
                  e.preventDefault();
                }}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </div>

            <div className=" w-full inline-flex justify-end items-center gap-2 pr-1 text-white">
              <input checked={rememberDate} type="checkbox"
                onChange={(e) =>{
                    setRememberDate(e.target.checked);
                }}
              />
              <p>Remember Me For 30 Days</p>
            </div>

            <div className=" w-full inline-flex justify-end items-center gap-2">
              <p
                className=" bg-white p-1 pl-2 pr-2 rounded-md border-black border-[1px] hover:cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  enter(code);
                }}
              >
                Confirm
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
