import { useEffect, useRef, useState } from "react";
import {
  AiFillDelete,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineNumber,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/post";
import { allPermissions } from "./UserPermission";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { FaGear } from "react-icons/fa6";
import { getPermission } from "../utils/getPermission";
import { useTabStore } from "../utils/zustand/useTabStore";

export function Admin(props: { permission: any }) {
  const [users, setUsers] = useState<
    {
      user_id: number;
      username: string;
      permission: string;
      checked: boolean;
    }[]
  >([]);

  const totalLength = useRef<number>(0);
  const totalPage = useRef<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const selectAllheckbox = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();

  const { account } = useAccountStore();
  const {goTab, all_tabs} = useTabStore();

  useEffect(() => {
    const f = async () => {
      let result = await post("getUsers", {
        group_id: account.current_group,
        page: currentPage,
      });
      let arr = [];
      for (let i = 0; i < result.data.length; i++) {
        let data = result.data[i];
        arr.push({
          user_id: data.id,
          username: data.username,
          permission: data.permission,
          checked: false,
        });
      }

      totalLength.current = result.length;
      totalPage.current = Math.ceil(totalLength.current / 10);

      setUsers(arr);
    };
    f();
  }, [currentPage]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className="w-full lg:w-2/3 h-fit flex flex-row justify-end items-center mt-5 text-white gap-2">
        
        {
            props.permission['a-admin'] ?
            <div className=' w-10 h-10 bg-[#30323f] rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer'
                onClick={() =>{
                    if (props.permission['a-admin']){
                      goTab('Group Settings', '/dashboard/add-user', all_tabs);
                      navigate('/dashboard/add-user');
                    }
                }}>
                <FaGear />
            </div> : <></>
        }
        
        {props.permission["d-admin"] ? (
          <div
            className=" w-10 h-10 bg-[#30323f] rounded-md flex justify-center items-center hover:text-[#a9abc6] hover:cursor-pointer"
            onClick={async (e) => {
              let t = e.currentTarget;
              t.style.pointerEvents = "none";

              let checked: number[] = [];
              let temp: typeof users = [];
              for (let i = 0; i < users.length; i++) {
                if (users[i].checked) {
                  checked.push(users[i].user_id);
                } else {
                  temp.push(users[i]);
                }
              }

              if (checked.length === 0){
                t.style.pointerEvents = "auto";
                alert('No user selected.');
                return;
              }
              let _confirm = window.confirm('Are you sure to delete users?');
              if (!_confirm){
                t.style.pointerEvents = "auto";
                return;
              }

              await post(
                "deleteUsers",
                {
                  checked: checked,
                  group_id: account.current_group,
                },
                true
              );

              setUsers([...temp]);

              t.style.pointerEvents = "auto";
            }}
          >
            <AiFillDelete />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className=" mt-5 w-full lg:w-2/3 h-fit">
        <table className="table-auto w-full whitespace-nowrap">
          <thead className=" text-white">
            <tr>
              <th>
                <div className="bg-[#56596c] rounded-tl-md flex justify-center items-center h-[24px] mb-[1px]">
                  <input
                    ref={selectAllheckbox}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    onClick={(e) => {
                      let temp = users;
                      for (let i = 0; i < temp.length; i++) {
                        temp[i].checked = selectAllheckbox.current!.checked;
                      }
                      setUsers([...temp]);
                    }}
                  />
                </div>
              </th>
              <th>
                <div className=" flex justify-center items-center gap-2 bg-[#56596c]">
                  <p>Username</p>
                  <AiOutlineNumber />
                </div>
              </th>
              <th>
                <div className=" flex justify-center items-center gap-2 bg-[#56596c]">
                  <p>Permission</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              let bgColor = "#F5F3F9";
              if (index % 2 === 0) bgColor = "#ECE9F1";

              return (
                <tr
                  key={`admin-item-${index}`}
                  className=" text-center"
                  style={{
                    backgroundColor: bgColor,
                  }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={user.checked}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      onChange={(e) => {
                        let temp = users;
                        temp[index].checked = e.target.checked;
                        setUsers([...temp]);

                        let checked = 0;
                        for (let i = 0; i < temp.length; i++) {
                          if (temp[i].checked) checked++;
                        }

                        if (checked === temp.length) {
                          selectAllheckbox.current!.checked = true;
                        } else {
                          selectAllheckbox.current!.checked = false;
                        }
                      }}
                    />
                  </td>
                  <td className=" p-2 border-[2px] border-white text-start">
                    <p
                      className=" w-fit underline hover:no-underline hover:cursor-pointer"
                      onClick={() => {
                        if (props.permission["o-admin"]){
                          goTab(`User ${user.username}`, `/dashboard/user-permission/${user.user_id}`, all_tabs);
                          navigate(
                            `/dashboard/user-permission/${user.user_id}`
                          );
                        }
                      }}
                    >
                      {user.username}
                    </p>
                  </td>
                  <td className="border-[2px] border-white">
                    <div className="w-full h-fit max-h-[200px] overflow-y-scroll flex flex-col justify-start items-start">
                      {user.permission ? (
                        Object.keys(getPermission(user.permission)).map((key, uIndex) => {
                          return (
                            <p
                              key={`perm-${index}-${uIndex}`}
                              className=" w-full text-start pl-2"
                              style={{
                                backgroundColor:
                                  uIndex % 2 === 0
                                    ? "rgb(253, 252, 255)"
                                    : "rgb(213, 207, 223)",
                              }}
                            >
                              {allPermissions[key]}
                            </p>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="w-full h-fit flex flex-row justify-center items-center gap-5 mt-5">
          {currentPage - 1 > 0 ? (
            <AiOutlineDoubleLeft
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
          ) : (
            <></>
          )}
          <p>{currentPage}</p>
          {currentPage + 1 > totalPage.current ? (
            <></>
          ) : (
            <AiOutlineDoubleRight
              className=" text-lg hover:cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
