import { useEffect, useRef, useState } from "react";
import { useAccountStore } from "../utils/zustand/useAccountStore"
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineSwapLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaEnvelope } from "react-icons/fa";
import { motion } from "framer-motion";
import { post } from "../utils/post";


export function AddGroup(){

    const navigate = useNavigate();
    const {account, groups, setGroups} = useAccountStore();

    const [groupName, setGroupName] = useState<string>('');

    return (
        <div className="w-full h-fit flex flex-col justify-center items-center">
            
            <div className="w-full lg:w-2/3 h-fit flex flex-col justify-center items-center mt-5">

                <div className="w-full h-fit flex flex-row justify-end items-center text-white gap-5">
                    <motion.div className=" w-fit h-fit bg-[#649d7f] flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2}}
                        onClick={async() =>{
                            let index = groups.findIndex(e => e.name === groupName);
                            if (index > -1){
                                alert('Group name exists.')
                                return;
                            }

                            let result = await post('addGroup', {
                                name: groupName,
                                user_id: account.user_id
                            });

                            if (result.status){
                                alert(`Group ${groupName} added.`);
                                let temp = groups;
                                temp.push({
                                    id: result.id,
                                    name: groupName,
                                    permission: 'admin',
                                    created_by: account.user_id.toString(),
                                    latest_notification: '0'
                                })
    
                                setGroups([...temp]);
                            } else {
                                alert(`Group ${groupName} cannot be added.`);
                            }
                        }}
                    >
                        Add
                    </motion.div>
                </div>

                <div className="mt-5 w-full h-fit flex justify-between items-center bg-[#30323f] text-white rounded-tl-md rounded-tr-md p-2">
                    <p>Group Info</p>

                    <div className=" w-fit h-fit hover:text-[#a9abc6] hover:cursor-pointer"
                        onClick={() =>{
                            navigate('/dashboard/');
                        }}>
                        <AiOutlineSwapLeft className=" text-lg " />
                    </div>
                </div>

                <table className="table-auto whitespace-nowrap text-center w-full">
                    <tbody>
                        <tr className=" bg-[#F5F3F9]">
                            <td className=" pl-2">Group Name</td>
                            <td className=" w-full pl-2 p-2"> 
                                <input value={groupName} className=" w-full p-1 border border-zinc-800 rounded-md" 
                                    onChange={(e) =>{
                                        setGroupName(e.target.value);
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    )
}