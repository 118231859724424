import { create } from 'zustand'

interface StyleState {
    style: string;
    colors: Record<string, string>;
    setStyle: (style: string) => void;
}

export const defaultMode: Record<string, string> = {
    'bar-1': '#18181B',
    'bar-2': '#27272A',
    'bar-but': '#A1A1AA',
    'bar-1-text': '#FFFFFF',
    'bar-2-text': '#FFFFFF',
    'bg': '#FFFFFF',
    'text': '#000000',
    'tb-head': '#56596C',
    'tb-head-text': '#FFFFFF',
    'tb-row-1': '#F5F3F9',
    'tb-row-2': '#ECE9F1',
    'tb-row-1-h': '#ECE9F3',
    'tb-row-2-h': '#DAD4E4',
    'tb-row-text': '#000000',
    'tb-row-text-e': '#312F30',
    'tb-row-text-d': '#B5B1B3'
};

export const darkMode: Record<string, string> = {
    'bar-1': '#18122B',
    'bar-2': '#393053',
    'bar-but': '#635985',
    'bar-1-text': '#FFFFFF',
    'bar-2-text': '#FFFFFF',
    'bg': '#18172F',
    'text': '#FFFFFF',
    'tb-head': '#32407B',
    'tb-head-text': '#FFFFFF',
    'tb-row-1': '#8190BD',
    'tb-row-2': '#7382B0',
    'tb-row-1-h': '#8F7FA6',
    'tb-row-2-h': '#959BB7',
    'tb-row-text': '#FFFFFF',
    'tb-row-text-e': '#363169',
    'tb-row-text-d': '#5C60AF'
};

export const useStyleStore = create<StyleState>()((set) => ({
    style: 'default',
    colors: defaultMode,
    setStyle: (_style) =>{
        localStorage.setItem('style', _style);
        let dict: Record<string, string> = {};
        switch (_style){
            case 'dark': {
                dict = darkMode;
                break;
            }
            default: {
                dict = defaultMode;
            }
        }
        set(() => ({
            style: _style,
            colors: dict
        }))
    }
}))