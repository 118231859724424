import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { post } from "../utils/post";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AiOutlineClose,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlineSwapLeft,
} from "react-icons/ai";
import { FaRegStar } from "react-icons/fa";
import { FaShuttleVan, FaWarehouse } from "react-icons/fa";
import { ScrollTo } from "../utils/scrollTo";
import { useAccountStore } from "../utils/zustand/useAccountStore";
import { useStyleStore } from "../utils/zustand/useStyleStore";
import { saveOrderExcel } from "../utils/report/saveOrderExcel";

export function OrderView(props: { permission: any }) {
  const { name } = useParams();
  const navigate = useNavigate();

  const { account } = useAccountStore();
  const { colors } = useStyleStore();

  const firstNameInputField = useRef<HTMLInputElement | null>(null);
  const lastNameInputField = useRef<HTMLInputElement | null>(null);
  const emailInputField = useRef<HTMLInputElement | null>(null);
  const phoneInputField = useRef<HTMLInputElement | null>(null);
  const countrtInputField = useRef<HTMLInputElement | null>(null);
  const address1InputField = useRef<HTMLInputElement | null>(null);
  const address2InputField = useRef<HTMLInputElement | null>(null);
  const cityInputField = useRef<HTMLInputElement | null>(null);
  const regionInputField = useRef<HTMLInputElement | null>(null);
  const postalCodeInputField = useRef<HTMLInputElement | null>(null);
  const shippingMethodInputField = useRef<HTMLInputElement | null>(null);
  const shippingOptionInputField = useRef<HTMLInputElement | null>(null);
  const paymentMethodInputField = useRef<HTMLInputElement | null>(null);
  const receipt = useRef<string>("");
  const created_at = useRef<string>('');

  const deliveryHead = useRef<HTMLTableHeaderCellElement | null>(null);

  //model_id,style_code -> other
  const [products, setProducts] = useState<
    Record<
      string,
      {
        model_id: string;
        name: string;
        price: string;
        quantity: string;
        style: string;
        style_code: number;
        is_custom: boolean;
      }
    >
  >({});
  const [status, setStatus] = useState<string>("not started");
  const [payment_option, setPaymentOption] = useState<string>('');
  const [remarks, setRemarks] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [sharedBy, setSharedBy] = useState<number>(0);
  const [inventory, setInventory] = useState<
    Record<
      string,
      {
        modelId: string;
        name: string;
        style_code: number;
        style: string;
        warehouse: string;
        quantity: number;
        distribution: { to: string; quantity: number }[];
      }[]
    >
  >({});

  const [totalStockOut, setTotalStockOut] = useState<
    Record<string, { total: number; current: number }>
  >({});

  const [customerId, setCustomerId] = useState<string>("Anonymous");

  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/dashboard/order-view")) return;
    if (!name) return;

    const f = async () => {
      let result = await post("getOrder", {
        group_id: account.current_group,
        orderId: name,
      });
      if (result.status === 1) {
        if (result.customer_id) {
          setCustomerId(result.customer_id);
          if (result.customer_id === "-1") setCustomerId("Anonymous");
        }
        firstNameInputField.current!.value = result.first_name;
        lastNameInputField.current!.value = result.last_name;
        emailInputField.current!.value = result.email;
        phoneInputField.current!.value = result.phone;
        countrtInputField.current!.value = result.country;
        address1InputField.current!.value = result.address1;
        address2InputField.current!.value = result.address2;
        cityInputField.current!.value = result.city;
        regionInputField.current!.value = result.region;
        postalCodeInputField.current!.value = result.postal_code;
        shippingMethodInputField.current!.value = result.shipping_method;
        shippingOptionInputField.current!.value = result.shipping_option;
        paymentMethodInputField.current!.value = result.payment_method;
        created_at.current = result.created_at;

        receipt.current = result.hashed_order_id;

        let p: typeof products = {};
        let t: typeof totalStockOut = {};
        Object.values(result.products).forEach((item: any) => {
          if (item.is_custom === 1){
            p[item.model_id] = {
              ...item,
              style: '',
              name: item.model_id,
              is_custom: true
            };
          } else {
            let index = result.quantities.findIndex(
              (e: any) => e.model_id === item.model_id && e.style_code === item.style_code
            );
            if (index > -1) {
              let style_code_ref =
                result.quantities[index].style_code_ref[item.style_code];
              p[item.model_id + "," + item.style_code] = {
                ...item,
                style: style_code_ref,
                name: result.quantities[index].name,
                is_custom: false
              };
              t[item.model_id + "," + item.style_code] = {
                total: parseInt(item.quantity),
                current: 0,
              };
            }
          }
        });
        setProducts(p);
        setTotalStockOut(t);
        setStatus(result.order_status);
        setPaymentOption(result.payment_option);
        setRemarks(result.remarks ? result.remarks : "");
        setSharedBy(result.shared_by);

        let total = 0;
        let temp: typeof inventory = {};
        Object.values(result.products).forEach((product: any) => {
          total += parseFloat(product.price);
        });

        let existed: string[] = [];
        Object.values(result.quantities).forEach((inv: any) => {
          Object.values(inv["inv"]).forEach((item: any) => {
            if (!temp[item.warehouse]) temp[item.warehouse] = [];
            if (!existed.includes(item.warehouse + "," + inv.model_id + "," + item.style_code)) {
              existed.push(
                item.warehouse + "," + inv.model_id + "," + item.style_code
              );
              temp[item.warehouse].push({
                modelId: inv.model_id,
                name: inv.name,
                style_code: parseInt(item.style_code),
                style: inv.style_code_ref[item.style_code],
                warehouse: item.warehouse,
                quantity: item.quantity,
                distribution: [],
              });
            }
          });
        });

        Object.values(result.order_inventories).forEach((item: any) => {
          if (item.model_id){
            //model_id, style, warehouse, quantity
            temp[item.warehouse].forEach((inv) => {
              if (
                inv.modelId === item.model_id &&
                inv.style_code === parseInt(item.style_code) &&
                inv.warehouse === item.warehouse
              ) {
                inv.distribution.push({
                  to: "",
                  quantity: item.quantity,
                });
                inv.quantity =
                  parseInt(inv.quantity as any) + parseInt(item.quantity);
              }
            });
          }
        });
        setTotalPrice(total);
        setInventory({ ...temp });
      } else {
        alert("Order not found.");
        navigate("/dashboard/order");
      }
    };
    f();
  }, [name, location]);

  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center">
      <div className=" mt-5 w-full lg:w-2/3 h-fit flex justify-end items-end">
        <motion.div
          className=" w-fit h-fit flex justify-center items-center p-2 hover:cursor-pointer"
          style={{
            backgroundColor: colors["tb-head"],
            color: colors["tb-head-text"],
          }}
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.2 }}
          onClick={async () => {
            let distributions: {
              warehouse: string;
              model_id: string;
              style_code: number;
              style: string;
              quantity: number;
              transferred: number;
            }[] = [];
            Object.values(inventory).forEach((inv) => {
              inv.forEach((item) => {
                item.distribution.forEach((dis) => {
                  if (dis.quantity > 0)
                    distributions.push({
                      warehouse: item.warehouse,
                      model_id: item.modelId,
                      style_code: item.style_code,
                      style: item.style,
                      quantity: item.quantity - dis.quantity,
                      transferred: dis.quantity,
                    });
                });
              });
            });
            saveOrderExcel(
              account.username,
              name ? name : "UNDEFINED",
              products,
              distributions,
              firstNameInputField.current!.value,
              lastNameInputField.current!.value,
              emailInputField.current!.value,
              phoneInputField.current!.value,
              countrtInputField.current!.value,
              address1InputField.current!.value,
              address2InputField.current!.value,
              cityInputField.current!.value,
              regionInputField.current!.value,
              postalCodeInputField.current!.value,
              shippingMethodInputField.current!.value,
              shippingOptionInputField.current!.value,
              paymentMethodInputField.current!.value,
              created_at.current
            );
          }}
        >
          Export Excel
        </motion.div>
      </div>

      <div className=" mt-14 w-full lg:w-2/3 h-fit flex flex-col justify-center items-center">
        <div
          className="w-full h-fit flex flex-row justify-between items-start gap-5"
          style={{
            color: colors["text"],
          }}
        >
          <div className=" flex flex-col justify-start items-start">
            <div className=" inline-flex gap-2">
              <p>Order Id:</p>
              <p>{name}</p>
            </div>

            <div className=" inline-flex gap-2">
              <p
                className="font-semibold cursor-pointer hover:underline"
                onClick={() => {
                  window.open(
                    `https://shopping-platform-front.vercel.app/receipt/customer/${receipt.current}`
                  );
                }}
              >
                Customer Receipt
              </p>
            </div>

            <div className=" inline-flex gap-2">
              <p
                className="font-semibold cursor-pointer hover:underline"
                onClick={() => {
                  window.open(
                    `https://shopping-platform-front.vercel.app/receipt/delivery/${receipt.current}/${account.current_group}`
                  );
                }}
              >
                Delivery Note
              </p>
            </div>
          </div>

          <motion.div
            className=" w-fit h-fit bg-[#649d7f] text-white flex justify-center items-center p-2 mt-2 hover:cursor-pointer"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            onClick={async () => {
              if (!props.permission["m-ord"]) return;

              let distributions: {
                warehouse: string;
                model_id: string;
                style_code: number;
                style: string;
                quantity: number;
                transferred: number;
              }[] = [];
              Object.values(inventory).forEach((inv) => {
                inv.forEach((item) => {
                  item.distribution.forEach((dis) => {
                    if (dis.quantity > 0)
                      distributions.push({
                        warehouse: item.warehouse,
                        model_id: item.modelId,
                        style_code: item.style_code,
                        style: item.style,
                        quantity: item.quantity - dis.quantity,
                        transferred: dis.quantity,
                      });
                  });
                });
              });

              if (sharedBy > 1){
                await post(
                  "updateOrder",
                  {
                    group_id: account.current_group,
                    distributions: JSON.stringify(distributions),
                    orderId: name,
                    status: status,
                    remarks: remarks,
                  },
                  true
                );
              } else {
                await post(
                  "updateOrder",
                  {
                    group_id: account.current_group,
                    distributions: JSON.stringify(distributions),
                    orderId: name,
                    status: status,
                    remarks: remarks,
                    customer_id: customerId,
                    first_name: firstNameInputField.current!.value,
                    last_name: lastNameInputField.current!.value,
                    email: emailInputField.current!.value,
                    phone: phoneInputField.current!.value,
                    country: countrtInputField.current!.value,
                    address1: address1InputField.current!.value,
                    address2: address2InputField.current!.value,
                    city: cityInputField.current!.value,
                    region: regionInputField.current!.value,
                    postal_code: postalCodeInputField.current!.value,
                    shipping_method: shippingMethodInputField.current!.value,
                    shipping_option: shippingOptionInputField.current!.value,
                    payment_method: paymentMethodInputField.current!.value
                  },
                  true
                );
              }

              navigate("/dashboard/order");
            }}
          >
            Save
          </motion.div>
        </div>

        <div className=" w-full inline-flex justify-end h-fit mt-10 mb-2">
          <AiOutlineSwapLeft
            className=" text-lg hover:text-[#a9abc6] cursor-pointer"
            onClick={() => {
              navigate("/dashboard/order");
            }}
          />
        </div>

        <div className="w-full inline-flex flex-col justify-start items-start gap-1">
          <p style={{ color: colors["text"] }}>Status</p>
          <select
            className=" w-full h-8 border border-black p-1"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="not started">Not Started</option>
            <option value="transporting">Transporting</option>
            <option value="finished">Finished</option>
          </select>
        </div>

        <table className="w-full overflow-hidden whitespace-nowrap rounded-tl-md rounded-tr-md mt-5">
          <thead
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <tr>
              <th className=" pt-2 pb-2">Model Id</th>
              <th>Style</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody className=" text-center">
            {Object.values(products).map((item, index) => {
              let bg = colors["tb-row-1"];
              if (index % 2 === 1) bg = colors["tb-row-2"];
              return (
                <tr
                  key={`prod-${index}`}
                  style={{
                    backgroundColor: bg,
                    color: colors["tb-row-text"],
                  }}
                >
                  <td>
                    <div className=" inline-flex gap-1 font-semibold cursor-pointer hover:underline"
                      style={{
                        pointerEvents: item.is_custom ? 'none' : 'auto'
                      }}
                      onClick={() => {
                        if (item.is_custom)
                          return;
                        window.scrollTo({ top: 0 });
                        navigate(`/dashboard/inventory-view/${item.model_id}`);
                      }}
                    >
                      {item.is_custom ? <FaRegStar /> : <></>}
                      <p>{item.model_id}</p>
                    </div>
                  </td>
                  <td>{item.style}</td>
                  <td>{item.quantity}</td>
                  <td>${item.price}</td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td></td>
              <td className=" font-semibold">Total</td>
              <td>
                <p className=" underline font-semibold">${totalPrice}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className=" inline-flex w-full justify-start items-center"
          style={{
            color: colors["text"],
          }}
        >
          Inventory Items
        </p>
        <div
          className="w-full flex flex-col justify-center items-start p-4 border border-dashed rounded-md"
          style={{
            borderColor: colors["text"],
          }}
        >
          <table className="table-auto w-full whitespace-nowrap mb-5 mt-4">
            <thead
              className=" text-start font-bold"
              style={{ color: colors["text"] }}
            >
              <tr>
                <td>Model Id</td>
                <td>Name</td>
                <td>Style</td>
                <td>Warehouse</td>
                <td>Quantity</td>
              </tr>
            </thead>
            <tbody className=" text-start">
              {Object.entries(inventory).map(([warehouse, items], index) => {
                return Object.values(items).map((item, iIndex) => {
                  let bgColor = colors["tb-row-1"];
                  if (index % 2 === 0) bgColor = colors["tb-row-2"];
                  return (
                    <tr
                      key={`record-${index}-${iIndex}`}
                      className=" relative"
                      style={{
                        backgroundColor: bgColor,
                        opacity: item.quantity > 0 ? "1.0" : "0.6",
                      }}
                    >
                      <td>{item.modelId}</td>
                      <td>{item.name}</td>
                      <td>{item.style}</td>
                      <td>{item.warehouse}</td>
                      <td>{item.quantity}</td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>

          {Object.entries(inventory).map(([warehouse, items], index) => {
            return (
              <div
                key={`inv-${index}`}
                className="w-full flex flex-col justify-center items-start mb-5 gap-2"
              >
                <p className=" font-semibold" style={{ color: colors["text"] }}>
                  {warehouse}
                </p>
                {Object.values(items).map((item, iIndex) => {
                  let left = item.quantity;
                  for (let i = 0; i < item.distribution.length; i++) {
                    if (item.distribution[i].quantity)
                      left -= item.distribution[i].quantity;
                  }
                  return (
                    <div
                      key={`inv-${index}-${iIndex}`}
                      className=" w-full flex justify-start items-start"
                    >
                      <div className=" flex flex-col w-[200px] min-w-[200px] overflow-x-scroll">
                        <p
                          className=" whitespace-nowrap"
                          style={{ color: colors["text"] }}
                        >{`${item.modelId} (${left})`}</p>
                        <p style={{ color: colors["tb-row-text-d"] }}>
                          {item.style}
                        </p>
                      </div>
                      <div
                        className=" w-full flex flex-col justify-start items-center gap-3"
                        style={{
                          backgroundColor: colors["tb-row-2-h"],
                          color: colors["tb-row-text"],
                        }}
                      >
                        {Object.values(item.distribution).map(
                          (disItem, dIndex) => {
                            return (
                              <div
                                key={`inv-${index}-${iIndex}-${dIndex}`}
                                className=" relative w-full flex justify-between pl-5 pr-5 pt-2 pb-2 items-center gap-5"
                              >
                                <div className=" relative w-full h-[1px] border-dashed border-[1px] border-gray-600">
                                  <FaShuttleVan className=" absolute left-0 -top-5 text-2xl animate-bounce-x hidden xl:block" />
                                  <FaWarehouse
                                    className=" absolute right-0 -top-5 text-2xl hidden xl:block cursor-pointer"
                                    onClick={() => {
                                      ScrollTo(deliveryHead.current as Element);
                                    }}
                                  />
                                </div>
                                <input
                                  value={
                                    inventory[warehouse][iIndex].distribution[
                                      dIndex
                                    ].quantity
                                  }
                                  type="number"
                                  className="p-1 text-black"
                                  onChange={(e) => {
                                    let val = parseInt(e.target.value);
                                    if (val < 0) val = 0;
                                    let max = item.quantity;

                                    for (
                                      let i = 0;
                                      i < item.distribution.length;
                                      i++
                                    ) {
                                      if (i === dIndex) continue;
                                      if (item.distribution[i].quantity)
                                        max -= item.distribution[i].quantity;
                                    }
                                    if (val > max) val = max;
                                    let totalDict =
                                      totalStockOut[
                                        item.modelId + "," + item.style_code
                                      ];
                                    if (!totalDict) return;
                                    if (
                                      val >
                                      totalDict.total - totalDict.current
                                    )
                                      val = totalDict.total - totalDict.current;

                                    let temp = inventory;
                                    temp[warehouse][iIndex].distribution[
                                      dIndex
                                    ].quantity = val;
                                    setInventory({ ...temp });

                                    let t = totalStockOut;
                                    if (!val) {
                                      t[
                                        item.modelId + "," + item.style_code
                                      ].current = 0;
                                    } else {
                                      t[
                                        item.modelId + "," + item.style_code
                                      ].current += val;
                                    }
                                    setTotalStockOut({ ...t });
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div
                        className=" p-2 ml-5 hover:cursor-pointer select-none"
                        style={{
                          backgroundColor:
                            item.quantity > 0 &&
                            products[item.modelId + "," + item.style_code]
                              ? colors["tb-head"]
                              : colors["tb-row-2"],
                          color: colors["tb-head-text"],
                        }}
                        onClick={async () => {
                          if (item.quantity == 0) return;
                          if (!products[item.modelId + "," + item.style_code])
                            return;

                          let temp = inventory;
                          if (item.distribution.length > 0) {
                            temp[warehouse][iIndex].distribution = [];
                            setInventory({ ...temp });
                          } else {
                            temp[warehouse][iIndex].distribution.push({
                              to: "",
                              quantity: 0,
                            });
                            setInventory({ ...temp });
                          }
                        }}
                      >
                        {item.distribution.length > 0 ? (
                          <AiOutlineClose className=" text-lg" />
                        ) : (
                          <AiOutlinePlus className="text-lg" />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <p
          className="mt-5 inline-flex w-full justify-start items-center"
          style={{ color: colors["text"] }}
        >
          Customer Info
        </p>
        <div
          className="w-full flex flex-col justify-center items-start p-4 border border-dashed rounded-md"
          style={{ borderColor: colors["text"] }}
        >
          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th className="p-2">Contact</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ color: colors["tb-row-text"] }}>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Customer Id</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      value={customerId}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                      onChange={(e) =>{
                        setCustomerId(e.target.value);
                      }}
                    />
                    <div className=" p-2 cursor-pointer hover:opacity-80"
                      style={{
                        backgroundColor: colors['tb-head'],
                        color: colors['tb-head-text']
                      }}
                      onClick={() =>{
                        let val = parseInt(customerId);
                        if (isNaN(val) || val < 1)
                          return;

                        navigate(`/dashboard/customer-view/${customerId}`);
                      }}
                    >
                      <AiOutlineSearch />
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">First Name</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={firstNameInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Last Name</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={lastNameInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Email</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={emailInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Phone</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={phoneInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th ref={deliveryHead} className="p-2">
                  Delivery
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ color: colors["tb-row-text"] }}>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Country/ Region</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={countrtInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Address 1</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={address1InputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Address 2</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={address2InputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">City</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={cityInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td className=" pl-2">Region</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={regionInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Postal Code</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={postalCodeInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th className="p-2">Shipping Method</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ color: colors["tb-row-text"] }}>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td></td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={shippingMethodInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td className=" pl-2">Method Option</td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center gap-2 mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={shippingOptionInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
            <thead
              style={{
                backgroundColor: colors["tb-head"],
                color: colors["tb-head-text"],
              }}
            >
              <tr>
                <th className="p-2">Payment Method</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: colors["tb-row-1"] }}>
                <td></td>
                <td className=" w-full pl-2">
                  <div className=" flex justify-center items-center mr-2">
                    <input
                      disabled={sharedBy > 1 ? true : false}
                      ref={paymentMethodInputField}
                      type="text"
                      className="mb-2 mt-2 border-[2px] border-gray-300 text-gray-900 text-sm rounded-md w-full p-2.5"
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: colors["tb-row-2"] }}>
                <td></td>
                <td className=" w-full pl-2">
                  <div className="w-full h-fit max-h-52 overflow-y-scroll">
                  {
                    paymentMethodInputField.current?.value === 'bank transfer' && payment_option ?
                    <img src={`https://inanin.com/shopping-platform/commerce-backend/uploads/bank_note/${payment_option}.jpg`} className=" w-full contain max-h-[10vw]" /> :
                    <></>
                  }
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table className="w-full overflow-hidden whitespace-nowrap text-center rounded-tl-md rounded-tr-md mt-5">
          <thead
            style={{
              backgroundColor: colors["tb-head"],
              color: colors["tb-head-text"],
            }}
          >
            <tr>
              <th className="p-2">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ backgroundColor: colors["tb-row-1"] }}>
              <td>
                <div className=" w-full p-3">
                  <textarea
                    value={remarks}
                    className="w-full p-1 border-2 rounded-md min-h-[60px]"
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
